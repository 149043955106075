export const handleUpdateName = (
  groupTrees: any[],
  setGroupTrees: React.Dispatch<React.SetStateAction<any[]>>,
  id: number,
  updatedName: string
) => {
  // Helper function to recursively update the name
  const updateName = (nodes: any[]): any[] => {
    return nodes.map((node) => {
      if (node.id === id) {
        // If the current node matches the id, update its name
        return {
          ...node,
          name: updatedName,
        };
      }

      // If the current node has children, recursively update them
      if (node.children && node.children.length > 0) {
        return {
          ...node,
          children: updateName(node.children),
        };
      }

      // Return the node unchanged if no match is found
      return node;
    });
  };

  // Update the groupTrees and call setGroupTrees with the new state
  setGroupTrees(updateName(groupTrees));
};
