import React from "react";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { numbersOnly } from "../../../../utils/numbersOnly";
import Switch from "@mui/material/Switch";
import Input from "@mui/material/Input";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const vmsBuilder = (
  array: any[],
  vmsVariables: any,
  setVmsVariables: any,
  setPatchObject: any,
  setSiteInfo: any,
  siteInfo: any,
  setSiteInfoChangesMade: any,
  showPassword: any,
  handleClickShowPassword: any,
  handleMouseDownPassword: any,
  handleMouseUpPassword: any,
  index: number
) => {
  const options = array[4];
  const optionsIds = new Map();
  const optionNames = new Map();

  if ((array[3] === "choices" || array[3] === "choice") && options) {
    options.forEach((option: any) => {
      optionsIds.set(option[0], option[1]);
    });
    options.forEach((option: any) => {
      optionNames.set(option[1], option[0]);
    });
  }

  const expr: string = array[3];
  switch (expr) {
    case "string":
      return (
        <>
          <div key={array[2]}>
            <TextField
              id={array[2]}
              defaultValue={
                siteInfo[array[2]] === "null" ? "" : siteInfo[array[2]]
              }
              label={array[0]}
              variant="standard"
              sx={{ width: 200 }}
              type="string"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPatchObject((previousState: any) => {
                  return { ...previousState, [array[2]]: event.target.value };
                });
                setSiteInfo((previousState: any) => {
                  return { ...previousState, [array[2]]: event.target.value };
                });
                const newVMSVariables = vmsVariables;
                newVMSVariables[index][1] = event.target.value;
                setVmsVariables(newVMSVariables);
                setSiteInfoChangesMade(true);
              }}
            />
          </div>
        </>
      );
    case "integer":
      return (
        <>
          <div key={array[2]}>
            <TextField
              error={
                siteInfo[array[2]] && !numbersOnly.test(siteInfo[array[2]])
              }
              id={array[2]}
              defaultValue={array[1] === "null" ? "" : array[1]}
              label={array[0]}
              autoComplete="off"
              variant="standard"
              sx={{ width: 200 }}
              type="string"
              helperText={
                siteInfo[array[2]] && !numbersOnly.test(siteInfo[array[2]])
                  ? "Must be a number"
                  : ""
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPatchObject((previousState: any) => {
                  return { ...previousState, [array[2]]: event.target.value };
                });
                setSiteInfo((previousState: any) => {
                  return { ...previousState, [array[2]]: event.target.value };
                });
                const newVMSVariables = vmsVariables;
                newVMSVariables[index][1] = event.target.value;
                setVmsVariables(newVMSVariables);
                setSiteInfoChangesMade(true);
              }}
            />
          </div>
        </>
      );
    case "password":
      return (
        <>
          <div key={array[2]}>
            <FormControl sx={{ width: 200 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                {array[0]}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword[array[2]] ? "text" : "password"}
                defaultValue={array[1] === "null" ? "" : array[1]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPatchObject((previousState: any) => {
                    return { ...previousState, [array[2]]: event.target.value };
                  });
                  setSiteInfo((previousState: any) => {
                    return { ...previousState, [array[2]]: event.target.value };
                  });
                  const newVMSVariables = vmsVariables;
                  newVMSVariables[index][1] = event.target.value;
                  setVmsVariables(newVMSVariables);
                  setSiteInfoChangesMade(true);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={() => {
                        handleClickShowPassword(array[2]);
                      }}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword[array[2]] ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </>
      );
    case "checkbox":
      return (
        <>
          <Switch
            color="warning"
            defaultChecked={array[1] === "true"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPatchObject((previousState: any) => {
                return { ...previousState, [array[2]]: event.target.checked };
              });
              setSiteInfo((previousState: any) => {
                return { ...previousState, [array[2]]: event.target.checked };
              });
              const newVMSVariables = vmsVariables;
              newVMSVariables[index][1] = `${event.target.checked}`;
              setVmsVariables(newVMSVariables);
              setSiteInfoChangesMade(true);
            }}
          />
        </>
      );
    case "choice":
      return !options ? (
        <>No Options</>
      ) : (
        <>
          <div key={array[2]}>
            <Autocomplete
              disablePortal
              id={array[2]}
              options={options.map((option: any) => {
                return option[0];
              })}
              defaultValue={
                array[1] === "null" ? "" : optionNames.get(array[1])
              }
              onChange={(event: any, newInputValue: any) => {
                setPatchObject((previousState: any) => {
                  return {
                    ...previousState,
                    [array[2]]: optionsIds.get(newInputValue),
                  };
                });
                setSiteInfo((previousState: any) => {
                  return {
                    ...previousState,
                    [array[2]]: optionsIds.get(newInputValue),
                  };
                });
                const newVMSVariables = vmsVariables;
                newVMSVariables[index][1] = optionsIds.get(newInputValue);
                setVmsVariables(newVMSVariables);
                setSiteInfoChangesMade(true);
              }}
              sx={{ width: 250 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={array[0]}
                  autoComplete="off"
                  id={array[2]}
                  name={array[0]}
                />
              )}
            />
          </div>
        </>
      );
    default:
      return <div>Unsupported field</div>;
  }
};
