import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { getApiUrl } from "../../../utils/getApiUrl";
import { getCookie } from "../../../utils/getCookie";
import { getAlertMP4 } from "./getAlertMP4";

export const checkForRedirectAndFetchMP4 = (
  selectedRow: any,
  navigate: NavigateFunction,
  setMP4URL: Dispatcher<string>,
  setVideoClipLoading: Dispatcher<boolean>,
  setShowRefreshButton: Dispatcher<boolean>
) => {
  const baseURL = getApiUrl();
  const token = getCookie("token");

  setMP4URL("");

  if (selectedRow) {
    setVideoClipLoading(true);

    const url = `${baseURL}monitoring-api/alert/clip/?approx_capture_timestamp=${selectedRow.approx_capture_timestamp}&window_id=${selectedRow.window_id}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "manual", // Prevent automatic redirects
    })
      .then(async (response) => {
        setShowRefreshButton(false);
        if (response.status === 302) {
          // Handle the redirect location if necessary
          setVideoClipLoading(false);
        } else if (response.ok) {
          const data = await response.json();
          const selectedRowAlerts = JSON.parse(localStorage.selectedRowAlerts);
          if (
            selectedRow.approx_capture_timestamp ===
            selectedRowAlerts.approx_capture_timestamp
          ) {
            setMP4URL(data);
          }
          setVideoClipLoading(false);
        } else {
          throw response; // Pass error to the catch block
        }
      })
      .catch((error) => {
        if (error.url) {
          setShowRefreshButton(true);
          getAlertMP4(selectedRow, navigate, setMP4URL, setVideoClipLoading);
        } else {
          setVideoClipLoading(false);
        }
      });
  }
};
