import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import { SiteInfo } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const createDots = (inputString: any) => {
  if (!inputString) {
    return "";
  }
  let dots = "";
  for (let i = 0; i < inputString.length; i++) {
    dots += "•";
  }
  return dots;
};

export const VmsTab = ({
  vmsVariables,
  siteInfo,
}: {
  vmsVariables: any;
  siteInfo: SiteInfo;
}) => {
  const [showPassword, setShowPassword] = React.useState<any>({});

  const handleClickShowPassword = (inputString: string) =>
    setShowPassword((previousState: any) => ({
      ...previousState,
      [inputString]: !previousState[inputString],
    }));

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const noVMS =
    siteInfo.integration_type_name === "rtsp" &&
    siteInfo.onboarding_integration === 1;

  React.useEffect(() => {
    vmsVariables.forEach((array: string[]) => {
      if (array[3] === "password") {
        setShowPassword((show: any) => ({ ...show, [array[2]]: false }));
      }
    });
  }, []);

  return noVMS ? (
    <TableContainer component={Paper}>
      <div className="pt-[20px] pb-[20px] pl-[20px]">
        <p className="font-mulish">
          {" "}
          {`To view connection details for specific cameras, find the camera below, then > Show More > Edit Camera > Camera Information > Connection.`}
        </p>
      </div>
    </TableContainer>
  ) : (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {vmsVariables.map(
              (array: string[], index: number) =>
                index < 6 && (
                  <TableCell
                    key={array[0]}
                    align="left"
                    sx={{
                      width: 160,
                      fontFamily: "Mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    {array[0]}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={siteInfo.id}>
            {vmsVariables.map((array: string[], index: number) => {
              if (array[3] === "checkbox" && index < 6) {
                return (
                  <TableCell
                    key={array[1]}
                    align="left"
                    sx={{ fontFamily: "Mulish" }}
                  >
                    <Switch
                      disabled
                      checked={array[1] === "true"}
                      color="warning"
                    ></Switch>
                  </TableCell>
                );
              }
              if (array[3] === "password" && index < 6) {
                return (
                  <TableCell
                    key={array[1]}
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 160 }}
                  >
                    <div className="flex">
                      {showPassword[array[2]]
                        ? siteInfo[array[2] as keyof typeof siteInfo]
                        : createDots(
                            siteInfo[array[2] as keyof typeof siteInfo]
                          )}
                      <div>
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={() => {
                              handleClickShowPassword(array[2]);
                            }}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {showPassword[array[2]] ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </div>
                    </div>
                  </TableCell>
                );
              }
              if (
                array[3] !== "checkbox" &&
                array[3] !== "passowrd" &&
                index < 6
              ) {
                return (
                  <TableCell
                    key={array[1]}
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 160 }}
                  >
                    {siteInfo[array[2] as keyof typeof siteInfo]}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableBody>
      </Table>

      {vmsVariables.length > 5 && (
        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 5 &&
                  index < 12 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{
                        width: 160,
                        fontFamily: "Mulish",
                        fontWeight: "Bold",
                      }}
                    >
                      {array[0]}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={siteInfo.id}>
              {vmsVariables.map((array: string[], index: number) => {
                if (array[3] === "checkbox" && index > 5 && index < 12) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      <Switch
                        disabled
                        checked={array[1] === "true"}
                        color="warning"
                      ></Switch>
                    </TableCell>
                  );
                }
                if (array[3] === "password" && index > 5 && index < 12) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      <div className="flex">
                        {showPassword[array[2]]
                          ? siteInfo[array[2] as keyof typeof siteInfo]
                          : createDots(
                              siteInfo[array[2] as keyof typeof siteInfo]
                            )}
                        <div>
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={
                                showPassword
                                  ? "hide the password"
                                  : "display the password"
                              }
                              onClick={() => {
                                handleClickShowPassword(array[2]);
                              }}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge="end"
                            >
                              {showPassword[array[2]] ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        </div>
                      </div>
                    </TableCell>
                  );
                }
                if (
                  array[3] !== "checkbox" &&
                  array[3] !== "password" &&
                  index > 5 &&
                  index < 12
                ) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      {siteInfo[array[2] as keyof typeof siteInfo]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
      {vmsVariables.length > 12 && (
        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 11 &&
                  index < 18 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{
                        width: 160,
                        fontFamily: "Mulish",
                        fontWeight: "Bold",
                      }}
                    >
                      {array[0]}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={siteInfo.id}>
              {vmsVariables.map((array: string[], index: number) => {
                if (array[3] === "checkbox" && index > 11 && index < 18) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      <Switch
                        disabled
                        checked={array[1] === "true"}
                        color="warning"
                      ></Switch>
                    </TableCell>
                  );
                }
                if (array[3] === "password" && index > 11 && index < 18) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      <div className="flex">
                        {showPassword[array[2]]
                          ? siteInfo[array[2] as keyof typeof siteInfo]
                          : createDots(
                              siteInfo[array[2] as keyof typeof siteInfo]
                            )}
                        <div>
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={
                                showPassword
                                  ? "hide the password"
                                  : "display the password"
                              }
                              onClick={() => {
                                handleClickShowPassword(array[2]);
                              }}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge="end"
                            >
                              {showPassword[array[2]] ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        </div>
                      </div>
                    </TableCell>
                  );
                }
                if (
                  array[3] !== "checkbox" &&
                  array[3] !== "password" &&
                  index > 11 &&
                  index < 18
                ) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      {siteInfo[array[2] as keyof typeof siteInfo]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
