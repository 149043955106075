import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { handleUnnestedLevelClick } from "./handleUnnestedLevelClick";
import { CameraIcon } from "../../../../common/CameraIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";

export const createUnnestedListItemButton = (
  arrayItem: any,
  setPage: any,
  setCurrentTitle: any,
  indentNumber: number,
  setRenderedRows: any,
  setPageLoading: any,
  navigate: any,
  maxWordLength: number,
  setSiteBreadcrumbs: any,
  navBarCollapsed: boolean,
  setSelected: any,
  setSelectedGroupListName: any,
  setEditableGroupSelected: any
) => {
  return (
    <span>
      <ListItemButton
        sx={{ pl: indentNumber }}
        onClick={() => {
          handleUnnestedLevelClick(
            setPage,
            setCurrentTitle,
            arrayItem.name,
            arrayItem.id,
            setRenderedRows,
            setPageLoading,
            navigate,
            setSiteBreadcrumbs,
            setSelected,
            true,
            setSelectedGroupListName,
            setEditableGroupSelected
          );
        }}
      >
        <ListItemIcon>
          <CameraIcon />
        </ListItemIcon>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {arrayItem.name.length < 18 || navBarCollapsed ? (
          <ListItemText
            primary={`${arrayItem.name}`}
            primaryTypographyProps={{
              fontSize: 14,
              fontFamily: "Mulish",
            }}
          />
        ) : (
          <Tooltip
            title={arrayItem.name}
            placement="top"
            sx={{ fontSize: "15px", fontFamily: "mulish" }}
          >
            <ListItemText
              primary={`${arrayItem.name.slice(0, 15)}...`}
              primaryTypographyProps={{
                fontSize: 14,
                fontFamily: "Mulish",
              }}
            />
          </Tooltip>
        )}
        {/* {arrayItem.name.length < maxWordLength ? (
            <>
              {`${arrayItem.name} - ${arrayItem.children.length}`}
              <VideocamOutlinedIcon fontSize="small" />
            </>
          ) : (
            <>
              {`${arrayItem.name.slice(0, maxWordLength - 1)}... - ${
                arrayItem.children.length
              }`}
              <VideocamOutlinedIcon fontSize="small" />
            </>
          )} */}
      </ListItemButton>
    </span>
    // </Tooltip>
  );
};
