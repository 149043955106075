import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";
import { NavigateFunction } from "react-router-dom";
import { SiteInfo } from "./siteAboutInterfaces";
import { GeneralInfoTab } from "../GeneralInfoTab";

export const handleSubmitUpdatedNotes = (
  newNotes: string,
  backupNotes: string,
  setSiteInfo: React.Dispatch<React.SetStateAction<SiteInfo>>,
  setActiveTab: React.Dispatch<React.SetStateAction<JSX.Element>>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleSubmitUpdatedNotes(
      newNotes,
      backupNotes,
      setSiteInfo,
      setActiveTab,
      navigate
    );
  };

  AxiosUI.patch(`customer/${getNumbersAfterAboutSite(location.href)}/about/`, {
    notes: newNotes,
  }).then(
    (response: AxiosResponse) => {
      setActiveTab(<></>);
      setSiteInfo((previousState: SiteInfo) => {
        return { ...previousState, notes: newNotes };
      });
      setActiveTab(
        <GeneralInfoTab
          siteInfo={response.data}
          setSiteInfo={setSiteInfo}
          setActiveTab={setActiveTab}
        />
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, retryFunction, navigate, () => {
        setSiteInfo((previousState: SiteInfo) => {
          return { ...previousState, notes: backupNotes };
        });
        alert("Notes could not be updated. Please try again.");
      });
    }
  );
};
