import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AlertData } from "./alertsInterfaces";
import { getAlertMP4 } from "./getAlertMP4";
import { handleAlertButtonsFill } from "./handleAlertButtonsFill";
import { NavigateFunction } from "react-router-dom";
import { getActiveIgnoreZones } from "./getActiveIgnoreZones";
import { findSelectedRowIndex } from "./findSelectedRowIndex";
import { setDetectionTypeLower } from "../AlertsTable";
import { checkForRedirectAndFetchMP4 } from "./checkForRedirectAndFetchMP4";

export const handleBackButtonClick = (
  selectedRow: any,
  setAlertIndex: Dispatcher<number>,
  setSelectedRow: any,
  alertData: any,
  navigate: NavigateFunction,
  setMP4URL: any,
  setVideoClipLoading: Dispatcher<boolean>,
  setSelectedButtonsFilled: any,
  setSeeMoreURL: Dispatcher<string>,
  setAlertData: any,
  setRefreshingData: Dispatcher<boolean>,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  alertsIndexOrder: any,
  detectionType: string,
  outcomeFilterType: string,
  setBackArrowDisabled: Dispatcher<boolean>,
  setForwardArrowDisabled: Dispatcher<boolean>,
  setShowRefreshButton: Dispatcher<boolean>
) => {
  let filteredAlertData;

  if (detectionType === "All" && outcomeFilterType === "All") {
    filteredAlertData = alertData;
  }
  if (detectionType === "All" && outcomeFilterType !== "All") {
    filteredAlertData = alertData.filter(
      (alert: any) => alert.detection_type === outcomeFilterType
    );
  }
  if (detectionType !== "All" && outcomeFilterType === "All") {
    filteredAlertData = alertData.filter(
      (alert: any) =>
        alert.alert_labels === setDetectionTypeLower(detectionType)
    );
  }
  if (detectionType !== "All" && outcomeFilterType !== "All") {
    filteredAlertData = alertData.filter(
      (alert: any) =>
        alert.alert_labels === setDetectionTypeLower(detectionType) &&
        alert.detection_type === outcomeFilterType
    );
  }

  // const currentIndex = findSelectedRowIndex(
  //   alertsIndexOrder,
  //   selectedRow.index
  // );

  const currentIndex = filteredAlertData.findIndex(
    (alert: any) =>
      alert.approx_capture_timestamp === selectedRow.approx_capture_timestamp
  );

  const isLastIndex = currentIndex + 1 === filteredAlertData.length - 1;

  const isFirstIndex = currentIndex - 1 === 0;

  if (isLastIndex) {
    setForwardArrowDisabled(true);
  } else {
    setForwardArrowDisabled(false);
  }

  if (isFirstIndex) {
    setBackArrowDisabled(true);
  } else {
    setBackArrowDisabled(false);
  }

  const newSelectedRow =
    // detectionType === "All"
    //   ?
    filteredAlertData[currentIndex - 1];
  // : alertData[alertsIndexOrder[currentIndex - 1]];
  setSelectedRow(newSelectedRow);
  localStorage.setItem("selectedRowAlerts", JSON.stringify(newSelectedRow));
  if (newSelectedRow.camera_pk) {
    getActiveIgnoreZones(
      newSelectedRow.camera_pk,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      navigate
    );
    // getAlertMP4(newSelectedRow, navigate, setMP4URL, setVideoClipLoading);
    checkForRedirectAndFetchMP4(
      newSelectedRow,
      navigate,
      setMP4URL,
      setVideoClipLoading,
      setShowRefreshButton
    );
    handleAlertButtonsFill(newSelectedRow, setSelectedButtonsFilled);
  } else {
    setMP4URL("");
    setVideoClipLoading(false);
    setSelectedAlertCameraInfo({});
    setIgnoreZonesSelectedAlert([]);
    alert(
      "This camera has not been added to this site, therefore video can not be retrieved. Please navigate to this site's page and add the camera"
    );
  }
  const newAlertData = alertData;
  newAlertData.forEach((object: AlertData) => {
    object.selected = false;
  });
  const newSelectedRowIndex = alertData.findIndex(
    (alert: any) =>
      alert.approx_capture_timestamp === newSelectedRow.approx_capture_timestamp
  );
  newAlertData.forEach((object: AlertData) => {
    object.selected = false;
  });
  newAlertData[newSelectedRowIndex].selected = true;
  // if (detectionType === "All") {
  //   newAlertData[currentIndex - 1].selected = true;
  // } else {
  //   newAlertData[alertsIndexOrder[currentIndex - 1]].selected = true;
  // }
  setSeeMoreURL(newSelectedRow.alert_url);
  setAlertData(newAlertData);
  setRefreshingData(false);
};
