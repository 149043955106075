import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { vmsBuilder } from "./SiteAboutPageUtils/vmsBuilder";
import {
  PatchObject,
  SiteInfo,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const VmsTabEditMode = ({
  vmsVariables,
  setVmsVariables,
  setPatchObject,
  setSiteInfo,
  siteInfo,
  setSiteInfoChangesMade,
}: {
  vmsVariables: any;
  setVmsVariables: any;
  setPatchObject: Dispatcher<PatchObject>;
  setSiteInfo: Dispatcher<SiteInfo>;
  siteInfo: SiteInfo;
  setSiteInfoChangesMade: Dispatcher<boolean>;
}) => {
  const [showPassword, setShowPassword] = React.useState<any>({});

  const handleClickShowPassword = (inputString: string) =>
    setShowPassword((previousState: any) => ({
      ...previousState,
      [inputString]: !previousState[inputString],
    }));

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const noVMS =
    siteInfo.integration_type_name === "rtsp" &&
    siteInfo.onboarding_integration === 1;

  React.useEffect(() => {
    vmsVariables.forEach((array: string[]) => {
      if (array[3] === "password") {
        setShowPassword((show: any) => ({ ...show, [array[2]]: false }));
      }
    });
  }, []);

  return noVMS ? (
    <TableContainer component={Paper}>
      <div className="pt-[20px] pb-[20px] pl-[20px]">
        <p className="font-mulish">
          {" "}
          {`To view connection details for specific cameras, find the camera below, then > Show More > Edit Camera > Camera Information > Connection.`}
        </p>
      </div>
    </TableContainer>
  ) : (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {vmsVariables.map(
              (array: string[], index: number) =>
                index < 6 && (
                  <TableCell
                    key={array[0]}
                    align="left"
                    sx={{
                      width: 160,
                      fontFamily: "Mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    {array[0]}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={siteInfo.id}>
            {vmsVariables.map(
              (array: string[], index: number) =>
                index < 6 && (
                  <TableCell
                    key={array[0]}
                    align="left"
                    sx={{ width: 160, fontFamily: "Mulish" }}
                  >
                    {vmsBuilder(
                      array,
                      vmsVariables,
                      setVmsVariables,
                      setPatchObject,
                      setSiteInfo,
                      siteInfo,
                      setSiteInfoChangesMade,
                      showPassword,
                      handleClickShowPassword,
                      handleMouseDownPassword,
                      handleMouseUpPassword,
                      index
                    )}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableBody>
      </Table>
      {vmsVariables.length > 5 && (
        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 5 &&
                  index < 12 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{
                        width: 160,
                        fontFamily: "Mulish",
                        fontWeight: "Bold",
                      }}
                    >
                      {array[0]}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={siteInfo.id}>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 5 &&
                  index < 12 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{ width: 160, fontFamily: "Mulish" }}
                    >
                      {vmsBuilder(
                        array,
                        vmsVariables,
                        setVmsVariables,
                        setPatchObject,
                        setSiteInfo,
                        siteInfo,
                        setSiteInfoChangesMade,
                        showPassword,
                        handleClickShowPassword,
                        handleMouseDownPassword,
                        handleMouseUpPassword,
                        index
                      )}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableBody>
        </Table>
      )}
      {vmsVariables.length > 12 && (
        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 11 &&
                  index < 18 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{
                        width: 160,
                        fontFamily: "Mulish",
                        fontWeight: "Bold",
                      }}
                    >
                      {array[0]}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={siteInfo.id}>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 11 &&
                  index < 18 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{ width: 160, fontFamily: "Mulish" }}
                    >
                      {vmsBuilder(
                        array,
                        vmsVariables,
                        setVmsVariables,
                        setPatchObject,
                        setSiteInfo,
                        siteInfo,
                        setSiteInfoChangesMade,
                        showPassword,
                        handleClickShowPassword,
                        handleMouseDownPassword,
                        handleMouseUpPassword,
                        index
                      )}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
