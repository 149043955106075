import { Help } from "@mui/icons-material";
import { FormHelperText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const MuiCheckBoxFieldType = ({
  id,
  label,
  checked,
  disabled,
  onChange,
  helperText,
}: {
  id: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  onChange: any;
  helperText: string;
}) => {
  return (
    <div key={id}>
      <FormControlLabel
        sx={{
          fontFamily: "Mulish",
          color: "#001943",
        }}
        label={label}
        control={
          <Checkbox
            color="primary"
            defaultChecked={checked}
            disabled={disabled}
            onChange={onChange}
          />
        }
      />
      <FormHelperText>{`${helperText}`}</FormHelperText>
    </div>
  );
};
