import React from "react";
import { handleConfigureMotion } from "./SiteAboutPageUtils/handleConfigureMotion";
import { AlertState } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const ConfigureMotionButton = ({
  setBackdropLoadingScreenOn,
  setAlertState,
  navigate,
}: {
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  navigate: NavigateFunction;
}) => {
  return (
    <button
      className={
        "shadow-button bg-actuate-orange rounded font-mulish text-white text-base	p-1.5 hover:bg-[#E67300]"
      }
      onClick={() => {
        setBackdropLoadingScreenOn(true);
        handleConfigureMotion(
          setBackdropLoadingScreenOn,
          setAlertState,
          navigate
        );
      }}
    >
      &nbsp; Configure Motion &nbsp;
    </button>
  );
};
