import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { Switch } from "@mui/material";
import { createDots } from "../Sites/SiteAboutPage/VmsTab";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ConnectionsTab = ({
  cameraGeneralInfo,
}: {
  cameraGeneralInfo: any;
}) => {
  const connectionsTitlesArray = JSON.parse(
    localStorage.connectionsTitlesArray
  );

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={connectionsTitlesArray} />
        <TableBody>
          <TableRow key={`connections_table_row_1`}>
            {cameraGeneralInfo.ip && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.ip}
              </TableCell>
            )}
            {cameraGeneralInfo.rtsp && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.rtsp}
              </TableCell>
            )}
            {cameraGeneralInfo.http && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.http}
              </TableCell>
            )}
            {cameraGeneralInfo.server_port && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.server_port}
              </TableCell>
            )}
            {cameraGeneralInfo.username && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.username}
              </TableCell>
            )}
            {cameraGeneralInfo.password && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div className="flex">
                  {showPassword
                    ? cameraGeneralInfo.password
                    : createDots(cameraGeneralInfo.password)}
                  <div>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          showPassword
                            ? "hide the password"
                            : "display the password"
                        }
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.channel && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.channel}
              </TableCell>
            )}
            {cameraGeneralInfo.stream_parameters && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.stream_parameters}
              </TableCell>
            )}
            {cameraGeneralInfo.slice && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {
                  <Switch
                    checked={cameraGeneralInfo.slice}
                    color="warning"
                    disabled={true}
                  />
                }
              </TableCell>
            )}
            {cameraGeneralInfo.split && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {
                  <Switch
                    checked={cameraGeneralInfo.split}
                    color="warning"
                    disabled={true}
                  />
                }
              </TableCell>
            )}
            {cameraGeneralInfo.use_stream_quality && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.stream_quality
                  ? cameraGeneralInfo.stream_quality
                  : 1}
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
