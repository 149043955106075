import React from "react";

export const showReleaseNotesBanner = () => {
  rnw("show");
};

export const ReleaseNotesBanner = () => {
  return (
    <rn-banner
      type="feature"
      background-color="#1F72E3"
      button-text="Learn More"
      border-radius="0"
      font-family="Mulish"
      margin="0"
      prefix="Latest Release"
      prefix-background-color="#FF8400"
      shadow="none"
    ></rn-banner>
  );
};
