import React from "react";
import { AlertData } from "./alertsInterfaces";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getAlertMP4 } from "./getAlertMP4";
import { NavigateFunction } from "react-router-dom";
import { handleAlertButtonsFill } from "./handleAlertButtonsFill";
import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { getActiveIgnoreZones } from "./getActiveIgnoreZones";
import { createApi } from "../../../utils/createApi";
import { checkForRedirectAndFetchMP4 } from "./checkForRedirectAndFetchMP4";

export const handleRowSelected = (
  row: any,
  alertData: AlertData[],
  setAlertData: Dispatcher<AlertData[]>,
  setSelectedButtonsFilled: Dispatcher<any>,
  setSeeMoreURL: Dispatcher<string>,
  navigate: NavigateFunction,
  setMP4URL: Dispatcher<string>,
  setVideoClipLoading: Dispatcher<boolean>,
  setSelectedRow: any,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  refreshingToken: boolean,
  visibleRows: any,
  setBackArrowDisabled: Dispatcher<boolean>,
  setForwardArrowDisabled: Dispatcher<boolean>,
  setShowRefreshButton: Dispatcher<boolean>
) => {
  setVideoClipLoading(true);

  const AxiosMonUI = createMonitoringAPICall(``);

  const retryFunction = () => {
    handleRowSelected(
      row,
      alertData,
      setAlertData,
      setSelectedButtonsFilled,
      setSeeMoreURL,
      navigate,
      setMP4URL,
      setVideoClipLoading,
      setSelectedRow,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      refreshingToken,
      visibleRows,
      setBackArrowDisabled,
      setForwardArrowDisabled,
      setShowRefreshButton
    );
  };

  setMP4URL("");

  setSelectedRow(row);

  localStorage.setItem("selectedRowAlerts", JSON.stringify(row));

  setIgnoreZonesSelectedAlert([]);

  setSelectedAlertCameraInfo({});

  const currentIndex = visibleRows.findIndex(
    (alert: any) =>
      alert.approx_capture_timestamp === row.approx_capture_timestamp
  );

  if (currentIndex === 0 && visibleRows.length !== 1) {
    setBackArrowDisabled(true);
  }

  if (currentIndex === visibleRows.length - 1 && visibleRows.length !== 1) {
    setForwardArrowDisabled(true);
  }

  if (visibleRows.length === 1) {
    setForwardArrowDisabled(true);
    setBackArrowDisabled(true);
  }

  if (
    visibleRows.length !== 1 &&
    currentIndex !== 0 &&
    currentIndex !== visibleRows.length - 1
  ) {
    setBackArrowDisabled(false);
    setForwardArrowDisabled(false);
  }

  if (!refreshingToken) {
    if (row) {
      if (!row.camera_pk) {
        const newAlertData = alertData;
        newAlertData.forEach((object: AlertData) => {
          object.selected = false;
        });
        newAlertData.forEach((object: AlertData, index: number) => {
          if (
            `${row.approx_capture_timestamp}${row.camera_name}` ===
            `${object.approx_capture_timestamp}${object.camera_name}`
          ) {
            newAlertData[index].selected = true;
          }
        });
        setSeeMoreURL(row.alert_url);
        setAlertData(newAlertData);
        setMP4URL("");
        setVideoClipLoading(false);
        alert(
          "This camera has not been added to this site, therefore video can not be retrieved. Please navigate to this site's page and add the camera"
        );
      }
      if (row.camera_pk) {
        handleAlertButtonsFill(row, setSelectedButtonsFilled);
        const newAlertData = alertData;
        newAlertData.forEach((object: AlertData) => {
          object.selected = false;
        });
        newAlertData.forEach((object: AlertData, index: number) => {
          if (
            `${row.approx_capture_timestamp}${row.camera_name}` ===
            `${object.approx_capture_timestamp}${object.camera_name}`
          ) {
            newAlertData[index].selected = true;
          }
        });
        setSeeMoreURL(row.alert_url);
        setAlertData(newAlertData);
        checkForRedirectAndFetchMP4(
          row,
          navigate,
          setMP4URL,
          setVideoClipLoading,
          setShowRefreshButton
        );
        // AxiosMonUI.get(
        //   `alert/clip/?approx_capture_timestamp=${row.approx_capture_timestamp}&window_id=${row.window_id}`
        // ).then(
        //   (response: AxiosResponse) => {
        //     const selectedRowAlerts = JSON.parse(
        //       localStorage.selectedRowAlerts
        //     );
        //     if (
        //       row.approx_capture_timestamp ===
        //       selectedRowAlerts.approx_capture_timestamp
        //     ) {
        //       setMP4URL(response.data);
        //       setVideoClipLoading(false);
        //     }
        //   },
        //   (reason: AxiosError) => {
        //     universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        //       setTimeout(() => {
        //         setVideoClipLoading(false);
        //       }, 2000);
        //     });
        //   }
        // );
      }
    }
  }
};
