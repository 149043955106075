import { handleSetSelectedIndexLevel } from "./handleSetSelectedIndexLevel";
import { getIndividualGroup } from "./getIndividualGroup";

export const handleNestedLevelClick = async (
  setPage: any,
  setCurrentTitle: any,
  name: string,
  id: any,
  selectedIndex: any,
  levelName: any,
  setSelectedIndex: any,
  setRenderedRows: any,
  setPageLoading: any,
  navigate: any,
  setSiteBreadcrumbs: any,
  setSelected: any,
  setSelectedGroupListName: any,
  setEditableGroupSelected: any
) => {
  setEditableGroupSelected(true);
  setSelectedGroupListName(name);
  setSiteBreadcrumbs("");
  setPage(0);
  setCurrentTitle(name);
  handleSetSelectedIndexLevel(selectedIndex, levelName, id, setSelectedIndex);
  getIndividualGroup(
    id,
    setRenderedRows,
    setPageLoading,
    setSiteBreadcrumbs,
    setSelected,
    setEditableGroupSelected,
    setSelectedGroupListName,
    navigate
  );
};
