import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../../utils/createApi";
import { checkArmedStatus } from "./checkArmedStatus";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { SchedulesTab } from "../SchedulesTab";

export const handleDeactivateSite = async (
  setBackdropLoadingScreenOn: any,
  patchObject: any,
  setSiteInfo: any,
  setAlertState: any,
  setPatchObject: any,
  siteInfo: any,
  setArmButtonDisabled: any,
  setArmedStatus: any,
  setSiteSchedules: any,
  setAllSiteSchedules: any,
  clickedTab: any,
  setActiveTab: any,
  setDeleteScheduleDialogOpen: any,
  navigate: any
) => {
  setBackdropLoadingScreenOn(true);
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleDeactivateSite(
      setBackdropLoadingScreenOn,
      patchObject,
      setSiteInfo,
      setAlertState,
      setPatchObject,
      siteInfo,
      setArmButtonDisabled,
      setArmedStatus,
      setSiteSchedules,
      setAllSiteSchedules,
      clickedTab,
      setActiveTab,
      setDeleteScheduleDialogOpen,
      navigate
    );
  };

  await AxiosUI.patch(`customer/${siteInfo.id}/about/`, { active: false }).then(
    (response: AxiosResponse) => {
      setActiveTab(<></>);
      setPatchObject({ name: siteInfo.name });
      checkArmedStatus(
        response.data,
        setArmButtonDisabled,
        navigate,
        setSiteInfo,
        setArmedStatus
      );
      if (response.data.armed) {
        setArmedStatus(true);
      }
      if (!response.data.armed) {
        setArmedStatus(false);
      }
      setSiteInfo(response.data);
      setSiteSchedules([]);
      setAllSiteSchedules([]);
      localStorage.setItem("siteSchedule", JSON.stringify([]));
      localStorage.setItem("allSiteSchedulesBackup", JSON.stringify([]));
      if (clickedTab === "three") {
        setActiveTab(
          <SchedulesTab
            allSiteSchedules={[]}
            siteInfo={response.data}
            setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          />
        );
      }
      setBackdropLoadingScreenOn(false);
      setAlertState((previousState: any) => {
        return {
          ...previousState,
          changeSuccess: false,
          activateSuccess: false,
          deactivateSuccess: true,
          disarmSuccess: false,
          armSuccess: false,
          syncSuccess: false,
          cameraDeleteSuccess: false,
          noCameraOrSchedule: false,
          noSchedule: false,
          armFail: false,
        };
      });
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error activating site, please try again");
        setSiteInfo(JSON.parse(localStorage.siteInfo));
        setBackdropLoadingScreenOn(false);
        setPatchObject({ name: siteInfo.name });
      });
    }
  );
};
