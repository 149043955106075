import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleFilterByAlerts } from "./HealthMonitoringUtils/handleFilterByAlerts";

export const FilterMenuAlerts = ({
  alertFilter,
  setAlertFilter,
  alertsData,
  setFilteredDataLengths,
  singleCellActivated,
  setActiveTable,
  setAlertStatusDialogOpen,
  setSelectedRow,
  setSelectedRowIndex,
  setFectchingAllData,
  setAlertsData,
  setUnhealthyCamsData,
  unhealthyCamsData,
  clickedTab,
  setSortedData,
  setUpdatingStatus,
  filters,
  activeName,
  setSceneChangeOutcomesDialogOpen,
  setBackgroundFrame,
  setDetectedFrame,
  setSelectedRows,
  selectedRows,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  cameraDropDownValue,
  sceneChangeValueString,
  setBulkSceneChangeOutcomesDialogOpen,
  navigate,
}: {
  alertFilter: string;
  setAlertFilter: Dispatcher<string>;
  alertsData: any;
  setFilteredDataLengths: any;
  singleCellActivated: boolean;
  setActiveTable: any;
  setAlertStatusDialogOpen: any;
  setSelectedRow: any;
  setSelectedRowIndex: any;
  setFectchingAllData: any;
  setAlertsData: any;
  setUnhealthyCamsData: any;
  unhealthyCamsData: any;
  clickedTab: any;
  setSortedData: any;
  setUpdatingStatus: any;
  filters: any;
  activeName: string;
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setSelectedRows: any;
  selectedRows: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  cameraDropDownValue: any;
  sceneChangeValueString: string;
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  navigate: any;
}) => {
  const optionsArrayArray = [
    { name: "All Alerts", value: "all" },
    // { name: "Connectivity Alerts", value: "connectivity" },
    // { name: "Image Quality Alerts", value: "image_quality" },
    // { name: "Scene Change Alerts", value: "scene_change" },
    // { name: "Stream Quality Alerts", value: "stream_quality" },
    // { name: "Motion Status Alerts", value: "motion_status" },
    // { name: "Recording Status", value: "recording_status" },
  ];
  if (
    filters.metrics.includes("Connectivity") ||
    filters.metrics.length === 0
  ) {
    optionsArrayArray.push({
      name: "Connectivity Alerts",
      value: "connectivity",
    });
  }
  if (
    filters.metrics.includes("Image Quality") ||
    filters.metrics.length === 0
  ) {
    optionsArrayArray.push({
      name: "Image Quality Alerts",
      value: "image_quality",
    });
  }
  if (
    filters.metrics.includes("Scene Change") ||
    filters.metrics.length === 0
  ) {
    optionsArrayArray.push({
      name: "Scene Change Alerts",
      value: "scene_change",
    });
  }
  if (
    filters.metrics.includes("Stream Quality") ||
    filters.metrics.length === 0
  ) {
    optionsArrayArray.push({
      name: "Stream Quality Alerts",
      value: "stream_quality",
    });
  }
  if (
    filters.metrics.includes("Motion Status") ||
    filters.metrics.length === 0
  ) {
    optionsArrayArray.push({
      name: "Motion Status Alerts",
      value: "motion_status",
    });
  }
  if (
    filters.metrics.includes("Recording Status") ||
    filters.metrics.length === 0
  ) {
    optionsArrayArray.push({
      name: "Recording Status Alerts",
      value: "recording_status",
    });
  }

  const optionMaps = new Map();

  optionsArrayArray.forEach((option: any) => {
    optionMaps.set(`${option.value}`, `${option.name}`);
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div>
        <div className="absolute text-[14px] top-[7.5px] w-[80px] left-[10px]">
          FILTER BY
        </div>
        <div className="absolute left-[77px] w-[220px]">
          <Button
            id="filter-button"
            aria-controls={open ? "filter-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {optionMaps.get(alertFilter)}
          </Button>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
          >
            {optionsArrayArray.map((object: any) => {
              return (
                <MenuItem
                  key={object.value}
                  onClick={() => {
                    setAlertFilter(object.value);
                    setActiveTable(<></>);
                    handleFilterByAlerts(
                      object.value,
                      alertsData,
                      setFilteredDataLengths,
                      singleCellActivated,
                      setActiveTable,
                      setAlertStatusDialogOpen,
                      setSelectedRow,
                      setSelectedRowIndex,
                      setFectchingAllData,
                      setAlertsData,
                      setUnhealthyCamsData,
                      unhealthyCamsData,
                      clickedTab,
                      setSortedData,
                      setUpdatingStatus,
                      filters,
                      activeName,
                      setSceneChangeOutcomesDialogOpen,
                      setBackgroundFrame,
                      setDetectedFrame,
                      setSelectedRows,
                      selectedRows,
                      setErrorsOverviewData,
                      setCameraStatsData,
                      setLoadingCameraStatsData,
                      setBandwidthData,
                      setLoadingBandwidthData,
                      setShowSiteStats,
                      cameraDropDownValue,
                      sceneChangeValueString,
                      setBulkSceneChangeOutcomesDialogOpen,
                      navigate
                    );
                    handleClose();
                  }}
                >
                  {object.name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </div>
  );
};
