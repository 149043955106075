import * as React from "react";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import {
  AlertState,
  SiteCameras,
  SiteSchedule,
  SiteInfo,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { handleSyncSite } from "./SiteAboutPageUtils/handleSyncSite";
import { getNumbersAfterAboutSite } from "./SiteAbout";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const SiteAboutAlerts = ({
  siteDeployed,
  aiLinkSite,
  setBackdropLoadingScreenOn,
  setSyncNeeded,
  setAlertState,
  siteInfo,
  setSiteInfo,
  setAllSiteSchedules,
  setActiveTab,
  vmsVariables,
  setSiteDeployed,
  setDeativateInquiryDialogOpen,
  setSiteCameras,
  camerasNotDeployed,
  setCamerasNotDeployed,
  navigate,
  setVmsVariables,
  alertState,
  setEditMode,
  setClickedTab,
  setArmButtonDisabled,
  setArmedStatus,
}: {
  siteDeployed: boolean;
  aiLinkSite: boolean;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setSyncNeeded: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  siteInfo: SiteInfo;
  setSiteInfo: Dispatcher<SiteInfo>;
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>;
  setActiveTab: Dispatcher<JSX.Element>;
  vmsVariables: (string | string[] | undefined)[][];
  setSiteDeployed: Dispatcher<boolean>;
  setDeativateInquiryDialogOpen: Dispatcher<boolean>;
  setSiteCameras: Dispatcher<SiteCameras>;
  camerasNotDeployed: boolean;
  setCamerasNotDeployed: Dispatcher<boolean>;
  navigate: NavigateFunction;
  setVmsVariables: Dispatcher<(string | string[] | undefined)[][]>;
  alertState: AlertState;
  setEditMode: Dispatcher<boolean>;
  setClickedTab: Dispatcher<string>;
  setArmButtonDisabled: any;
  setArmedStatus: any;
}) => {
  return (
    <div>
      {!siteDeployed && !aiLinkSite && (
        <Alert severity="warning">
          <p className="font-mulish font-bold text-[16px]">
            Site not deployed.{" "}
          </p>
          <p className="font-mulish ftext-[15px]">
            Please&nbsp;
            <Link
              to={`/sites/aboutsite/${getNumbersAfterAboutSite(location.href)}`}
              onClick={() => {
                setBackdropLoadingScreenOn(true);
                setSyncNeeded(false);
                handleSyncSite(
                  setBackdropLoadingScreenOn,
                  setAlertState,
                  siteInfo,
                  setSiteInfo,
                  setSyncNeeded,
                  setAllSiteSchedules,
                  setActiveTab,
                  vmsVariables,
                  setSiteDeployed,
                  setDeativateInquiryDialogOpen,
                  setSiteCameras,
                  camerasNotDeployed,
                  setCamerasNotDeployed,
                  navigate,
                  setVmsVariables,
                  setClickedTab,
                  setArmButtonDisabled,
                  setArmedStatus
                );
              }}
              className="underline underline-offset-1"
            >
              Sync Site
            </Link>
            &nbsp;to deploy site.
          </p>
        </Alert>
      )}
      {alertState.noCameraOrSchedule &&
        !aiLinkSite &&
        localStorage.noAutoAddCamerasDialogOpen === "false" && (
          <Alert severity="warning">
            <p className="font-mulish font-bold text-[16px]">
              Incomplete Information
            </p>
            <p className="font-mulish ftext-[15px]">
              Please&nbsp;
              {siteInfo.auto_add ? (
                <Link
                  to="/sites/autoaddcamera"
                  className="underline underline-offset-1"
                >
                  Add Cameras
                </Link>
              ) : (
                <Link
                  to="/sites/addCamera"
                  className="underline underline-offset-1"
                  onClick={() => {
                    localStorage.setItem("firstCameraAdded", "true");
                    localStorage.setItem("copyValues", "true");
                  }}
                >
                  Add Cameras
                </Link>
              )}
              &nbsp;and then&nbsp;
              <Link
                to="/sites/addschedule"
                className="underline underline-offset-1"
              >
                Add Schedule
              </Link>
              &nbsp;to run the Site.
            </p>
          </Alert>
        )}
      {alertState.noSchedule &&
        !aiLinkSite &&
        !siteInfo.chm &&
        Boolean(siteInfo.active) === true && (
          <Alert severity="warning">
            <p className="font-mulish font-bold text-[16px]">
              Incomplete Information
            </p>
            <p className="font-mulish ftext-[15px]">
              Please&nbsp;
              <Link
                to="/sites/addschedule"
                className="underline underline-offset-1"
              >
                Add Schedule
              </Link>{" "}
              to run the Site.
            </p>
          </Alert>
        )}
      {alertState.activateSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, activateSuccess: false };
            });
          }}
        >
          Site successfully activated.
        </Alert>
      )}
      {alertState.deactivateSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, deactivateSuccess: false };
            });
          }}
        >
          Site successfully deactivated.
        </Alert>
      )}
      {alertState.changeSuccess && (
        <Alert
          severity="warning"
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, changeSuccess: false };
            });
            setEditMode(false);
          }}
        >
          Changes saved successfully! Please&nbsp;
          <Link
            to={`/sites/aboutsite/${getNumbersAfterAboutSite(location.href)}`}
            onClick={() => {
              setBackdropLoadingScreenOn(true);
              setSyncNeeded(false);
              handleSyncSite(
                setBackdropLoadingScreenOn,
                setAlertState,
                siteInfo,
                setSiteInfo,
                setSyncNeeded,
                setAllSiteSchedules,
                setActiveTab,
                vmsVariables,
                setSiteDeployed,
                setDeativateInquiryDialogOpen,
                setSiteCameras,
                camerasNotDeployed,
                setCamerasNotDeployed,
                navigate,
                setVmsVariables,
                setClickedTab,
                setArmButtonDisabled,
                setArmedStatus
              );
            }}
            className="underline underline-offset-1"
          >
            Sync Site
          </Link>
          &nbsp;to apply changes.
        </Alert>
      )}
      {alertState.disarmSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, disarmSuccess: false };
            });
          }}
        >
          Site disarm command sent successfully. It may take up to 5 minutes for
          site arm status to update.
        </Alert>
      )}
      {alertState.armSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, armSuccess: false };
            });
          }}
        >
          Site arm command sent successfully. It may take up to 5 minutes for
          arm status to update.
        </Alert>
      )}
      {alertState.armFail && (
        <Alert
          severity="warning"
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, armFail: false };
            });
          }}
        >
          Site arm command failed. Please try again
        </Alert>
      )}
      {alertState.syncSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, syncSuccess: false };
            });
          }}
        >
          Site synced successfully.
        </Alert>
      )}
      {alertState.configureMotionSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, configureMotionSuccess: false };
            });
          }}
        >
          Configure Motion request sent successfully.
        </Alert>
      )}
      {alertState.configureMotionFail && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, configureMotionFail: false };
            });
          }}
        >
          Configure Motion request failed. Please try again.
        </Alert>
      )}
      {alertState.refreshCameraSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, refreshCameraSuccess: false };
            });
          }}
        >
          Cameras refreshed successfully.
        </Alert>
      )}
      {alertState.refreshCameraFail && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, refreshCameraFail: false };
            });
          }}
        >
          Cameras failed to refresh. Please try again.
        </Alert>
      )}
      {alertState.webhookChangesFail && (
        <Alert
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, webhookChangesFail: false };
            });
          }}
        >
          Webhooks updates failed to save. Please try again.
        </Alert>
      )}
      {alertState.cameraDeleteSuccess && (
        <Alert
          severity="warning"
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, cameraDeleteSuccess: false };
            });
          }}
        >
          Camera delete successful! Please{" "}
          <Link
            to={`/sites/aboutsite/${getNumbersAfterAboutSite(location.href)}`}
            onClick={() => {
              setBackdropLoadingScreenOn(true);
              setSyncNeeded(false);
              handleSyncSite(
                setBackdropLoadingScreenOn,
                setAlertState,
                siteInfo,
                setSiteInfo,
                setSyncNeeded,
                setAllSiteSchedules,
                setActiveTab,
                vmsVariables,
                setSiteDeployed,
                setDeativateInquiryDialogOpen,
                setSiteCameras,
                camerasNotDeployed,
                setCamerasNotDeployed,
                navigate,
                setVmsVariables,
                setClickedTab,
                setArmButtonDisabled,
                setArmedStatus
              );
            }}
            className="underline underline-offset-1"
          >
            Sync Site
          </Link>
          &nbsp;to apply changes.
        </Alert>
      )}
      {camerasNotDeployed && (
        <Alert
          severity="warning"
          onClose={() => {
            setCamerasNotDeployed(false);
          }}
        >
          One or more cameras has changes which have not been deployed! Please{" "}
          <Link
            to={`/sites/aboutsite/${getNumbersAfterAboutSite(location.href)}`}
            onClick={() => {
              setBackdropLoadingScreenOn(true);
              handleSyncSite(
                setBackdropLoadingScreenOn,
                setAlertState,
                siteInfo,
                setSiteInfo,
                setSyncNeeded,
                setAllSiteSchedules,
                setActiveTab,
                vmsVariables,
                setSiteDeployed,
                setDeativateInquiryDialogOpen,
                setSiteCameras,
                camerasNotDeployed,
                setCamerasNotDeployed,
                navigate,
                setVmsVariables,
                setClickedTab,
                setArmButtonDisabled,
                setArmedStatus
              );
            }}
            className="underline underline-offset-1"
          >
            Sync Site
          </Link>
          &nbsp;to apply changes.
        </Alert>
      )}
    </div>
  );
};
