import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { TextField } from "@mui/material";
import { handleUpdateGroupListName } from "./SitesPageUtils/handleUpdateGroupListName";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const EditGroupNameDialog = ({
  editGroupNameDialogOpen,
  setEditGroupNameDialogOpen,
  initialValue,
  setBackdropLoadingScreenOn,
  setSelectedGroupListName,
  groupTrees,
  setGroupTrees,
  navigate,
}: {
  editGroupNameDialogOpen: boolean;
  setEditGroupNameDialogOpen: Dispatcher<boolean>;
  initialValue: string;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setSelectedGroupListName: Dispatcher<string>;
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
  navigate: NavigateFunction;
}) => {
  const [newGroupName, setNewGroupName] = React.useState(initialValue);

  return (
    <Dialog open={editGroupNameDialogOpen}>
      <DialogTitle id="alert-dialog-title">{"Edit Group Name"}</DialogTitle>

      <DialogContent>
        <TextField
          defaultValue={initialValue}
          id="immix_server"
          variant="standard"
          label="Group Name"
          onChange={(event) => {
            setNewGroupName(event.target.value);
          }}
          sx={{ width: "400px" }}
          type="string"
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setEditGroupNameDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setEditGroupNameDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleUpdateGroupListName(
              newGroupName,
              setBackdropLoadingScreenOn,
              setSelectedGroupListName,
              groupTrees,
              setGroupTrees,
              navigate
            );
          }}
          text="Save"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
