import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { handleImmixServerInput } from "./handleImmixServerInput";
import { handleImmixPortInput } from "./handleImmixPortInput";
import { productsMatcher } from "../../../utils/productsMatcher";
import { createCamerasObject } from "./createCamerasObject";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { handleSentinelInput } from "./handleSentinelInput";

export const getAutoAddData = (
  setCamerasList: any,
  setCamerasObject: any,
  setFetchingAutoAddData: Dispatcher<boolean>,
  checkboxesChecked: any,
  setCheckboxesChecked: any,
  setClientProducts: any,
  setImmixSite: Dispatcher<boolean>,
  setSureviewSite: Dispatcher<boolean>,
  setProductsArray: any,
  setAutoAddSiteInfo: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    getAutoAddData(
      setCamerasList,
      setCamerasObject,
      setFetchingAutoAddData,
      checkboxesChecked,
      setCheckboxesChecked,
      setClientProducts,
      setImmixSite,
      setSureviewSite,
      setProductsArray,
      setAutoAddSiteInfo,
      navigate
    );
  };

  AxiosUI.get(`nvr_camera/?customer__id=${localStorage.siteId}`).then(
    (response: AxiosResponse) => {
      if (response.data.count === 0) {
        localStorage.setItem("noAutoAddCamerasDialogOpen", "true");
      }
      if (response.data.count > 0) {
        localStorage.setItem("noAutoAddCamerasDialogOpen", "false");
      }
      setCamerasList(response.data);
      setCamerasObject(createCamerasObject(response.data.results));
      const createdCamerasObject = createCamerasObject(response.data.results);
      const immixAlertPostObject = JSON.parse(
        localStorage.immixAlertPostObject
      );
      if (immixAlertPostObject.immix_server) {
        handleImmixServerInput(
          createdCamerasObject,
          setCamerasObject,
          immixAlertPostObject.immix_server
        );
      }
      if (immixAlertPostObject.immix_port) {
        handleImmixPortInput(
          createdCamerasObject,
          setCamerasObject,
          immixAlertPostObject.immix_port
        );
      }
      const sentinelAlertPostObject = JSON.parse(
        localStorage.sentinelAlertPostObject
      );
      if (sentinelAlertPostObject.sentinel_server) {
        handleSentinelInput(
          setCamerasObject,
          createdCamerasObject,
          sentinelAlertPostObject.sentinel_server.toString(),
          false,
          null,
          "sentinelServer"
        );
      }
      if (sentinelAlertPostObject.sentinel_site_id) {
        handleSentinelInput(
          setCamerasObject,
          createdCamerasObject,
          sentinelAlertPostObject.sentinel_site_id.toString(),
          false,
          null,
          "sentinelSiteId"
        );
      }
      if (sentinelAlertPostObject.sentinel_device_address) {
        handleSentinelInput(
          setCamerasObject,
          createdCamerasObject,
          sentinelAlertPostObject.sentinel_device_address.toString(),
          false,
          null,
          "sentinelDeviceAddress"
        );
      }
      if (sentinelAlertPostObject.draw_ignore_zones) {
        handleSentinelInput(
          setCamerasObject,
          createdCamerasObject,
          "",
          sentinelAlertPostObject.draw_ignore_zones,
          null,
          "drawIgnoreZones"
        );
      }
      const nvrResults = response.data.results;
      AxiosUI.get(
        `nvr_camera/product/?customer__id=${localStorage.siteId}`
      ).then((response: AxiosResponse) => {
        const productResponse = response.data;
        setClientProducts(productResponse);
        setProductsArray(response.data);
        const productsArray: any = [];
        productResponse.forEach((array: any[]) => {
          productsArray.push(array[0]);
        });
        setClientProducts(productsArray);
        const idsArray: any = [];
        productResponse.forEach((array: any[]) => {
          idsArray.push(array[1]);
        });
        idsArray.forEach((id: any) => {
          if (productsMatcher("Name", id, productResponse)) {
            const name = productsMatcher("Name", id, productResponse);
            setCheckboxesChecked((previousState: any) => {
              return { ...previousState, [name]: false };
            });
            checkboxesChecked[name] = false;
          }
          nvrResults.forEach((object: any) => {
            if (!object.camera) {
              const name = productsMatcher("Name", id, productResponse);
              const newName = `${name}${object.id}`;
              setCheckboxesChecked((previousState: any) => {
                return { ...previousState, [newName]: false };
              });
            }
          });
        });
        // if (localStorage.immixAlertsSite === "true") {
        //   setImmixSite(true);
        // }
        // if (localStorage.sureviewAlertsSite === "true") {
        //   setSureviewSite(true);
        // }
        AxiosUI.get(`customer/${localStorage.siteId}/about/`).then(
          (response: AxiosResponse) => {
            setAutoAddSiteInfo(response.data);
            setFetchingAutoAddData(false);
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              setAutoAddSiteInfo(
                localStorage.siteInfo ? JSON.parse(localStorage.siteInfo) : {}
              );
              setFetchingAutoAddData(false);
            });
          }
        );
      }),
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert("Error retrieving data, please refresh and try again");
            setFetchingAutoAddData(false);
          });
        };
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setFetchingAutoAddData(false);
      });
    }
  );
};
