import { createApi } from "../../utils/createApi";
import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import { WizardContainerScreen } from "./WizardContainerScreen";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

export const handleSubmitIntegerationType = async (
  setVisibleScreen: any,
  handleSubmitSite: any,
  navigate: any,
  setIntegrationSubmitError: any
) => {
  const integrationsTypes = JSON.parse(localStorage.integrationTypes);

  const autoAddNums: number[] = [];

  integrationsTypes.forEach((object: any) => {
    if (object.is_auto_add) {
      autoAddNums.push(object.id);
    }
  });

  const retryFunction = () => {
    handleSubmitIntegerationType(
      setVisibleScreen,
      handleSubmitSite,
      navigate,
      setIntegrationSubmitError
    );
  };

  const AxiosUI = createApi(``);
  await AxiosUI.get(
    `integration_type/${localStorage.integrationType}/onboarding_forms/`
  ).then(
    (response: AxiosResponse) => {
      const responseObject = {
        initial_data: response.data.initial_data,
        forms: response.data.forms,
      };
      localStorage.setItem(
        "responseObjectBackup",
        JSON.stringify(responseObject)
      );
      localStorage.removeItem("integrationSubmitError");
      setVisibleScreen(
        <WizardContainerScreen
          handleSubmitSite={handleSubmitSite}
          responseObject={responseObject}
          setVisibleScreen={setVisibleScreen}
        />
      );
      if (autoAddNums.includes(Number(localStorage.integrationType))) {
        localStorage.setItem("autoAdd", "true");
      } else {
        localStorage.setItem("autoAdd", "false");
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setIntegrationSubmitError(true);
      });
    }
  );
};
