import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { handleNestedLevelClick } from "./handleNestedLevelClick";
import Tooltip from "@mui/material/Tooltip";

export const createNestedListItemButton = (
  arrayItem: any,
  selectedIndex: any,
  setPage: any,
  setCurrentTitle: any,
  levelName: any,
  setSelectedIndex: any,
  setRenderedRows: any,
  setPageLoading: any,
  navigate: any,
  indentNumber: any,
  maxWordLength: number,
  setSiteBreadcrumbs: any,
  navBarCollapsed: boolean,
  setSelected: any,
  setSelectedGroupListName: any,
  setEditableGroupSelected: any
) => {
  localStorage.setItem("selectedItemSiteGroupList", JSON.stringify(arrayItem));

  return (
    // <Tooltip
    //   title={arrayItem.name.length > maxWordLength ? arrayItem.name : ""}
    //   placement="right"
    // >
    <span className="text-[10px]">
      <ListItemButton
        key={arrayItem.id}
        onClick={() => {
          handleNestedLevelClick(
            setPage,
            setCurrentTitle,
            arrayItem.name,
            arrayItem.id,
            selectedIndex,
            levelName,
            setSelectedIndex,
            setRenderedRows,
            setPageLoading,
            navigate,
            setSiteBreadcrumbs,
            setSelected,
            setSelectedGroupListName,
            setEditableGroupSelected
          );
        }}
        sx={{ pl: indentNumber }}
      >
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        {arrayItem.name.length < 18 || navBarCollapsed ? (
          <ListItemText
            primary={`${arrayItem.name}`}
            primaryTypographyProps={{
              fontSize: 14,
              fontFamily: "Mulish",
            }}
          />
        ) : (
          <Tooltip
            title={arrayItem.name}
            placement="top"
            sx={{ fontSize: "15px", fontFamily: "mulish" }}
          >
            <ListItemText
              primary={`${arrayItem.name.slice(0, 15)}...`}
              primaryTypographyProps={{
                fontSize: 14,
                fontFamily: "Mulish",
              }}
            />
          </Tooltip>
        )}
        {/* {arrayItem.name.length < maxWordLength ? (
            <>
              {`${arrayItem.name} - ${arrayItem.children.length}`}
              <VideocamOutlinedIcon fontSize="small" />
            </>
          ) : (
            <>
              {`${arrayItem.name.slice(0, maxWordLength - 1)}... - ${
                arrayItem.children.length
              }`}
              <VideocamOutlinedIcon fontSize="small" />
            </>
          )} */}
        {
          // arrayItem.id === selectedIndex[levelName]
          selectedIndex[levelName].includes(arrayItem.id) ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        }
      </ListItemButton>
    </span>
    // </Tooltip>
  );
};
