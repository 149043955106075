import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";

export const StreamParametersTab = ({
  streamParametersData,
}: {
  streamParametersData: any;
}) => {
  const titlesArray: string[] = [];

  const extraFields = streamParametersData.extra_fields;

  extraFields.forEach((field: any) => {
    titlesArray.push(field.label);
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000, fontFamily: "Mulish" }}>
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          <TableRow key={`stream_parameters`}>
            {extraFields.map((object: any) => (
              <TableCell key={object.id} align="left">
                {streamParametersData[object.field] !== null
                  ? streamParametersData[object.field]
                  : "--"}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
