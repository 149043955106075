import { createApi } from "../../../../utils/createApi";
import { AxiosResponse } from "axios";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { VmsTab } from "../VmsTab";
import { SchedulesTab } from "../SchedulesTab";
import { getRefreshToken } from "../../../../utils/getRefreshToken";
import { getNumbersAfterAboutSite } from "../SiteAbout";
import { handleSaveChangesButtonClick } from "./handleSaveChangesButtonClick";
import { AlertState } from "./siteAboutInterfaces";

export const submitScheduleChanges = async (
  allSiteSchedules: any,
  setAllSiteSchedules: any,
  setEditMode: any,
  setBackdropLoadingScreenOn: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  vmsVariables: any,
  setDeleteScheduleDialogOpen: any,
  navigate: any,
  patchObject: any,
  setSiteInfo: any,
  setPatchObject: any,
  siteSchedules: any,
  setAlertState: any,
  setSyncNeeded: any,
  editMode: any,
  setSchedulesTabActive: any,
  setClickedTab: any,
  siteInfoChangesMade: any,
  setSiteInfoChangesMade: any,
  scheduleChangesMade: any,
  setScheduleChangesMade: any,
  setSiteSchedules: any,
  setArmedStatus: any,
  setDeactivateSiteInsteadDialogOpen: any,
  setArmButtonDisabled: any,
  recordingServerChangesMade: any,
  recordingServerData: any,
  recordingServerPatchObject: any,
  setRecordingServerData: any,
  setRecordingServerPatchObject: any,
  setRecordingServerChangesMade: any,
  cameraHealthChangesMade: any,
  healthCheckData: any,
  setHealthCheckData: any,
  cameraHealthPatchObject: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: any,
  webhooksChangesMade: any,
  webhooksInfo: any,
  setWebhooksInfo: any,
  webhooksPatchObject: any,
  setWebhooksPatchObject: any,
  setWebhooksChangesMade: any,
  setActivateSiteWithScheduleDialogOpen: any
) => {
  const AxiosUI = createApi(``);
  const allSiteSchedulesBackup = JSON.parse(
    localStorage.allSiteSchedulesBackup
  );
  const returnFunction = () => {
    handleSaveChangesButtonClick(
      patchObject,
      setEditMode,
      setSiteInfo,
      setPatchObject,
      siteInfo,
      clickedTab,
      setActiveTab,
      vmsVariables,
      allSiteSchedules,
      siteSchedules,
      setAllSiteSchedules,
      setBackdropLoadingScreenOn,
      setAlertState,
      setSyncNeeded,
      editMode,
      setSchedulesTabActive,
      setClickedTab,
      siteInfoChangesMade,
      setSiteInfoChangesMade,
      scheduleChangesMade,
      setScheduleChangesMade,
      setDeleteScheduleDialogOpen,
      navigate,
      setSiteSchedules,
      setArmButtonDisabled,
      recordingServerChangesMade,
      recordingServerData,
      recordingServerPatchObject,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerChangesMade,
      cameraHealthChangesMade,
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      setArmedStatus,
      webhooksChangesMade,
      webhooksInfo,
      setWebhooksInfo,
      webhooksPatchObject,
      setWebhooksPatchObject,
      setWebhooksChangesMade,
      setDeactivateSiteInsteadDialogOpen,
      handleSaveChangesButtonClick
    );
  };
  setActiveTab(<></>);

  const submittedSchedules = allSiteSchedules;

  //check each schedule object in submittedSchedules and if it's start_time or "end_time" is ":00" then replace with null
  submittedSchedules.forEach((schedule: any, index: number) => {
    if (schedule.start_time !== null) {
      if (schedule.start_time === ":00") {
        submittedSchedules[index].start_time = null;
      }
    }
    if (schedule.end_time !== null) {
      if (schedule.end_time === ":00") {
        submittedSchedules[index].end_time = null;
      }
    }
  });

  //remove any schedule object that has a null start_time and end_time and false value for "always_on"
  for (let i = 0; i < submittedSchedules.length; i++) {
    if (
      submittedSchedules[i].start_time === null &&
      submittedSchedules[i].end_time === null &&
      submittedSchedules[i].always_on === false
    ) {
      submittedSchedules.splice(i, 1);
    }
  }

  if (!submittedSchedules[0]) {
    setDeactivateSiteInsteadDialogOpen(true);
  } else {
    await AxiosUI.post(`schedule/`, submittedSchedules).then(
      () => {
        localStorage.setItem("scheduleChangeError", "false");
        localStorage.setItem(
          "allSiteSchedulesBackup",
          JSON.stringify(allSiteSchedulesBackup)
        );
        setAlertState((previousState: AlertState) => {
          return { ...previousState, noSchedule: false };
        });
        AxiosUI.get(
          `schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`
        ).then(
          (response: AxiosResponse) => {
            setScheduleChangesMade(false);
            localStorage.setItem(
              "siteSchedules",
              JSON.stringify(response.data)
            );
            localStorage.setItem("siteSchedule", JSON.stringify(response.data));
            setSiteSchedules(response.data);
            setAllSiteSchedules(response.data);
            localStorage.setItem(
              "allSiteSchedulesBackup",
              JSON.stringify(response.data)
            );
            setEditMode(false);
            setBackdropLoadingScreenOn(false);
            setAlertState((previousState: any) => {
              return {
                ...previousState,
                changeSuccess: false,
                activateSuccess: false,
                deactivateSuccess: false,
                disarmSuccess: false,
                armSuccess: false,
                syncSuccess: false,
                cameraDeleteSuccess: false,
                armFail: false,
              };
            });
            if (!siteInfo.active) {
              setActivateSiteWithScheduleDialogOpen(true);
            }
            if (clickedTab === "one") {
              setActiveTab(
                <GeneralInfoTab
                  siteInfo={siteInfo}
                  setSiteInfo={setSiteInfo}
                  setActiveTab={setActiveTab}
                />
              );
            } else if (clickedTab === "two") {
              setActiveTab(
                <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
              );
            } else if (clickedTab === "three") {
              if (localStorage.scheduleSubmitError === "true") {
                localStorage.removeItem("scheduleSubmitError");
                setActiveTab(
                  <SchedulesTab
                    allSiteSchedules={response.data}
                    siteInfo={siteInfo}
                    setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                  />
                );
              } else {
                setActiveTab(
                  <SchedulesTab
                    allSiteSchedules={response.data}
                    siteInfo={siteInfo}
                    setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                  />
                );
              }
            }
          },
          (reason: any) => {
            if (
              reason.request.response.slice(11, 53) ===
                "SocialToken matching query does not exist." ||
              reason.request.response.includes(
                "Returned invalid token from cache"
              ) ||
              reason.request.response.slice(11, 53) === "Token is expired"
            ) {
              getRefreshToken(navigate, returnFunction);
            } else {
              setSiteInfo(JSON.parse(localStorage.siteInfo));
              setEditMode(false);
              if (clickedTab === "one") {
                setActiveTab(
                  <GeneralInfoTab
                    siteInfo={siteInfo}
                    setSiteInfo={setSiteInfo}
                    setActiveTab={setActiveTab}
                  />
                );
              } else if (clickedTab === "two") {
                setActiveTab(
                  <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
                );
              } else if (clickedTab === "three") {
                setActiveTab(
                  <SchedulesTab
                    allSiteSchedules={allSiteSchedules}
                    siteInfo={siteInfo}
                    setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                  />
                );
              }
            }
          }
        );
      },
      (reason: any) => {
        localStorage.setItem("scheduleChangeError", "true");
        localStorage.setItem("scheduleSubmitError", "true");
        setAllSiteSchedules(allSiteSchedulesBackup);
        localStorage.setItem(
          "allSiteSchedules",
          JSON.stringify(allSiteSchedulesBackup)
        );
        if (
          reason.request.response.includes(
            "overlaps with existing enabled schedule"
          )
        ) {
          alert(
            `Schedule Changes not accepted: One or more days on changed schedule overlaps with day(s) from another scheulde at this Site.`
          );
          setEditMode(false);
          setBackdropLoadingScreenOn(false);
          setAllSiteSchedules(allSiteSchedulesBackup);
          if (clickedTab === "one") {
            setActiveTab(
              <GeneralInfoTab
                siteInfo={siteInfo}
                setSiteInfo={setSiteInfo}
                setActiveTab={setActiveTab}
              />
            );
          } else if (clickedTab === "two") {
            setActiveTab(
              <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
            );
          } else if (clickedTab === "three") {
            setActiveTab(
              <SchedulesTab
                allSiteSchedules={allSiteSchedulesBackup}
                siteInfo={siteInfo}
                setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
              />
            );
          }
        } else if (
          reason.request.response.slice(11, 53) ===
            "SocialToken matching query does not exist." ||
          reason.request.response.includes("Returned invalid token from cache")
        ) {
          getRefreshToken(navigate, returnFunction);
          setEditMode(false);
          if (clickedTab === "one") {
            setActiveTab(
              <GeneralInfoTab
                siteInfo={siteInfo}
                setSiteInfo={setSiteInfo}
                setActiveTab={setActiveTab}
              />
            );
          } else if (clickedTab === "two") {
            setActiveTab(
              <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
            );
          } else if (clickedTab === "three") {
            setActiveTab(
              <SchedulesTab
                allSiteSchedules={allSiteSchedules}
                siteInfo={siteInfo}
                setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
              />
            );
          }
        } else {
          alert(`Schedule Changes not accepted, please try again`);
          setEditMode(false);
          setBackdropLoadingScreenOn(false);
          setAllSiteSchedules(allSiteSchedulesBackup);
          if (clickedTab === "one") {
            setActiveTab(
              <GeneralInfoTab
                siteInfo={siteInfo}
                setSiteInfo={setSiteInfo}
                setActiveTab={setActiveTab}
              />
            );
          } else if (clickedTab === "two") {
            setActiveTab(
              <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
            );
          } else if (clickedTab === "three") {
            setActiveTab(
              <SchedulesTab
                allSiteSchedules={allSiteSchedulesBackup}
                siteInfo={siteInfo}
                setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
              />
            );
          }
        }
      }
    );
  }
};
