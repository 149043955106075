import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { checkForImmixOrSureviewOrSentinel } from "./checkForImmixOrSureviewOrSentinel";
import { fetchAddCameraInfo } from "../../SitesPage/SitesPageUtils/fetchAddCameraInfo";
import {
  AlertState,
  Dispatcher,
  ExtraFields,
  SiteCamerasResult,
  SiteSchedule,
} from "./siteAboutInterfaces";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { checkArmedStatus } from "./checkArmedStatus";
import { getRecordingServerData } from "./getRecordingServerData";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { integrationsMatcher } from "../../../../utils/integrationsMatcher";
import { get } from "http";

export const handleGetAllData = async (
  siteId: any,
  navigate: any,
  setSiteInfo: any,
  setSiteSchedules: any,
  setSiteCameras: any,
  setActiveTab: any,
  setDataLoaded: any,
  setBackdropLoadingScreenOn: any,
  setAllSiteSchedules: any,
  setVmsVariables: any,
  setNoAutoAddCamerasDialogOpen: any,
  setArmedStatus: any,
  setAiLinkSite: any,
  setSiteDeployed: any,
  setSyncNeeded: any,
  setAlertState: any,
  setCamerasNotDeployed: any,
  setToolTipTitle: any,
  setConfigureMotion: any,
  setPatchObject: any,
  setArmButtonDisabled: any,
  setRecordingServerData: any,
  setRecordingServerPatchObject: any,
  setRecordingServerVisible: Dispatcher<boolean>,
  setHealthCheckData: any,
  setWebhooksInfo: any,
  setWebhooksPatchObject: any
) => {
  localStorage.setItem("siteId", siteId);
  let autoAdd = true;
  let noAutoAddCamerasDialogOpen = true;
  let siteCameraCount = 0;
  let siteInfoSettingsDeployed = true;
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleGetAllData(
      siteId,
      navigate,
      setSiteInfo,
      setSiteSchedules,
      setSiteCameras,
      setActiveTab,
      setDataLoaded,
      setBackdropLoadingScreenOn,
      setAllSiteSchedules,
      setVmsVariables,
      setNoAutoAddCamerasDialogOpen,
      setArmedStatus,
      setAiLinkSite,
      setSiteDeployed,
      setSyncNeeded,
      setAlertState,
      setCamerasNotDeployed,
      setToolTipTitle,
      setConfigureMotion,
      setPatchObject,
      setArmButtonDisabled,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerVisible,
      setHealthCheckData,
      setWebhooksInfo,
      setWebhooksPatchObject
    );
  };
  let getSiteInfoFail = false;
  let getNVRCamerasFail = false;
  let getExtraFieldsFail = false;
  let getSiteCamerasFail = false;
  let getSMTPInstructionsFail = false;
  let getSchedulesFail = false;
  let getHealthcheckDataFail = false;
  localStorage.setItem("noAutoAddCamerasDialogOpen", "true");

  let allSiteCameras: any;

  const getSiteCameras = (pageNumber: number) => {
    AxiosUI.get(`camera/site/?customer__id=${siteId}&page=${pageNumber}`).then(
      (response: AxiosResponse) => {
        getSiteCamerasFail = false;
        if (response.data.count > 0) {
          if (pageNumber === 1) {
            allSiteCameras = response.data;
            siteCameraCount = response.data.count;
          }
          if (pageNumber > 1) {
            allSiteCameras.results.push(...response.data.results);
          }
          if (response.data.next) {
            getSiteCameras(pageNumber + 1);
          } else {
            allSiteCameras;
            localStorage.setItem("siteCameras", JSON.stringify(allSiteCameras));
            setSiteCameras(allSiteCameras);
            checkForImmixOrSureviewOrSentinel(
              response.data.results[0].id,
              navigate
            );
            if (
              siteInfoSettingsDeployed &&
              localStorage.navigatedFromEditCameraPage !== "true"
            ) {
              response.data.results.forEach((cameraObj: SiteCamerasResult) => {
                if (!cameraObj.deployed) {
                  setCamerasNotDeployed(true);
                  setSyncNeeded(true);
                }
              });
            } else if (
              siteInfoSettingsDeployed &&
              localStorage.navigatedFromEditCameraPage === "true"
            ) {
              localStorage.removeItem("navigatedFromEditCameraPage");
            }
          }
        } else {
          setSiteCameras(response.data);
          localStorage.setItem("siteCameras", JSON.stringify(response.data));
        }
      },
      (reason: AxiosError) => {
        getSiteCamerasFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Error loading site data");
          navigate(`/sites`);
        });
      }
    );
  };

  await AxiosUI.get(`customer/${siteId}/about/`).then(
    (response: AxiosResponse) => {
      getSiteInfoFail = false;
      fetchAddCameraInfo(siteId, navigate);
      localStorage.setItem(
        "selectedIntegrationType",
        integrationsMatcher("FullName", response.data.integration_type_name)
      );
      if (response.data.integration_type_name === "milestone") {
        setRecordingServerVisible(true);
        getRecordingServerData(
          response.data.id,
          setRecordingServerData,
          setRecordingServerPatchObject,
          navigate
        );
      }
      localStorage.setItem("siteInfo", JSON.stringify(response.data));
      const siteInfo = response.data;
      setSiteInfo(response.data);
      checkArmedStatus(
        response.data,
        setArmButtonDisabled,
        navigate,
        setSiteInfo,
        setArmedStatus
      );
      setPatchObject({
        name: siteInfo.name,
      });
      const newVMSVariables: any[] = [];
      siteInfo.extra_fields.forEach((object: ExtraFields) => {
        const newArray = [
          `${object.label}`,
          `${siteInfo[object.id]}`,
          `${object.id}`,
          `${object.type}`,
          object.choices,
        ];
        object.choices
          ? (newArray[4] = object.choices)
          : (newArray[4] = object.options);
        newVMSVariables.push(newArray);
      });
      setVmsVariables(newVMSVariables);
      localStorage.setItem("siteInfo", JSON.stringify(siteInfo));
      localStorage.setItem("integrationType", siteInfo.onboarding_integration);
      if (siteInfo.auto_add) {
        localStorage.setItem("autoAdd", "true");
        autoAdd = true;
      } else {
        localStorage.setItem("autoAdd", "false");
        autoAdd = false;
      }
      if (siteInfo.armed) {
        setArmedStatus(true);
      }
      if (!siteInfo.armed) {
        setArmedStatus(false);
      }
      if (
        siteInfo.armed_status === "DISARMING" ||
        siteInfo.armed_status === "NOT_RUNNING"
      ) {
        setArmedStatus(false);
      }
      if (
        siteInfo.armed_status === "WARNING" ||
        siteInfo.armed_status === "ERROR" ||
        siteInfo.armed_status === "ARMED_OUTSIDE_SCHEDULE" ||
        siteInfo.armed_status === "ARMING"
      ) {
        setArmedStatus(true);
      }

      setActiveTab(<></>);
      setActiveTab(
        <GeneralInfoTab
          siteInfo={siteInfo}
          setSiteInfo={setSiteInfo}
          setActiveTab={setActiveTab}
        />
      );
      localStorage.setItem("integrationType", siteInfo.onboarding_integration);
      if (siteInfo.auto_add) {
        localStorage.setItem("autoAdd", "true");
        autoAdd = true;
      } else {
        localStorage.setItem("autoAdd", "false");
        autoAdd = false;
      }
      if (siteInfo.integration_type_name === "ailink") {
        setAiLinkSite(true);
      }
      if (
        !siteInfo.settings_deployed &&
        siteInfo.integration_type_name !== "ailink"
      ) {
        setSiteDeployed(false);
        setSyncNeeded(true);
      }
      if (siteInfo.settings_deployed) {
        siteInfoSettingsDeployed = true;
      }
      if (!siteInfo.settings_deployed) {
        siteInfoSettingsDeployed = false;
      }
      if (siteInfo.armed_status === "DISARMING") {
        setToolTipTitle(
          "Disarm command received. Site is in the process of disarming.  It may take up to 5 minutes for arm status to update."
        );
      }
      if (siteInfo.armed_status === "ARMING") {
        setToolTipTitle(
          "Arm command received. Site is in the process of arming. It may take up to 5 minutes for arm status to update."
        );
      }
      if (siteInfo.show_configure_motion) {
        setConfigureMotion(true);
      } else {
        setConfigureMotion(false);
      }
    },
    (reason: AxiosError) => {
      getSiteInfoFail = true;
      if (reason.request.response.includes(`Not found`)) {
        navigate("/*");
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          localStorage.setItem("autoAdd", "true");
          autoAdd = true;
        });
      }
    }
  );
  !getSiteInfoFail &&
    (await AxiosUI.get(`nvr_camera/?customer__id=${siteId}`).then(
      (response: AxiosResponse) => {
        if (response.data.count === 0) {
          localStorage.setItem("noAutoAddCamerasDialogOpen", "true");
          noAutoAddCamerasDialogOpen = true;
        }
        if (response.data.count > 0) {
          localStorage.setItem("noAutoAddCamerasDialogOpen", "false");
          noAutoAddCamerasDialogOpen = false;
        }
        getNVRCamerasFail = false;
      },
      (reason: AxiosError) => {
        getNVRCamerasFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !getSiteInfoFail &&
    !getNVRCamerasFail &&
    (await AxiosUI.get(`nvr_camera/extra_fields/?customer__id=${siteId}`).then(
      (response: AxiosResponse) => {
        getExtraFieldsFail = false;
        localStorage.setItem(
          "additionalFieldNamesArray",
          JSON.stringify(response.data)
        );
      },
      (reason: AxiosError) => {
        getExtraFieldsFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !getSiteInfoFail &&
    !getNVRCamerasFail &&
    !getExtraFieldsFail &&
    getSiteCameras(1);

  !getSiteInfoFail &&
    !getNVRCamerasFail &&
    !getExtraFieldsFail &&
    !getSiteCamerasFail &&
    (await AxiosUI.get(`customer/${siteId}/instructions/`).then(
      (response: AxiosResponse) => {
        if (response.data.show_instructions === true) {
          getSMTPInstructionsFail = false;
          localStorage.setItem("SMTPSite", "true");
          localStorage.setItem(
            "SMTPInstructions",
            JSON.stringify(response.data)
          );
        } else {
          localStorage.setItem("SMTPSite", "false");
        }
      },
      (reason: AxiosError) => {
        getSMTPInstructionsFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !getSiteInfoFail &&
    !getNVRCamerasFail &&
    !getExtraFieldsFail &&
    !getSMTPInstructionsFail &&
    !getSiteCamerasFail &&
    (await AxiosUI.get(`schedule/by_customer/${siteId}/`).then(
      (response: AxiosResponse) => {
        getSchedulesFail = false;
        localStorage.setItem("siteSchedule", JSON.stringify(response.data));
        setSiteSchedules(response.data);
        localStorage.setItem(
          "allSiteSchedulesBackup",
          JSON.stringify(response.data)
        );
        response.data.forEach((object: SiteSchedule) => {
          setAllSiteSchedules((previousState: any) => [
            ...previousState,
            object,
          ]);
        });
        if (siteCameraCount === 0 && !response.data[0]) {
          setAlertState((previousState: AlertState) => {
            return { ...previousState, noCameraOrSchedule: true };
          });
        }
        if (siteCameraCount > 0 && !response.data[0]) {
          setAlertState((previousState: AlertState) => {
            return { ...previousState, noSchedule: true };
          });
        }
      },
      (reason: AxiosError) => {
        getSchedulesFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !getSiteInfoFail &&
    !getNVRCamerasFail &&
    !getExtraFieldsFail &&
    !getSMTPInstructionsFail &&
    !getSiteCamerasFail &&
    !getSchedulesFail &&
    (await AxiosUI.get(`healthcheck/by_customer/${siteId}/`).then(
      (response: AxiosResponse) => {
        getHealthcheckDataFail = false;
        if (noAutoAddCamerasDialogOpen === true && autoAdd === true) {
          setNoAutoAddCamerasDialogOpen(true);
        } else {
          setNoAutoAddCamerasDialogOpen(false);
        }
        setHealthCheckData(response.data);
      },
      (reason: AxiosError) => {
        getHealthcheckDataFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !getSiteInfoFail &&
    !getNVRCamerasFail &&
    !getExtraFieldsFail &&
    !getSMTPInstructionsFail &&
    !getSiteCamerasFail &&
    !getSchedulesFail &&
    !getHealthcheckDataFail &&
    (await AxiosUI.get(`webhook_customer/?customer=${siteId}`).then(
      (response: AxiosResponse) => {
        if (response.data[0]) {
          setWebhooksInfo(response.data);
          setWebhooksPatchObject((previousState: any) => {
            return {
              ...previousState,
              message_body: response.data[0].message_body,
            };
          });
        }
        setDataLoaded(true);
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
