export const handleImmixDrawIgnoreZonesInput = (
  camerasObject: any,
  setCamerasObject: any,
  checked: boolean
) => {
  const newCamerasObject = camerasObject;

  for (const key in newCamerasObject) {
    newCamerasObject[key].immix.draw_ignore_zones = checked;
  }

  setCamerasObject(newCamerasObject);
};
