import { createApi } from "../../../../utils/createApi";
import { AxiosResponse } from "axios";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { VmsTab } from "../VmsTab";
import { SchedulesTab } from "../SchedulesTab";
import { getRefreshToken } from "../../../../utils/getRefreshToken";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleSubmitEmptySchedule = async (
  setScheduleChangesMade: any,
  setSiteSchedules: any,
  setAllSiteSchedules: any,
  setEditMode: any,
  setBackdropLoadingScreenOn: any,
  setAlertState: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  vmsVariables: any,
  navigate: any,
  setSiteInfo: any,
  setDeleteScheduleDialogOpen: any,
  allSiteSchedules: any
) => {
  const emptySchedule = [
    {
      customer: getNumbersAfterAboutSite(location.href),
      start_time: null,
      end_time: "00:00:00",
      always_on: false,
      day_of_week: ["5", "6", "2", "4", "3", "1", "0"],
      schedule_status: true,
      is_override: false,
      location_dusk_dawn: null,
      enabled: true,
      override_start_date: null,
      override_end_date: null,
      buffer_time: null,
    },
  ];
  const AxiosUI = createApi(``);
  const allSiteSchedulesBackup = JSON.parse(
    localStorage.allSiteSchedulesBackup
  );

  const returnFunction = () => {
    handleSubmitEmptySchedule(
      setScheduleChangesMade,
      setSiteSchedules,
      setAllSiteSchedules,
      setEditMode,
      setBackdropLoadingScreenOn,
      setAlertState,
      clickedTab,
      setActiveTab,
      siteInfo,
      vmsVariables,
      navigate,
      setSiteInfo,
      setDeleteScheduleDialogOpen,
      allSiteSchedules
    );
  };

  await AxiosUI.post(`schedule/`, emptySchedule).then(
    () => {
      localStorage.setItem("scheduleChangeError", "false");
      localStorage.setItem(
        "allSiteSchedulesBackup",
        JSON.stringify(allSiteSchedulesBackup)
      );
      AxiosUI.get(
        `schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`
      ).then(
        (response: AxiosResponse) => {
          setScheduleChangesMade(false);
          localStorage.setItem("siteSchedules", JSON.stringify(response.data));
          localStorage.setItem("siteSchedule", JSON.stringify(response.data));
          setSiteSchedules(response.data);
          setAllSiteSchedules(response.data);
          localStorage.setItem(
            "allSiteSchedulesBackup",
            JSON.stringify(response.data)
          );
          setEditMode(false);
          setBackdropLoadingScreenOn(false);
          setAlertState((previousState: any) => {
            return {
              ...previousState,
              changeSuccess: false,
              activateSuccess: false,
              deactivateSuccess: false,
              disarmSuccess: false,
              armSuccess: false,
              syncSuccess: false,
              cameraDeleteSuccess: false,
              armFail: false,
            };
          });
          if (clickedTab === "one") {
            setActiveTab(
              <GeneralInfoTab
                siteInfo={siteInfo}
                setSiteInfo={setSiteInfo}
                setActiveTab={setActiveTab}
              />
            );
          } else if (clickedTab === "two") {
            setActiveTab(
              <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
            );
          } else if (clickedTab === "three") {
            if (localStorage.scheduleSubmitError === "true") {
              localStorage.removeItem("scheduleSubmitError");
              setActiveTab(
                <SchedulesTab
                  allSiteSchedules={response.data}
                  siteInfo={siteInfo}
                  setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                />
              );
            } else {
              setActiveTab(
                <SchedulesTab
                  allSiteSchedules={response.data}
                  siteInfo={siteInfo}
                  setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                />
              );
            }
          }
        },
        (reason: any) => {
          if (
            reason.request.response.slice(11, 53) ===
              "SocialToken matching query does not exist." ||
            reason.request.response.includes(
              "Returned invalid token from cache"
            ) ||
            reason.request.response.slice(11, 53) === "Token is expired"
          ) {
            getRefreshToken(navigate, returnFunction);
          } else {
            setSiteInfo(JSON.parse(localStorage.siteInfo));
            setEditMode(false);
            if (clickedTab === "one") {
              setActiveTab(
                <GeneralInfoTab
                  siteInfo={siteInfo}
                  setSiteInfo={setSiteInfo}
                  setActiveTab={setActiveTab}
                />
              );
            } else if (clickedTab === "two") {
              setActiveTab(
                <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
              );
            } else if (clickedTab === "three") {
              setActiveTab(
                <SchedulesTab
                  allSiteSchedules={allSiteSchedules}
                  siteInfo={siteInfo}
                  setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                />
              );
            }
          }
        }
      );
    },
    (reason: any) => {
      localStorage.setItem("scheduleChangeError", "true");
      localStorage.setItem("scheduleSubmitError", "true");
      setAllSiteSchedules(allSiteSchedulesBackup);
      localStorage.setItem(
        "allSiteSchedules",
        JSON.stringify(allSiteSchedulesBackup)
      );
      if (
        reason.request.response.includes(
          "overlaps with existing enabled schedule"
        )
      ) {
        alert(
          `Schedule Changes not accepted: One or more days on changed schedule overlaps with day(s) from another scheulde at this Site.`
        );
        setEditMode(false);
        setBackdropLoadingScreenOn(false);
        setAllSiteSchedules(allSiteSchedulesBackup);
        if (clickedTab === "one") {
          setActiveTab(
            <GeneralInfoTab
              siteInfo={siteInfo}
              setSiteInfo={setSiteInfo}
              setActiveTab={setActiveTab}
            />
          );
        } else if (clickedTab === "two") {
          setActiveTab(
            <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
          );
        } else if (clickedTab === "three") {
          setActiveTab(
            <SchedulesTab
              allSiteSchedules={allSiteSchedulesBackup}
              siteInfo={siteInfo}
              setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
            />
          );
        }
      } else if (
        reason.request.response.slice(11, 53) ===
          "SocialToken matching query does not exist." ||
        reason.request.response.includes("Returned invalid token from cache")
      ) {
        getRefreshToken(navigate, returnFunction);
        setEditMode(false);
        if (clickedTab === "one") {
          setActiveTab(
            <GeneralInfoTab
              siteInfo={siteInfo}
              setSiteInfo={setSiteInfo}
              setActiveTab={setActiveTab}
            />
          );
        } else if (clickedTab === "two") {
          setActiveTab(
            <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
          );
        } else if (clickedTab === "three") {
          setActiveTab(
            <SchedulesTab
              allSiteSchedules={allSiteSchedules}
              siteInfo={siteInfo}
              setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
            />
          );
        }
      } else {
        alert(`Schedule Changes not accepted, please try again`);
        setEditMode(false);
        setBackdropLoadingScreenOn(false);
        setAllSiteSchedules(allSiteSchedulesBackup);
        if (clickedTab === "one") {
          setActiveTab(
            <GeneralInfoTab
              siteInfo={siteInfo}
              setSiteInfo={setSiteInfo}
              setActiveTab={setActiveTab}
            />
          );
        } else if (clickedTab === "two") {
          setActiveTab(
            <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
          );
        } else if (clickedTab === "three") {
          setActiveTab(
            <SchedulesTab
              allSiteSchedules={allSiteSchedulesBackup}
              siteInfo={siteInfo}
              setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
            />
          );
        }
      }
    }
  );
};
