import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Field } from "formik";
import { FormHelperText } from "@mui/material";

export const CheckBoxFieldType = ({
  config,
  formik,
}: {
  config: any;
  formik: any;
}) => {
  let intialValue;

  //check if formik.values[config.field] is an empty string if so set intialValue to false
  if (formik.values[config.field] === "") {
    intialValue = false;
  } else {
    intialValue = formik.values[config.field];
  }

  React.useEffect(() => {
    formik.values[config.field] = intialValue;
  }, []);

  return (
    <div key={config.id}>
      <FormControlLabel
        sx={{
          fontFamily: "Mulish",
          color: "#001943",
        }}
        label={config.label}
        control={
          <Field
            color="primary"
            defaultChecked={intialValue}
            disabled={config.disabled}
            onChange={(item: any) => {
              formik.values[config.field] = item.target.checked;
            }}
            as={CheckBoxComponent}
          />
        }
      />
      <FormHelperText sx={{ width: 350 }}>{config.helperText}</FormHelperText>
    </div>
  );
};

const CheckBoxComponent = (props: any) => <Checkbox {...props} />;
