import React from "react";
import { GeneralInfoTabEditMode } from "../GeneralInfoTabEditMode";
import { VmsTabEditMode } from "../VmsTabEditMode";
import { SchedulesTabEditMode } from "../SchedulesTabEditMode";
import { RecordingServerTabEditMode } from "../RecordingServerTabEditMode";
import { Dispatcher } from "./siteAboutInterfaces";
import { CameraHealthTabEditMode } from "../CameraHealthTabEditMode";
import { WebhooksTab } from "../WebhooksTab";
import WebhooksTabEditMode from "../WebhooksTabEditMode";

export const handleEditInformationButtonClick = (
  clickedTab: any,
  setEditMode: any,
  setActiveTab: any,
  setPatchObject: any,
  setSiteInfo: any,
  vmsVariables: any,
  setVmsVariables: any,
  allSiteSchedules: any,
  siteInfo: any,
  setAllSiteSchedules: any,
  setBackdropLoadingScreenOn: any,
  editMode: any,
  setSchedulesTabActive: any,
  setClickedTab: any,
  setAlertState: any,
  setSyncNeeded: any,
  setSiteInfoChangesMade: any,
  setScheduleChangesMade: any,
  setDeleteScheduleDialogOpen: any,
  recordingServerData: any,
  recordingServerPatchObject: any,
  setRecordingServerPatchObject: any,
  setRecordingServerChangesMade: Dispatcher<boolean>,
  healthCheckData: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: Dispatcher<boolean>,
  setAddCameraHealthDialogOpen: Dispatcher<boolean>,
  setTurnOffHealthDialogOpen: Dispatcher<boolean>,
  webhooksInfo: any,
  setWebhooksPatchObject: any,
  setWebhooksChangesMade: any,
  setSiteInfoBackup: any,
  setVmsVariablesBackup: any
) => {
  setSiteInfoBackup(siteInfo);

  setVmsVariablesBackup(vmsVariables);

  if (clickedTab === "one") {
    setEditMode(true);
    setActiveTab(
      <GeneralInfoTabEditMode
        setPatchObject={setPatchObject}
        setSiteInfo={setSiteInfo}
        siteInfo={siteInfo}
        setSiteInfoChangesMade={setSiteInfoChangesMade}
      />
    );
  } else if (clickedTab === "two") {
    setEditMode(true);
    setActiveTab(
      <VmsTabEditMode
        vmsVariables={vmsVariables}
        setVmsVariables={setVmsVariables}
        setPatchObject={setPatchObject}
        setSiteInfo={setSiteInfo}
        siteInfo={siteInfo}
        setSiteInfoChangesMade={setSiteInfoChangesMade}
      />
    );
  } else if (clickedTab === "three") {
    setEditMode(true);
    setActiveTab(
      <SchedulesTabEditMode
        allSiteSchedules={allSiteSchedules}
        siteInfo={siteInfo}
        setAllSiteSchedules={setAllSiteSchedules}
        setScheduleChangesMade={setScheduleChangesMade}
        setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
        setActiveTab={setActiveTab}
        setEditMode={setEditMode}
        setSiteSchedules={setAllSiteSchedules}
        setSiteInfo={setSiteInfo}
      />
    );
  } else if (clickedTab === "five") {
    setEditMode(true);
    setActiveTab(
      <RecordingServerTabEditMode
        recordingServerData={recordingServerData}
        recordingServerPatchObject={recordingServerPatchObject}
        setRecordingServerPatchObject={setRecordingServerPatchObject}
        setRecordingServerChangesMade={setRecordingServerChangesMade}
      />
    );
  } else if (clickedTab === "six") {
    setEditMode(true);
    setActiveTab(
      <CameraHealthTabEditMode
        healthCheckData={healthCheckData}
        setCameraHealthPatchObject={setCameraHealthPatchObject}
        setCameraHealthChangesMade={setCameraHealthChangesMade}
        siteInfo={siteInfo}
        setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
        setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
      />
    );
  } else if (clickedTab === "seven") {
    setEditMode(true);
    setActiveTab(
      <WebhooksTabEditMode
        webhooksInfo={webhooksInfo}
        setWebhooksPatchObject={setWebhooksPatchObject}
        setWebhooksChangesMade={setWebhooksChangesMade}
      />
    );
  }
  setSchedulesTabActive(false);
};
