export const addIgnoreLabel = (inputArray: any[]): typeof inputArray => {
  return inputArray.map((item) => ({
    ...item,
    label_display_name: item.label_display_name.startsWith("Ignore")
      ? item.label_display_name === "Ignore Motion"
        ? "Ignore All"
        : item.label_display_name
      : `Ignore ${item.label_display_name}`,
  }));
};
