import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { TableHeadComponent } from "../../common/TableHead";
import { Autocomplete, TextField } from "@mui/material";
import { createCameraAlarmType } from "./EditCameraUtils/createCameraAlarmType";
import { getNumbersAfterEditCamera } from "./EditCamera";

export const GeneralInfoEditCameraTabEditMode = ({
  siteInfo,
  cameraGeneralInfo,
  setCameraGeneralInfo,
  setPatchObject,
  setGeneralInfoChangesMade,
  setActiveChangeMade,
}: {
  siteInfo: any;
  cameraGeneralInfo: any;
  setCameraGeneralInfo: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
  setActiveChangeMade: any;
}) => {
  const cameraViewOptions = JSON.parse(localStorage.cameraViewOptions);

  const cameraViewOptionIds = new Map();
  const cameraViewOptionNames = new Map();
  cameraViewOptions.forEach((option: any) => {
    cameraViewOptionIds.set(option.name, option.value);
  });
  cameraViewOptions.forEach((option: any) => {
    cameraViewOptionNames.set(option.value, option.name);
  });

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent
          titlesArray={[
            "Customer",
            "Camera Name",
            "Camera View",
            "Alarm Type",
            "Active",
          ]}
        />
        <TableBody>
          <TableRow key={getNumbersAfterEditCamera(location.href)}>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.parent_group.name}
            </TableCell>
            {/* <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {cameraGeneralInfo.camera_name}
            </TableCell> */}
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              <div key={siteInfo.name}>
                <TextField
                  defaultValue={cameraGeneralInfo.camera_name}
                  variant="standard"
                  sx={{ width: 200 }}
                  type="string"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCameraGeneralInfo((previousState: any) => {
                      return {
                        ...previousState,
                        camera_name: event.target.value,
                      };
                    });
                    setPatchObject((previousState: any) => {
                      return {
                        ...previousState,
                        camera_name: event.target.value,
                      };
                    });
                    setGeneralInfoChangesMade(true);
                  }}
                />
              </div>
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              <Autocomplete
                disablePortal
                options={cameraViewOptions.map((option: any) => {
                  return option.name;
                })}
                defaultValue={cameraViewOptionNames.get(
                  cameraGeneralInfo.camera_view
                )}
                onChange={(event: any, value: any) => {
                  setCameraGeneralInfo((previousState: any) => {
                    return {
                      ...previousState,
                      camera_view: cameraViewOptionIds.get(value),
                    };
                  });
                  setPatchObject((previousState: any) => {
                    return {
                      ...previousState,
                      camera_view: cameraViewOptionIds.get(value),
                    };
                  });
                  setGeneralInfoChangesMade(true);
                }}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" sx={{}} />
                )}
              ></Autocomplete>
            </TableCell>

            {/* <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {cameraGeneralInfo.camera_type
                ? cameraGeneralInfo.camera_type.display_name
                : `N/A`}
            </TableCell> */}
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {createCameraAlarmType(cameraGeneralInfo.default_alarm)}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {cameraGeneralInfo.active ? (
                <Switch
                  defaultChecked
                  color="warning"
                  disabled={false}
                  onChange={(event: any) => {
                    setCameraGeneralInfo((previousState: any) => {
                      return {
                        ...previousState,
                        active: event.target.checked,
                      };
                    });
                    setActiveChangeMade(true);
                  }}
                />
              ) : (
                <Switch
                  color="warning"
                  disabled={false}
                  onChange={(event: any) => {
                    setCameraGeneralInfo((previousState: any) => {
                      return {
                        ...previousState,
                        active: event.target.checked,
                      };
                    });
                    setActiveChangeMade(true);
                  }}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
