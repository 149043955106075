import React from "react";
import { useCookies } from "react-cookie";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { createApi } from "../../utils/createApi";
import { createSubmittedObject } from "./createSubmittedObject";
import axios, { AxiosResponse, AxiosError } from "axios";
import { getApiUrl } from "../../utils/getApiUrl";
import { getRefreshToken } from "../../utils/getRefreshToken";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../Onboarding/OnboardingProgressDiv";
import { fetchAddCameraInfo } from "../Sites/SitesPage/SitesPageUtils/fetchAddCameraInfo";
import { WizardContainerScreen } from "./WizardContainerScreen";
import { syncSite } from "../../utils/syncSite";
import { getUpdatedSitesPageInfo } from "./getUpdatedSitesPageInfo";
import { ImmixSetupScreen } from "./ImmixSetupScreen";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import { AddWebhookScreen } from "./AddWebhookScreen";
import { handleAddImmixToAddCameraForm } from "./handleAddImmixToAddCameraForm";
import { SentinelSetupScreen } from "./SentinelSetupScreen";
import { useFormikContext } from "formik";

export const handleSubmitSite = async (
  values: any,
  setVisibleScreen: any,
  responseObject: any,
  navigate: any,
  response: any,
  formik: any
) => {
  setVisibleScreen(
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          We&apos;re creating the new Site.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleSubmitSite(
      values,
      setVisibleScreen,
      responseObject,
      navigate,
      response,
      formik
    );
  };

  const handleRedirect = async () => {
    const AcuateUI = createApi(`eagle_eye/`);

    await AcuateUI.get(`url`)
      .then((response: AxiosResponse) => {
        window.location.href = response.data.login_url;
      })
      .catch((err: AxiosError) => alert(`${err}`));
  };

  localStorage.removeItem("addSiteError");
  await AxiosUI.post(
    "customer/onboarding/",
    createSubmittedObject(values)
  ).then(
    (response: AxiosResponse) => {
      syncSite(response.data.id, navigate);
      localStorage.setItem("siteId", response.data.id);
      localStorage.setItem(`newSite${response.data.id}`, "true");
      localStorage.removeItem("addSiteError");
      AxiosUI.get(`customer/${response.data.id}/camera_onboarding_forms/`).then(
        (response: AxiosResponse) => {
          // handleAddImmixToAddCameraForm(response.data);
          localStorage.setItem("addCameraInfo", JSON.stringify(response.data));
          localStorage.setItem(
            "selectedProducts",
            JSON.stringify(response.data.initial_data.products)
          );
          getUpdatedSitesPageInfo();
          setVisibleScreen(
            <StatusNotificationScreen
              styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
              text="Site created successfully!"
            />
          );
          setTimeout(() => {
            if (localStorage.getItem("integrationType") === "35") {
              setVisibleScreen(
                <StatusNotificationScreen
                  styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
                  text="Redirecting to Eagle Eye..."
                />
              );
              setTimeout(() => {
                handleRedirect();
              }, 2000);
            } else if (localStorage.immixAlertsSite === "true") {
              setVisibleScreen(
                <ImmixSetupScreen setVisibleScreen={setVisibleScreen} />
              );
            } else if (localStorage.sentinelAlertsSite === "true") {
              setVisibleScreen(
                <SentinelSetupScreen setVisibleScreen={setVisibleScreen} />
              );
            } else if (JSON.parse(localStorage.showWebHooksOnboardingScreen)) {
              setVisibleScreen(
                <AddWebhookScreen
                  setVisibleScreen={setVisibleScreen}
                  siteId={response.data.id}
                />
              );
              localStorage.setItem(
                "showWebHooksOnboardingScreen",
                JSON.stringify(false)
              );
            } else {
              setVisibleScreen(
                <OnboardingProgressDiv
                  percentage={
                    validIntegrationTypes.includes(
                      localStorage.selectedIntegrationType
                    )
                      ? 25
                      : 33
                  }
                  step={1}
                  setVisibleScreen={setVisibleScreen}
                />
              );
            }
          }, 2000);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert(`${reason}`);
          });
        }
      );
    },
    (reason: AxiosError) => {
      const backupResponse = JSON.parse(localStorage.responseObjectBackup);
      if (reason.request.response.includes("Please specify a valid Group")) {
        localStorage.setItem(
          "responseObjectBackup",
          JSON.stringify(responseObject)
        );
        setVisibleScreen(
          <WizardContainerScreen
            handleSubmitSite={handleSubmitSite}
            responseObject={backupResponse}
            setVisibleScreen={setVisibleScreen}
          />
        );
        // eslint-disable-next-line no-console
        console.log(reason.request.response);
        alert("Please select a valid group and retry.");
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          localStorage.setItem("addSiteError", "true");
          setVisibleScreen(
            <WizardContainerScreen
              handleSubmitSite={handleSubmitSite}
              responseObject={backupResponse}
              setVisibleScreen={setVisibleScreen}
            />
          );
        });
      }
    }
  );
};
