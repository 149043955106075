import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { ThemeProvider } from "@mui/material/styles";
import { Stepper, Step, StepLabel, Alert } from "@mui/material";
import { theme } from "../AddSite/StepsStepper";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { AddGroupUserStepOne } from "./AddGroupUserStepOne";
import { AddGroupUserStepTwo } from "./AddGroupUserStepTwo";

export const AddGroupUserScreen = ({
  setAddGroupUserScreenOpen,
  setUserNameString,
  setAlertState,
  groupUsersSearchString,
  activeData,
  setActiveData,
}: {
  setAddGroupUserScreenOpen: Dispatcher<boolean>;
  setUserNameString: any;
  setAlertState: any;
  groupUsersSearchString: string;
  activeData: any;
  setActiveData: any;
}) => {
  const parentAccounts = localStorage.parentAccounts
    ? JSON.parse(localStorage.parentAccounts)
    : [];

  const parentIds = new Map();
  const parentNames = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  parentAccounts.forEach((object: any) => {
    parentNames.set(object.id, object.name);
  });

  const [newUserInfo, setNewUserInfo] = React.useState<any>({
    group: "",
    email: "",
    first_name: "",
    last_name: "",
    send_cognito_invitation: true,
    access_level: 1,
    create_user: false,
    clips: false,
  });

  const navigate = useNavigate();

  const steps = ["User Information", "Permissions"];

  const [activeStep, setActiveStep] = React.useState(0);

  const [visibleScreen, setVisibleScreen] = React.useState(
    <AddGroupUserStepOne
      newUserInfo={newUserInfo}
      setNewUserInfo={setNewUserInfo}
      parentAccounts={parentAccounts}
      parentIds={parentIds}
      parentNames={parentNames}
      navigate={navigate}
    />
  );

  const [submitErrorState, setSubmitErrorState] = React.useState({
    noAILink: false,
  });

  const [errorStateMessage, setErrorStateMessage] = React.useState("");

  return (
    <div>
      <div className="absolute top-0 right-[600px] h-full bg-white">
        <div className="absolute h-full bg-white w-[600px]">
          <div className="bg-actuate-blue absolute w-[600px] h-[50px]">
            <button
              className="absolute right-6 top-[10px] text-white text-[18px]"
              onClick={() => {
                setAddGroupUserScreenOpen(false);
              }}
            >
              X
            </button>
            <div
              className={
                "text-white absolute top-[1px] right-[430px] font-mulish text-[18px] p-3"
              }
            >
              Add Group User
            </div>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <div className="absolute top-[70px] left-[20px]">
                <Stepper activeStep={activeStep} sx={{ width: 565 }}>
                  {steps.map((label) => (
                    <Step
                      key={label}
                      color="primary"
                      sx={{ fontFamily: "Mulish" }}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </ThemeProvider>
            {activeStep === 0 && (
              <div className="absolute right-4 top-28">
                <OrangeTextButton
                  disabled={
                    !newUserInfo.group ||
                    !newUserInfo.email ||
                    !newUserInfo.first_name ||
                    !newUserInfo.last_name
                  }
                  onClick={() => {
                    if (activeStep === 0) {
                      setActiveStep(1);
                      setVisibleScreen(
                        <AddGroupUserStepTwo
                          newUserInfo={newUserInfo}
                          setNewUserInfo={setNewUserInfo}
                          setVisibleScreen={setVisibleScreen}
                          setAddGroupUserScreenOpen={setAddGroupUserScreenOpen}
                          setUserNameString={setUserNameString}
                          setAlertState={setAlertState}
                          groupUsersSearchString={groupUsersSearchString}
                          activeData={activeData}
                          setActiveData={setActiveData}
                          setErrorStateMessage={setErrorStateMessage}
                          setSubmitErrorState={setSubmitErrorState}
                          setActiveStep={setActiveStep}
                          navigate={navigate}
                        />
                      );
                    }
                  }}
                  text="Next"
                />
              </div>
            )}
          </div>
          {activeStep === 1 && (
            <div className="absolute right-[520px] top-28">
              <OrangeTextButton
                disabled={false}
                onClick={() => {
                  if (activeStep === 1) {
                    setActiveStep(0);
                    setVisibleScreen(
                      <AddGroupUserStepOne
                        newUserInfo={newUserInfo}
                        setNewUserInfo={setNewUserInfo}
                        parentAccounts={parentAccounts}
                        parentIds={parentIds}
                        parentNames={parentNames}
                        navigate={navigate}
                      />
                    );
                  }
                }}
                text="Back"
              />
            </div>
          )}
        </div>
        <div>{visibleScreen}</div>
      </div>
    </div>
  );
};
