import React from "react";
import { StatusNotificationScreen } from "../../../common/StatusNotificationScreen";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../../Onboarding/OnboardingProgressDiv";
import { handleCreateAutoAddObject } from "./handleCreateAutoAddObject";
import { CameraAutoAddScreen } from "../CameraAutoAddScreen";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { ProgressFinishedScreen } from "../../AddSchedule/ProgressFinishedScreen";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { handleCheckCameras } from "../../CameraCheck/handleCheckCameras";

export const handleSubmitAutoAddCameras = async (
  camerasObject: any,
  setVisibleScreen: any,
  setLargerScreen: Dispatcher<boolean>,
  largerScreen: boolean,
  setCameraCheckFinsihedAlertOpen: any,
  setGoodCameras: any,
  setConnectivityCameras: any,
  setLowResolutionCameras: any,
  setCameraCheckResultDownloadURL: any,
  sentinelSite: boolean,
  streamChangesMade: boolean,
  streamPatchObject: any,
  navigate: any
) => {
  const retryFunction = () => {
    handleSubmitAutoAddCameras(
      camerasObject,
      setVisibleScreen,
      setLargerScreen,
      largerScreen,
      setCameraCheckFinsihedAlertOpen,
      setGoodCameras,
      setConnectivityCameras,
      setLowResolutionCameras,
      setCameraCheckResultDownloadURL,
      sentinelSite,
      streamChangesMade,
      streamPatchObject,
      navigate
    );
  };
  setLargerScreen(false);
  setVisibleScreen(
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          Camera Add in process.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );
  const AxiosUI = createApi(``);
  const ActuateUI = createApi(``);
  let nvrCameraFetchFail = false;
  let siteCamerasFetchFail = false;

  const sentinelCamsWithErrors: string[] = [];

  const sentinelAlertObject =
    Object.keys(camerasObject).length > 0
      ? camerasObject[Object.keys(camerasObject)[0]].sentinel
      : {
          sentinel_server: "",
          sentinel_site_id: "",
          sentinel_device_address: "",
          draw_ignore_zones: false,
        };

  const createNewStreamsObject = (streams: any) => {
    const updatedStreams = streams;
    updatedStreams.forEach((object: any, index: any) => {
      const updatedSentinelAlerts = object.sentinel_alerts;
      updatedSentinelAlerts.push(sentinelAlertObject);
      updatedStreams[index].sentinel_alerts = updatedSentinelAlerts;
    });
    return updatedStreams;
  };

  const streamPatchErrorsCameras: string[] = [];

  const postSentinelAlert = (
    cameraId: number,
    streamsObject: any,
    cameraName: any,
    lastCamera: boolean
  ) => {
    AxiosUI.patch(`/camera/${cameraId}/general_info/`, {
      streams: createNewStreamsObject(streamsObject),
    }).then(
      () => {
        if (lastCamera) {
          if (sentinelCamsWithErrors.length > 0) {
            alert(
              `The following cameras had errors in adding the Sentinel alert: ${sentinelCamsWithErrors.join(
                ", "
              )}`
            );
          }
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          sentinelCamsWithErrors.push(cameraName);
          if (lastCamera) {
            if (sentinelCamsWithErrors.length > 0) {
              alert(
                `The following cameras had errors in adding the Sentinel alert: ${sentinelCamsWithErrors.join(
                  ", "
                )}`
              );
            }
          }
        });
      }
    );
  };

  await AxiosUI.post(
    "/nvr_camera/auto_add/",
    handleCreateAutoAddObject(camerasObject)
  ).then(
    (response: AxiosResponse) => {
      nvrCameraFetchFail = false;
      localStorage.setItem("cameraId", response.data.id);
    },
    (reason: AxiosError) => {
      nvrCameraFetchFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        localStorage.setItem("autoAddError", "true");
        // setLargerScreen(true);
        setVisibleScreen(
          <CameraAutoAddScreen
            setVisibleScreen={setVisibleScreen}
            setLargerScreen={setLargerScreen}
            largerScreen={largerScreen}
            setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
            setGoodCameras={setGoodCameras}
            setConnectivityCameras={setConnectivityCameras}
            setLowResolutionCameras={setLowResolutionCameras}
            setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
          />
        );
      });
    }
  );
  !nvrCameraFetchFail &&
    (await ActuateUI.get(
      `camera/site/?customer__id=${localStorage.siteId}`
    ).then(
      (response: AxiosResponse) => {
        siteCamerasFetchFail = false;
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
        const cameraResults = response.data.results;
        if (cameraResults.length > 0 && sentinelSite) {
          cameraResults.forEach((camera: any, index: number) => {
            AxiosUI.get(`camera/${camera.id}/general_info/`).then(
              (responseTwo: AxiosResponse) => {
                const streams = responseTwo.data.streams;
                postSentinelAlert(
                  camera.id,
                  streams,
                  camera.camera_name,
                  cameraResults.length === index + 1
                );
              },
              (reason: AxiosError) => {
                universalAPIErrorHandler(
                  reason,
                  navigate,
                  retryFunction,
                  () => {}
                );
              }
            );
          });
        }
        if (cameraResults.length > 0 && streamChangesMade) {
          cameraResults.forEach((camera: any, index: number) => {
            AxiosUI.patch(
              `camera/${camera.id}/extra_fields/`,
              streamPatchObject
            ).then(
              () => {
                if (cameraResults.length === index + 1) {
                  if (streamPatchErrorsCameras.length > 0) {
                    alert(
                      `The following cameras had errors in adding the stream parameters: ${streamPatchErrorsCameras.join(
                        ", "
                      )}`
                    );
                  }
                }
              },
              (reason: AxiosError) => {
                universalAPIErrorHandler(
                  reason,
                  navigate,
                  retryFunction,
                  () => {
                    streamPatchErrorsCameras.push(camera.camera_name);
                    if (cameraResults.length === index + 1) {
                      if (streamPatchErrorsCameras.length > 0) {
                        alert(
                          `The following cameras had errors in adding the stream parameters: ${streamPatchErrorsCameras.join(
                            ", "
                          )}`
                        );
                      }
                    }
                  }
                );
              }
            );
          });
        }
      },
      (reason: AxiosError) => {
        siteCamerasFetchFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !nvrCameraFetchFail &&
    !siteCamerasFetchFail &&
    (await AxiosUI.post(
      `customer/${localStorage.siteId}/action/deploy_next/`
    ).then(
      () => {
        localStorage.setItem("syncNeeded", "false");
        setVisibleScreen(
          <StatusNotificationScreen
            styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
            text="Camera Add successful!"
          />
        );
        setTimeout(() => {
          if (
            localStorage.addAdditionalCamera === "true" ||
            localStorage.clickedFromSiteAboutPage === "true"
          ) {
            if (
              validIntegrationTypes.includes(
                localStorage.selectedIntegrationType
              )
            ) {
              localStorage.setItem("cameraCheckAlertOpen", "true");
              localStorage.setItem("clickedId", localStorage.siteId);
              localStorage.setItem("navigatedFromSitesAboutPage", "true");
              localStorage.setItem("level0Click", "true");
              localStorage.setItem("cameraChecksiteId", localStorage.siteId);
              handleCheckCameras(
                setCameraCheckFinsihedAlertOpen,
                setGoodCameras,
                setConnectivityCameras,
                setLowResolutionCameras,
                localStorage.siteId,
                setCameraCheckResultDownloadURL,
                navigate
              );
            }
            navigate(`/sites/aboutsite/${localStorage.siteId}`);
            localStorage.removeItem("addAdditionalCamera");
          } else if (localStorage.healthcheckOnly === "true") {
            setVisibleScreen(<ProgressFinishedScreen />);
            localStorage.setItem("healthcheckOnly", "false");
          } else {
            setVisibleScreen(
              <OnboardingProgressDiv
                percentage={
                  validIntegrationTypes.includes(
                    localStorage.selectedIntegrationType
                  )
                    ? 50
                    : 66
                }
                step={2}
                setVisibleScreen={setVisibleScreen}
              />
            );
          }
        }, 2000);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
