import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { checkForRedirectAndFetchMP4 } from "./checkForRedirectAndFetchMP4";

export const getAlertMP4 = (
  selectedRow: any,
  navigate: NavigateFunction,
  setMP4URL: Dispatcher<string>,
  setVideoClipLoading: Dispatcher<boolean>
) => {
  const AxiosUI = createMonitoringAPICall(``);

  const retryFunction = () => {
    getAlertMP4(selectedRow, navigate, setMP4URL, setVideoClipLoading);
  };

  setMP4URL("");

  if (selectedRow) {
    setVideoClipLoading(true);
    AxiosUI.get(
      `alert/clip/?approx_capture_timestamp=${selectedRow.approx_capture_timestamp}&window_id=${selectedRow.window_id}`
    ).then(
      (response: AxiosResponse) => {
        const selectedRowAlerts = JSON.parse(localStorage.selectedRowAlerts);
        if (
          selectedRow.approx_capture_timestamp ===
          selectedRowAlerts.approx_capture_timestamp
        ) {
          setMP4URL(response.data);
          setVideoClipLoading(false);
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setTimeout(() => {
            setVideoClipLoading(false);
          }, 2000);
        });
      }
    );
  }
};

// import { NavigateFunction } from "react-router-dom";
// import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
// import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
// import { getApiUrl } from "../../../utils/getApiUrl";
// import { getCookie } from "../../../utils/getCookie";

// export const getAlertMP4 = (
//   selectedRow: any,
//   navigate: NavigateFunction,
//   setMP4URL: Dispatcher<string>,
//   setVideoClipLoading: Dispatcher<boolean>
// ) => {
//   const baseURL = getApiUrl();
//   const token = getCookie("token");

//   const retryFunction = () => {
//     getAlertMP4(selectedRow, navigate, setMP4URL, setVideoClipLoading);
//   };

//   setMP4URL("");

//   if (selectedRow) {
//     setVideoClipLoading(true);

//     const url = `${baseURL}monitoring-api/alert/clip/?approx_capture_timestamp=${selectedRow.approx_capture_timestamp}&window_id=${selectedRow.window_id}`;

//     fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       redirect: "manual", // Prevent automatic redirects
//     })
//       .then(async (response) => {
//         if (response.status === 302) {
//           console.log("Redirect detected:", response.headers.get("Location"));
//           // Handle the redirect location if necessary
//           setVideoClipLoading(false);
//         } else if (response.ok) {
//           const data = await response.json();
//           const selectedRowAlerts = JSON.parse(localStorage.selectedRowAlerts);
//           if (
//             selectedRow.approx_capture_timestamp ===
//             selectedRowAlerts.approx_capture_timestamp
//           ) {
//             setMP4URL(data);
//           }
//           setVideoClipLoading(false);
//         } else {
//           throw response; // Pass error to the catch block
//         }
//       })
//       .catch((error) => {
//         if (error.url) {
//           console.log("error.url", error.url);
//           setTimeout(() => {
//             setVideoClipLoading(false);
//           }, 2000);
//         }
//         // console.log("error.response", error)
//         // universalAPIErrorHandler(error, navigate, retryFunction, () => {
//         //   setTimeout(() => {
//         //     setVideoClipLoading(false);
//         //   }, 2000);
//         // });
//       });
//   }
// };
