import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleDeactivateSite } from "./SiteAboutPageUtils/handleDeactivateSite";
import { useNavigate } from "react-router-dom";
import {
  AlertState,
  SiteInfo,
  PatchObject,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DeactivateInquiryDialog = ({
  deativateInquiryDialogOpen,
  setDeativateInquiryDialogOpen,
  setSiteInfo,
  setBackdropLoadingScreenOn,
  setAlertState,
  setPatchObject,
  patchObject,
  setArmButtonDisabled,
  setArmedStatus,
  setSiteSchedules,
  setAllSiteSchedules,
  clickedTab,
  setActiveTab,
  setDeleteScheduleDialogOpen,
  siteInfo,
}: {
  deativateInquiryDialogOpen: boolean;
  setDeativateInquiryDialogOpen: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<SiteInfo>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setPatchObject: Dispatcher<PatchObject>;
  patchObject: PatchObject;
  setArmButtonDisabled: any;
  setArmedStatus: any;
  setSiteSchedules: any;
  setAllSiteSchedules: any;
  clickedTab: any;
  setActiveTab: any;
  setDeleteScheduleDialogOpen: any;
  siteInfo: SiteInfo;
}) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={deativateInquiryDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {
          "All Cameras are currently deactivated.  Do you want deactivate this site?"
        }
      </DialogTitle>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setSiteInfo((previousState: SiteInfo) => {
              return { ...previousState, active: true };
            });
            setDeativateInquiryDialogOpen(false);
          }}
          text="No"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setDeativateInquiryDialogOpen(false);
            setPatchObject((previousState: PatchObject) => {
              return {
                ...previousState,
                active: false,
              };
            });
            setSiteInfo((previousState: SiteInfo) => {
              return {
                ...previousState,
                active: false,
              };
            });
            handleDeactivateSite(
              setBackdropLoadingScreenOn,
              patchObject,
              setSiteInfo,
              setAlertState,
              setPatchObject,
              siteInfo,
              setArmButtonDisabled,
              setArmedStatus,
              setSiteSchedules,
              setAllSiteSchedules,
              clickedTab,
              setActiveTab,
              setDeleteScheduleDialogOpen,
              navigate
            );
          }}
          text="Deactivate"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
