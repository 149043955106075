import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const getIndividualGroup = async (
  id: any,
  setRenderedRows: any,
  setPageLoading: any,
  setSiteBreadcrumbs: any,
  setSelected: any,
  setEditableGroupSelected: any,
  setSelectedGroupListName: any,
  navigate: any
) => {
  setPageLoading(true);
  const AxiosUI = createApi(`customer/`);
  const Axios = createApi(``);
  const retryFunction = () => {
    getIndividualGroup(
      id,
      setRenderedRows,
      setPageLoading,
      setSiteBreadcrumbs,
      setSelected,
      setEditableGroupSelected,
      setSelectedGroupListName,
      navigate
    );
  };
  // localStorage.setItem("group_id", id);
  localStorage.setItem("selectedGroupOrSiteId", id);
  setSelected([]);
  await Axios.get(`/group/${id}/`).then(
    (response: AxiosResponse) => {
      localStorage.setItem(
        "selectedIndvidualGroupInfo",
        JSON.stringify(response.data)
      );
      if (response.data.parent === null) {
        setEditableGroupSelected(false);
        setSelectedGroupListName(response.data.name);
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
  await AxiosUI.get(`/by_group_id/${id}/?page_size=1000`).then(
    (response: AxiosResponse) => {
      const data = response.data.results;
      setRenderedRows(data);
      // createRows(data, setRenderedRows, setPageLoading);
      setPageLoading(false);
      // if (data) {
      //   const breadcrumbs = response.data.results[0].breadcrumbs;
      //   setSiteBreadcrumbs(breadcrumbs);
      //   localStorage.setItem(
      //     "currentSiteBreadcrumbs",
      //     JSON.stringify(breadcrumbs)
      //   );
      //   if (localStorage.navigatedFromSitesAboutPage === "true") {
      //     localStorage.setItem("navigatedFromSitesAboutPage", "false");
      //   }
      // }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setPageLoading(false);
      });
    }
  );
};
