import { handleRefreshButtonsFill } from "./handleRefreshButtonsFill";
import { handleRowSelected } from "./handleRowSelected";

export const addSelectedAndDetectionTypeOnRefresh = (
  data: any,
  setAlertsIndexOrder: any,
  setSeeMoreURL: any,
  setSelectedRow: any,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  navigate: any,
  setMP4URL: any,
  setVideoClipLoading: any,
  setSelectedButtonsFilled: any,
  alertData: any,
  setAlertData: any,
  refreshingToken: boolean,
  setBackArrowDisabled: any,
  setForwardArrowDisabled: any,
  setShowRefreshButton: any
) => {
  let selectedRowAlerts = {
    approx_capture_timestamp: "",
  };

  if (
    localStorage.selectedRowAlerts &&
    localStorage.selectedRowAlerts !== "undefined"
  ) {
    selectedRowAlerts = JSON.parse(localStorage.selectedRowAlerts);
  }

  let selectedRowStillPresent = false;

  // Use map instead of forEach for cleaner array transformation
  const indexOrder: number[] = [];
  const newData = data.map((object: any, index: number) => {
    const engineeringOutcome = object.engineering_outcome;
    const customerOutcome = object.customer_outcome;

    const newObject = {
      ...object,
      index,
      selected: false,
      seen: false,
      detection_type: "",
    };
    indexOrder.push(index);

    // Check if this object should be selected
    if (
      object.approx_capture_timestamp ===
      selectedRowAlerts.approx_capture_timestamp
    ) {
      newObject.selected = true;
      selectedRowStillPresent = true;

      if (customerOutcome) {
        handleRefreshButtonsFill(
          customerOutcome.outcome,
          setSelectedButtonsFilled
        );
      }

      setSelectedRow(newObject); // Update selected row state
    }

    // Handle engineering outcomes and detection types
    const outcome = engineeringOutcome
      ? engineeringOutcome.outcome
      : customerOutcome?.outcome;
    const outcomeV2 = engineeringOutcome
      ? engineeringOutcome.outcome_v2
      : customerOutcome?.outcome_v2;

    if (outcome || outcomeV2) {
      newObject.seen = true;
      newObject.notes = engineeringOutcome
        ? engineeringOutcome.notes
        : customerOutcome?.notes;
      newObject.user = engineeringOutcome
        ? engineeringOutcome.user
        : customerOutcome?.user;
      newObject.updated = engineeringOutcome
        ? engineeringOutcome.updated
        : customerOutcome?.updated;

      // Assign detection_type and outcome_number based on outcome_v2 or fallback outcome
      if (outcomeV2) {
        newObject.detection_type = outcomeV2;
        newObject.outcome_number =
          outcomeV2 === "true_threat"
            ? 1
            : outcomeV2 === "true_non_threat"
            ? 2
            : 3; // false_positive = 3
      } else if (outcome) {
        newObject.detection_type =
          outcome === "intruder_detected" ? "true_threat" : "false_positive";
        newObject.outcome_number = outcome === "intruder_detected" ? 1 : 3;
      }
    }

    return newObject;
  });

  // Update index order
  setAlertsIndexOrder(indexOrder);

  // If selected row no longer present, select the first item by default
  if (!selectedRowStillPresent && newData.length > 0) {
    newData[0].selected = true;

    // Perform row selection once for the first item
    handleRowSelected(
      newData[0],
      newData,
      setAlertData,
      setSelectedButtonsFilled,
      setSeeMoreURL,
      navigate,
      setMP4URL,
      setVideoClipLoading,
      setSelectedRow,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      refreshingToken,
      newData,
      setBackArrowDisabled,
      setForwardArrowDisabled,
      setShowRefreshButton
    );
  }

  return newData;
};
