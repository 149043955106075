import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleSendImmixTest } from "./EditCameraUtils/handleSendImmixTest";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { Switch } from "@mui/material";

export const ImmixAlertsTab = ({
  immixAlerts,
  setAddImmixAlertDialogOpen,
  setSendingImmixTest,
  setImmixTestSuccessDialogOpen,
  setImmixTestFailDialogOpen,
  navigate,
}: {
  immixAlerts: any;
  setAddImmixAlertDialogOpen: any;

  setSendingImmixTest: Dispatcher<boolean>;
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>;
  setImmixTestFailDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      <div className="min-w-[1150px] h-[50px] bg-white">
        <div className="absolute right-[70px] top-[10px]">
          {permissions.edit_cameras && (
            <OrangeTextButton
              onClick={() => {
                setAddImmixAlertDialogOpen(true);
              }}
              text="+ Add Immix Alert"
              disabled={false}
            />
          )}
        </div>
      </div>
      {immixAlerts[0] &&
        immixAlerts.map((object: any, index: any) => {
          return (
            <div key={object.id} className="h-[208px]">
              <div className="min-w-[1150px] h-[80px] bg-white">
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "black",
                    textIndent: "1vw",
                  }}
                >
                  Immix Alert {index + 1}
                </Typography>
                <div className="pl-[10px]">
                  <OrangeTextButton
                    onClick={() => {
                      setSendingImmixTest(true);
                      handleSendImmixTest(
                        object,
                        setSendingImmixTest,
                        setImmixTestSuccessDialogOpen,
                        setImmixTestFailDialogOpen,
                        navigate
                      );
                    }}
                    text="Send test alert"
                    disabled={false}
                  />
                </div>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 1000, fontFamily: "Mulish" }}
                    aria-label="simple table"
                  >
                    <TableHeadComponent
                      titlesArray={[
                        "Immix Server",
                        "Immix Port",
                        "Immix Email",
                        "Immix input 1",
                        "Draw Ignore Zones",
                      ]}
                    />
                    <TableBody>
                      <TableRow key={object.id}>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.immix_server ? object.immix_server : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.immix_port ? object.immix_port : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.immix_email ? object.immix_email : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.immix_input1 ? object.immix_input1 : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.draw_ignore_zones !== null ? (
                            <Switch
                              color="warning"
                              checked={object.draw_ignore_zones}
                              disabled={true}
                            />
                          ) : (
                            "--"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          );
        })}
    </div>
  );
};
