import * as React from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MuiTextFieldType } from "../../common/muiFieldTypes/muiTextFieldType";
import { handleCheckBoxClick } from "./CameraAutoAddUtils/handleCheckboxClick";
import { handleImmixEmailInput } from "./CameraAutoAddUtils/handleImmixEmailInput";
import { handleImmixInput1Input } from "./CameraAutoAddUtils/handleImmixInput1Input";
import { Typography } from "@mui/material";
import { handleSelectAllClick } from "./CameraAutoAddUtils/handleSelectAllClick";
import { handleAllCamerasImmixEmailInput } from "./CameraAutoAddUtils/handleAllCamerasImmixEmailInput";
import { handleSureviewEmailInput } from "./CameraAutoAddUtils/handelSureViewEmailInput";
import { handleSureviewCCInput } from "./CameraAutoAddUtils/handleSureViewCCInput";
import { additionalFieldTableCellGenerator } from "./CameraAutoAddUtils/additionalFieldTableCellGenerator";
import { Link } from "react-router-dom";
import { MuiCheckBoxFieldType } from "../../common/muiFieldTypes/muiCheckBoxFieldType";
import { handleSentinelInput } from "./CameraAutoAddUtils/handleSentinelInput";

export const AutoAddTable = ({
  camerasList,
  selectedProducts,
  camerasObject,
  setCamerasObject,
  checkboxesChecked,
  setCheckboxesChecked,
  additionalFieldNamesArray,
  immixSite,
  sureViewSite,
  productsArray,
  sentinelSite,
}: {
  camerasList: any;
  selectedProducts: any;
  camerasObject: any;
  setCamerasObject: any;
  checkboxesChecked: any;
  setCheckboxesChecked: any;
  additionalFieldNamesArray: any;
  immixSite: any;
  sureViewSite: any;
  productsArray: any[];
  sentinelSite: any;
}) => {
  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            sx={{ fontFamily: "Mulish", fontWeight: "Bold", minWidth: 200 }}
          >
            Camera Name
          </TableCell>
          {additionalFieldNamesArray ? (
            additionalFieldNamesArray.map((object: any) => {
              return (
                <TableCell
                  key={object.id}
                  sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}
                >
                  {object.label}
                </TableCell>
              );
            })
          ) : (
            <></>
          )}
          {selectedProducts.map((productName: string) => {
            return (
              <React.Fragment key={productName}>
                <TableCell padding="checkbox">
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish", width: 135 }}
                    control={
                      <Checkbox
                        checked={checkboxesChecked[productName]}
                        color="primary"
                        onChange={(event: any) => {
                          setCheckboxesChecked((previousState: any) => {
                            return {
                              ...previousState,
                              [productName]: event.target.checked,
                            };
                          });
                          handleSelectAllClick(
                            camerasObject,
                            setCamerasObject,
                            productName,
                            event.target.checked,
                            checkboxesChecked,
                            setCheckboxesChecked,
                            productsArray
                          );
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontFamily: "Mulish",
                          fontSize: "14px",
                          fontWeight: "Bold",
                        }}
                      >
                        {productName}
                      </Typography>
                    }
                  />
                </TableCell>
              </React.Fragment>
            );
          })}
          {immixSite && (
            <TableCell sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}>
              {/* <MuiTextFieldType
                    id="immixPortInput"
                    defaultValue=""
                    value=""
                    onChange={(event: any) => {
                      handleAllCamerasImmixEmailInput(
                        camerasObject,
                        setCamerasObject,
                        event.target.value,
                      );
                    }}
                    helperText="Applies to all cameras"
                    stylingWidth={200}
                    label="Immix Email"
                    error={false}
                  /> */}
              Immix Email
            </TableCell>
          )}
          {immixSite && (
            <TableCell sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}>
              {`Immix input 1 (optional)`}
            </TableCell>
          )}
          {sureViewSite && (
            <TableCell sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}>
              {`Sureview Email`}
            </TableCell>
          )}
          {sureViewSite && (
            <TableCell sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}>
              {`Sureview cc`}
            </TableCell>
          )}
          {/* {sentinelSite && (
            <TableCell sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}>
              {`Draw Ignore Zones`}
            </TableCell>
          )} */}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Table stickyHeader aria-label="sticky table">
      <EnhancedTableHead />
      <TableBody>
        {camerasList.results.map((obj: any) => {
          return obj.camera ? (
            <React.Fragment key={obj.id}>
              <TableRow>
                <TableCell sx={{ fontFamily: "Mulish" }}>
                  <div className="text-[#0000EE] hover:text-actuate-orange">
                    <Link
                      to={`/sites/${localStorage.siteId}/editcamera/${obj.camera}`}
                      target="_blank"
                    >
                      {obj.camera_name}
                    </Link>
                  </div>
                </TableCell>
                {additionalFieldNamesArray ? (
                  additionalFieldNamesArray.map((object: any) => {
                    return (
                      <TableCell key={object.id} sx={{ fontFamily: "Mulish" }}>
                        {additionalFieldTableCellGenerator(
                          object.type,
                          object.id,
                          obj
                        )}
                      </TableCell>
                    );
                  })
                ) : (
                  <></>
                )}
                {selectedProducts.map((productName: any) => {
                  const name = `${productName}${obj.id}`;
                  return (
                    <React.Fragment key={productName}>
                      <TableCell>
                        <></>
                      </TableCell>
                    </React.Fragment>
                  );
                })}
                {immixSite && (
                  <TableCell>
                    <></>
                  </TableCell>
                )}
                {immixSite && (
                  <TableCell>
                    <></>
                  </TableCell>
                )}
                {sureViewSite && (
                  <TableCell>
                    <></>
                  </TableCell>
                )}
                {sureViewSite && (
                  <TableCell>
                    <></>
                  </TableCell>
                )}
              </TableRow>
            </React.Fragment>
          ) : (
            <React.Fragment key={obj.id}>
              <TableRow>
                <TableCell sx={{ fontFamily: "Mulish" }}>
                  {obj.camera_name}
                </TableCell>
                {additionalFieldNamesArray ? (
                  additionalFieldNamesArray.map((object: any) => {
                    return (
                      <TableCell key={object.id} sx={{ fontFamily: "Mulish" }}>
                        {additionalFieldTableCellGenerator(
                          object.type,
                          object.id,
                          obj
                        )}
                      </TableCell>
                    );
                  })
                ) : (
                  <></>
                )}
                {selectedProducts.map((productName: any) => {
                  const name = `${productName}${obj.id}`;
                  return (
                    <React.Fragment key={productName}>
                      <TableCell>
                        <FormControlLabel
                          sx={{ fontFamily: "Mulish" }}
                          control={
                            <Checkbox
                              checked={checkboxesChecked[name]}
                              color="primary"
                              onClick={(event: any) => {
                                setCheckboxesChecked((previousState: any) => {
                                  return {
                                    ...previousState,
                                    [name]: event.target.checked,
                                  };
                                });
                                handleCheckBoxClick(
                                  productName,
                                  setCamerasObject,
                                  camerasObject,
                                  obj.id,
                                  event.target.checked,
                                  productsArray
                                );
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontFamily: "Mulish",
                                fontSize: "14px",
                              }}
                            >
                              {productName}
                            </Typography>
                          }
                        />
                      </TableCell>
                    </React.Fragment>
                  );
                })}
                {immixSite && (
                  <TableCell>
                    <MuiTextFieldType
                      id="immixPortInput"
                      defaultValue=""
                      value=""
                      onChange={(event: any) => {
                        handleImmixEmailInput(
                          setCamerasObject,
                          camerasObject,
                          event.target.value,
                          obj.id
                        );
                      }}
                      helperText=""
                      stylingWidth={200}
                      label="Immix Email"
                      error={false}
                    />
                  </TableCell>
                )}
                {immixSite && (
                  <TableCell>
                    <MuiTextFieldType
                      id="immixInput1"
                      defaultValue=""
                      value=""
                      onChange={(event: any) => {
                        handleImmixInput1Input(
                          setCamerasObject,
                          camerasObject,
                          event.target.value,
                          obj.id
                        );
                      }}
                      helperText=""
                      stylingWidth={100}
                      label="Immix input 1"
                      error={false}
                    />
                  </TableCell>
                )}
                {sureViewSite && (
                  <TableCell>
                    <MuiTextFieldType
                      id="sureViewEmail"
                      defaultValue=""
                      value=""
                      onChange={(event: any) => {
                        handleSureviewEmailInput(
                          setCamerasObject,
                          camerasObject,
                          event.target.value,
                          obj.id
                        );
                      }}
                      helperText=""
                      stylingWidth={200}
                      label="Sureview Email"
                      error={false}
                    />
                  </TableCell>
                )}
                {sureViewSite && (
                  <TableCell>
                    <MuiTextFieldType
                      id="sureViewCC"
                      defaultValue=""
                      value=""
                      onChange={(event: any) => {
                        handleSureviewCCInput(
                          setCamerasObject,
                          camerasObject,
                          event.target.value,
                          obj.id
                        );
                      }}
                      helperText=""
                      stylingWidth={200}
                      label="Sureview cc"
                      error={false}
                    />
                  </TableCell>
                )}
                {/* {sentinelSite && (
                  <TableCell>
                    <MuiCheckBoxFieldType
                      id="drawIgnoreZones"
                      checked={false}
                      disabled={false}
                      onChange={(event: any, checked: boolean) => {
                        handleSentinelInput(
                          setCamerasObject,
                          camerasObject,
                          "",
                          checked,
                          obj.id,
                          "drawIgnoreZones"
                        );
                      }}
                      label=""
                    />
                  </TableCell>
                )} */}
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};
