import React from "react";
import { StepsStepper } from "./StepsStepper";
import { WizardContainer } from "../../common/formikContainer/WizardContainer";
import Alert from "@mui/material/Alert";
import ErrorBoundary from "../../../ErrorBoundary";

export const WizardContainerScreen = ({
  handleSubmitSite,
  responseObject,
  setVisibleScreen,
}: {
  handleSubmitSite: any;
  responseObject: any;
  setVisibleScreen: any;
}) => {
  const [addSiteError, setAddSiteError] = React.useState(
    localStorage.addSiteError
  );

  const [choicesChanger, setChoicesChanger] = React.useState(true);

  const [healthCheckSelected, setHealthCheckSelected] = React.useState(false);

  const [activeWizardContainer, setActiveWizardContainer] = React.useState(
    <></>
  );

  React.useEffect(() => {
    setActiveWizardContainer(
      <WizardContainer
        handleSubmitData={handleSubmitSite}
        response={responseObject}
        setVisibleScreen={setVisibleScreen}
        responseObject={false}
        choicesChanger={choicesChanger}
        setChoicesChanger={setChoicesChanger}
        setHealthCheckSelected={setHealthCheckSelected}
        healthCheckSelected={healthCheckSelected}
        setActiveWizardContainer={setActiveWizardContainer}
        handleSubmitSite={handleSubmitSite}
        setCameraCheckFinsihedAlertOpen={() => {}}
        setGoodCameras={() => {}}
        setConnectivityCameras={() => {}}
        setLowResolutionCameras={() => {}}
        setCameraCheckResultDownloadURL={() => {}}
      />
    );
  }, []);

  return (
    <ErrorBoundary>
      <div>
        {addSiteError && (
          <div className="absolute w-[600px] h-[50px]">
            <Alert
              severity="error"
              onClose={() => {
                setAddSiteError(false);
                localStorage.removeItem("addSiteError");
              }}
            >
              <p className="font-mulish font-bold text-[16px]">
                Problem adding Site. Please try again.
              </p>
            </Alert>
          </div>
        )}
        {/* {noGroupError && (
          <div className="absolute w-[600px] h-[50px]">
            <Alert
              severity="error"
              onClose={() => {
                setNoGroupError(false);
                localStorage.removeItem("noGroupError");
              }}
            >
              <p className="font-mulish font-bold text-[16px]">
                Please select a valid group and retry.
              </p>
            </Alert>
          </div>
        )} */}
        <div className="absolute top-20 right-[15px]">
          <div className="flex justify-end">
            <StepsStepper activeStep={1} />
          </div>
        </div>
        <p
          className={
            "font-mulish absolute top-[130px] text-[#283E6D] right-[150px] text-[18px]"
          }
        >
          Please complete the following for your Integration:
        </p>
        <div className="absolute right-[215px] top-[180px]">
          {activeWizardContainer}
        </div>
      </div>
    </ErrorBoundary>
  );
};
