import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getStreamParametersExtraFields = (
  cameraId: string,
  setStreamParametersData: React.Dispatch<React.SetStateAction<any>>,
  setShowStreamParameters: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    getStreamParametersExtraFields(
      cameraId,
      setStreamParametersData,
      setShowStreamParameters,
      navigate
    );
  };

  AxiosUI.get(`/camera/${cameraId}/extra_fields/`).then(
    (response: AxiosResponse) => {
      if (response.data.extra_fields[0]) {
        setShowStreamParameters(true);
        setStreamParametersData(response.data);
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
