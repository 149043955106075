import React from "react";
import * as Yup from "yup";
import Step from "./Step";
import Wizard from "./Wizard";
import { useNavigate } from "react-router-dom";
import { Dispatcher } from "../../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { useFormikContext } from "formik";

export const WizardContainer = ({
  handleSubmitData,
  response,
  setVisibleScreen,
  responseObject,
  choicesChanger,
  setChoicesChanger,
  setHealthCheckSelected,
  healthCheckSelected,
  setActiveWizardContainer,
  handleSubmitSite,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
}: {
  handleSubmitData: any;
  response: any;
  setVisibleScreen: any;
  responseObject: any;
  choicesChanger: any;
  setChoicesChanger: any;
  setHealthCheckSelected: Dispatcher<boolean>;
  healthCheckSelected: boolean;
  setActiveWizardContainer: any;
  handleSubmitSite: any;
  setCameraCheckFinsihedAlertOpen: any;
  setGoodCameras: any;
  setConnectivityCameras: any;
  setLowResolutionCameras: any;
  setCameraCheckResultDownloadURL: any;
}) => {
  const navigate = useNavigate();
  const [errorState, setErrorState] = React.useState({});
  const [stepNumber, setStepNumber] = React.useState(0);
  const [nextOrSubmitButtonDisabled, setNextOrSubmitButtonDisabled] =
    React.useState(true);

  return (
    <Wizard
      initialValues={response["initial_data"]}
      onSubmit={(values: any) => {
        localStorage.setItem("values", JSON.stringify(values));
        if (localStorage.cameraCheckAlertOpen) {
          handleSubmitData(
            values,
            setVisibleScreen,
            responseObject,
            setCameraCheckFinsihedAlertOpen,
            setGoodCameras,
            setConnectivityCameras,
            setLowResolutionCameras,
            setCameraCheckResultDownloadURL,
            navigate
          );
        } else {
          handleSubmitData(
            values,
            setVisibleScreen,
            responseObject,
            navigate,
            response
          );
        }
      }}
      setVisibleScreen={setVisibleScreen}
      responseObject={responseObject}
      errorState={errorState}
      choicesChanger={choicesChanger}
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
      setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
      setGoodCameras={setGoodCameras}
      setConnectivityCameras={setConnectivityCameras}
      setLowResolutionCameras={setLowResolutionCameras}
      setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
      nextOrSubmitButtonDisabled={nextOrSubmitButtonDisabled}
    >
      {response["forms"].map((formConfig: any) => {
        const validationSchema = Yup.object({
          //get stuff from the current formconfig to make the validationschema
        });
        return (
          // eslint-disable-next-line react/jsx-key
          <Step
            formConfig={formConfig}
            validationSchema={validationSchema}
            setErrorState={setErrorState}
            choicesChanger={choicesChanger}
            setChoicesChanger={setChoicesChanger}
            stepNumber={stepNumber}
            setHealthCheckSelected={setHealthCheckSelected}
            healthCheckSelected={healthCheckSelected}
            setActiveWizardContainer={setActiveWizardContainer}
            handleSubmitSite={handleSubmitSite}
            responseObject={response}
            setVisibleScreen={setVisibleScreen}
            setNextOrSubmitButtonDisabled={setNextOrSubmitButtonDisabled}
          />
        );
      })}
    </Wizard>
  );
};
