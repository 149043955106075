import React from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { AlertData, HeadCell } from "../../Alerts/AlertsUtils/alertsInterfaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { visuallyHidden } from "@mui/utils";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AlertData
  ) => void;
  order: Order;
  orderBy: string;
}
const headCells: readonly HeadCell[] = [
  {
    id: "camera_name",
    numeric: false,
    label: "Camera Name",
    text: "Hover over camera name to see sample image from camera feed.",
  },
  {
    id: "active",
    numeric: false,
    label: "Active",
    text: "A green checkmark indicates a camera is running. A red X indicates it is not.",
  },
  {
    id: "deployed",
    numeric: false,
    label: `Synced`,
    text: "An indication of whether the camera has been edited since its last deployment. A green checkmark will show if the most current version of the camera is running and a red x will show if the changes to the camera have not been synced - in which case it should be re-synced",
  },
  {
    id: "use_motion",
    numeric: false,
    label: "Use Motion",
  },
  {
    id: "has_ignore_zones",
    numeric: false,
    label: "Ignore Zones",
    text: "A green checkmark indicates the site has active ignore zones. A red X indicates no ignore zones have been created.",
  },
  {
    id: "health_status",
    numeric: false,
    label: "Health",
    text: "A green checkmark indicates a camera is running without issue. A red X indicates there is an issue(s) with the camera. Hovering over the X will display the issue(s).",
  },
  {
    id: "optimal_settings",
    numeric: false,
    label: "Optimal Settings",
    text: "A green checkmark indicates this camera has optimal settings.  A warning symbol indicate this camera has acceptable but not optimal settings that may result in performance issues. A red X indicates this camera has bad settings that will likely result in performance issues.",
  },
];

export const EnhancedCameraTableHead = (props: any) => {
  const {
    onSelectAllClick,
    onRequestSort,
    orderBy,
    order,
    permissions,
    allExpanded,
    setAllExpanded,
    setExpandedObject,
    saveExpandedObjectAllTrue,
    saveExpandedObjectAllFalse,
  } = props;
  const createSortHandler =
    (property: keyof AlertData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {permissions.edit_cameras && (
          <TableCell padding="checkbox">
            <Checkbox color="primary" onChange={onSelectAllClick} />
          </TableCell>
        )}
        {headCells.map((headCell) => {
          if (headCell.text) {
            return (
              <TableCell
                sx={{
                  fontSize: "15px",
                  fontFamily: "mulish",
                  fontWeight: "Bold",
                  maxWidth: 50,
                }}
                style={{ minWidth: 110 }}
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  &nbsp;
                  <Tooltip
                    title={headCell.text}
                    placement="top"
                    sx={{ fontSize: "15px", fontFamily: "mulish" }}
                  >
                    <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                  </Tooltip>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                sx={{
                  fontSize: "15px",
                  fontFamily: "mulish",
                  fontWeight: "Bold",
                }}
                style={{ maxWidth: 100 }}
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          {!allExpanded ? (
            <OrangeTextButton
              onClick={() => {
                setAllExpanded(true);
                setExpandedObject(
                  (previousState: { [key: string]: boolean }) => {
                    //set all items in previous object to true
                    const newState = { ...previousState };
                    for (const key in newState) {
                      newState[key] = true;
                    }
                    return newState;
                  }
                );
                saveExpandedObjectAllTrue();
              }}
              text={
                <div className="flex flex-row">
                  <KeyboardArrowDownIcon />
                  Expand All
                </div>
              }
              disabled={false}
            />
          ) : (
            <OrangeTextButton
              onClick={() => {
                setAllExpanded(false);
                setExpandedObject(
                  (previousState: { [key: string]: boolean }) => {
                    //set all items in previous object to false
                    const newState = { ...previousState };
                    for (const key in newState) {
                      newState[key] = false;
                    }
                    return newState;
                  }
                );
                saveExpandedObjectAllFalse();
              }}
              text={
                <div className="flex flex-row">
                  <KeyboardArrowUpIcon />
                  Collapse All
                </div>
              }
              disabled={false}
            />
          )}{" "}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
