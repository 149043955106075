import React from "react";

export const handleCheckForRequriedFields = (
  formikValues: any,
  responseObject: any,
  setNextOrSubmitButtonDisabled: any
) => {
  let allRequiredFieldsFilled = true;

  responseObject.forEach((step: any) => {
    if (step.validation) {
      if (
        formikValues[step.field] === "" ||
        formikValues[step.field] === undefined ||
        formikValues[step.field] === null ||
        //or if it is an array and the length is 0
        (Array.isArray(formikValues[step.field]) &&
          formikValues[step.field].length === 0)
      ) {
        allRequiredFieldsFilled = false;
      }
    }
  });

  setNextOrSubmitButtonDisabled(!allRequiredFieldsFilled);
};
