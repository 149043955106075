import { NavLink } from "react-router-dom";
import * as React from "react";
import * as ActuateLogo from "../../../assets/AcuateLogo.png";
import * as Actuate_Icon_Orange from "../../../assets/Actuate_Icon_Orange.png";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { UserLink } from "./UserLink";
import { handleLocalStorageChangesOnNavBarClick } from "../utils/handleLocalStorageChangesOnNavBarClick";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ErrorIcon from "@mui/icons-material/Error";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Tooltip from "@mui/material/Tooltip";
import { Dispatcher } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import useWindowDimensions from "./useWindowDimensions";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { showReleaseNotesBanner } from "../pages/ReleaseNotesBanner";

export const NavBar = ({
  navBarCollapsed,
  setNavBarCollapsed,
  ...props
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
  props: SvgIconProps;
}) => {
  const appVersion = localStorage.packageJsonVersion;

  const navLinkStyles = ({ isActive }: { isActive: boolean }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
    };
  };

  const [alertsIconOpen, setAlertsIconOpen] = React.useState(
    localStorage.alertsIconOpen
  );

  const [alertsPagesVisible, setAlertPagesVisible] = React.useState(false);

  const [cameraHealthVisible, setCameraHealthVisible] = React.useState(false);

  const userInfo = JSON.parse(localStorage.userInfo);

  const { height } = useWindowDimensions();

  React.useEffect(() => {
    if (localStorage.alertsIconOpen) {
      setAlertsIconOpen(false);
    }
    if (localStorage.alertsIconOpen === "true") {
      setAlertsIconOpen(true);
    }
    if (userInfo.permission.chm) {
      setCameraHealthVisible(true);
    }
    if (
      userInfo.permission.ailink ||
      userInfo.permission.sentinel ||
      userInfo.permission.smtp
    ) {
      setAlertPagesVisible(true);
    }
  }, []);

  React.useEffect(() => {
    if (userInfo.permission.chm) {
      setCameraHealthVisible(true);
    }
  }, []);

  const fullNavBarScreen = (
    <div>
      <nav className="bg-actuate-blue text-white absolute w-[150px] h-full z-20">
        <div>
          <div className="absolute left-4 top-5">
            <NavLink to="/" style={navLinkStyles}>
              <img className="w-24" src={ActuateLogo} alt="actuate logo" />
            </NavLink>
          </div>
          <div className="absolute left-[120px] top-4">
            <button
              onClick={() => {
                setNavBarCollapsed(!navBarCollapsed);
                localStorage.setItem(
                  "navBarCollapsed",
                  JSON.stringify(!navBarCollapsed)
                );
              }}
            >
              <MenuOpenOutlinedIcon />
            </button>
          </div>
          <br />
          <br />
          <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
            <NavLink
              to="/dashboard"
              style={navLinkStyles}
              onClick={() => {
                handleLocalStorageChangesOnNavBarClick();
              }}
            >
              <SvgIcon {...props}>
                <path
                  fill="currentColor"
                  d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586l6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"
                />
              </SvgIcon>
              &nbsp; Dashboard
            </NavLink>
          </div>
          <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
            <NavLink to="/sites" style={navLinkStyles}>
              <SvgIcon {...props}>
                <path
                  fill="currentColor"
                  d="M20.916 9.564a.998.998 0 0 0-.513-1.316L7.328 2.492c-.995-.438-2.22.051-2.645 1.042l-2.21 5.154a2.001 2.001 0 0 0 1.052 2.624L9.563 13.9L8.323 17H4v-3H2v8h2v-3h4.323c.823 0 1.552-.494 1.856-1.258l1.222-3.054l5.205 2.23a1 1 0 0 0 1.31-.517l.312-.71l1.701.68l2-5l-1.536-.613l.523-1.194zm-4.434 5.126L4.313 9.475l2.208-5.152l12.162 5.354l-2.201 5.013z"
                />
              </SvgIcon>
              &nbsp; Sites
            </NavLink>
          </div>
          <div className="py-1.5 indent-3 font-mulish text-base text-[14.5px] font-normal	">
            {!alertsPagesVisible && (
              <NavLink
                to="/alerts"
                style={navLinkStyles}
                onClick={() => {
                  handleLocalStorageChangesOnNavBarClick();
                }}
              >
                <SvgIcon {...props}>
                  <path
                    fill="currentColor"
                    d="M11.99 22C6.468 21.994 1.996 17.515 2 11.993C2.004 6.472 6.482 1.998 12.003 2C17.525 2.002 22 6.478 22 12c-.003 5.525-4.485 10.002-10.01 10ZM4 12.172A8 8 0 1 0 4 12v.172ZM13 17h-2v-2h2v2Zm0-4h-2V7h2v6Z"
                  />
                </SvgIcon>
                &nbsp; Alerts
              </NavLink>
            )}
            {alertsPagesVisible && (
              <button
                onClick={() => {
                  setAlertsIconOpen(!alertsIconOpen);
                  localStorage.setItem(
                    "alertsIconOpen",
                    JSON.stringify(!alertsIconOpen)
                  );
                }}
              >
                <SvgIcon {...props}>
                  <path
                    fill="currentColor"
                    d="M11.99 22C6.468 21.994 1.996 17.515 2 11.993C2.004 6.472 6.482 1.998 12.003 2C17.525 2.002 22 6.478 22 12c-.003 5.525-4.485 10.002-10.01 10ZM4 12.172A8 8 0 1 0 4 12v.172ZM13 17h-2v-2h2v2Zm0-4h-2V7h2v6Z"
                  />
                </SvgIcon>
                &nbsp; Alerts &nbsp;&nbsp;&nbsp;&nbsp;
                {!alertsIconOpen && <KeyboardArrowDownIcon />}
                {alertsIconOpen && <KeyboardArrowUpIcon />}
              </button>
            )}
            {alertsIconOpen && (
              <div className="relative left-[0px] text-[14.5px]  p-[5px] pt-[10px]">
                <NavLink
                  to="/alerts"
                  style={navLinkStyles}
                  onClick={() => {
                    handleLocalStorageChangesOnNavBarClick();
                  }}
                >
                  <ErrorIcon />
                  &nbsp; Live Alerts
                </NavLink>
              </div>
            )}
            {alertsIconOpen && (
              <div className="relative left-[0px] text-[14.5px] p-[5px]">
                <NavLink
                  to="/investigation"
                  style={navLinkStyles}
                  onClick={() => {
                    handleLocalStorageChangesOnNavBarClick();
                  }}
                >
                  <PersonSearchIcon />
                  &nbsp; Investigation
                </NavLink>
              </div>
            )}
          </div>
          <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
            <NavLink
              to="/analytics"
              style={navLinkStyles}
              onClick={() => {
                handleLocalStorageChangesOnNavBarClick();
              }}
            >
              <SvgIcon {...props}>
                <path
                  fill="currentColor"
                  d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"
                />
                <path
                  fill="currentColor"
                  d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5l-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5l1.414 1.414L13 12.414l2.293 2.293z"
                />
              </SvgIcon>
              &nbsp; Analytics
            </NavLink>
          </div>
          {cameraHealthVisible && (
            <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
              <NavLink
                to="/health"
                style={navLinkStyles}
                onClick={() => {
                  handleLocalStorageChangesOnNavBarClick();
                }}
              >
                <SvgIcon {...props}>
                  <QueryStatsIcon />
                </SvgIcon>
                &nbsp; Health
              </NavLink>
            </div>
          )}
          <div
            className={
              height > 450
                ? "absolute bottom-[105px] indent-3 w-[150px]"
                : "absolute top-[320px] indent-3 w-[150px]"
            }
          >
            <button
              onClick={() => {
                showReleaseNotesBanner();
                // window.open(`https://releasenotes.actuateui.net/`);
              }}
            >
              <SvgIcon {...props}>
                <TextSnippetIcon />
              </SvgIcon>
              <p className="absolute top-[1px] text-white text-[14.5px] font-mulish indent-[29px] font-normal">
                Release Notes
              </p>
            </button>
          </div>
          <div
            className={
              height > 450
                ? "absolute bottom-[70px] indent-3"
                : "absolute top-[355px] indent-3"
            }
          >
            <button
              onClick={() => {
                window.open(`https://support.actuateui.net`);
              }}
            >
              <SvgIcon {...props}>
                <HelpOutlineIcon />
              </SvgIcon>
              <p className="absolute top-[1px] text-white text-[14.5px] font-mulish indent-[29px] font-normal">
                Support
              </p>
            </button>
          </div>
          <NavLink to="/">
            <UserLink />
          </NavLink>
          <p
            className={
              height > 450
                ? "absolute bottom-[5px] text-white text-[14.5px] font-mulish indent-3 font-normal"
                : "absolute top-[425px] text-white text-[14.5px] font-mulish indent-3 font-normal"
            }
          >
            {" "}
            Version {appVersion}
          </p>
        </div>
      </nav>
    </div>
  );

  const collapsedNavBarScreen = (
    <div>
      <nav className="bg-actuate-blue text-white absolute w-[75px] h-full z-20">
        <div>
          <div className="absolute left-4 top-5">
            <Tooltip title="Home" placement="right">
              <NavLink to="/" style={navLinkStyles}>
                <img
                  className="w-5"
                  src={Actuate_Icon_Orange}
                  alt="actuate logo"
                />
              </NavLink>
            </Tooltip>
          </div>
          <div className="absolute left-10 top-4">
            <button
              onClick={() => {
                setNavBarCollapsed(!navBarCollapsed);
                localStorage.setItem(
                  "navBarCollapsed",
                  JSON.stringify(!navBarCollapsed)
                );
              }}
            >
              <MenuOutlinedIcon />
            </button>
          </div>
          <br />
          <br />
          <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
            <NavLink
              to="/dashboard"
              style={navLinkStyles}
              onClick={() => {
                handleLocalStorageChangesOnNavBarClick();
              }}
            >
              <Tooltip title="Dashboard" placement="right">
                <SvgIcon {...props}>
                  <path
                    fill="currentColor"
                    d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586l6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"
                  />
                </SvgIcon>
              </Tooltip>
            </NavLink>
          </div>
          <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
            <NavLink to="/sites" style={navLinkStyles}>
              <Tooltip title="Sites" placement="right">
                <SvgIcon {...props}>
                  <path
                    fill="currentColor"
                    d="M20.916 9.564a.998.998 0 0 0-.513-1.316L7.328 2.492c-.995-.438-2.22.051-2.645 1.042l-2.21 5.154a2.001 2.001 0 0 0 1.052 2.624L9.563 13.9L8.323 17H4v-3H2v8h2v-3h4.323c.823 0 1.552-.494 1.856-1.258l1.222-3.054l5.205 2.23a1 1 0 0 0 1.31-.517l.312-.71l1.701.68l2-5l-1.536-.613l.523-1.194zm-4.434 5.126L4.313 9.475l2.208-5.152l12.162 5.354l-2.201 5.013z"
                  />
                </SvgIcon>
              </Tooltip>
            </NavLink>
          </div>
          <div className="py-1.5 indent-3 font-mulish text-base text-[14.5px] font-normal	">
            {!alertsPagesVisible && (
              <NavLink
                to="/alerts"
                style={navLinkStyles}
                onClick={() => {
                  handleLocalStorageChangesOnNavBarClick();
                }}
              >
                <Tooltip title="Alerts" placement="right">
                  <SvgIcon {...props}>
                    <path
                      fill="currentColor"
                      d="M11.99 22C6.468 21.994 1.996 17.515 2 11.993C2.004 6.472 6.482 1.998 12.003 2C17.525 2.002 22 6.478 22 12c-.003 5.525-4.485 10.002-10.01 10ZM4 12.172A8 8 0 1 0 4 12v.172ZM13 17h-2v-2h2v2Zm0-4h-2V7h2v6Z"
                    />
                  </SvgIcon>
                </Tooltip>
              </NavLink>
            )}
            {alertsPagesVisible && (
              <button
                onClick={() => {
                  setAlertsIconOpen(!alertsIconOpen);
                  localStorage.setItem(
                    "alertsIconOpen",
                    JSON.stringify(!alertsIconOpen)
                  );
                }}
              >
                <Tooltip title="Alerts" placement="right">
                  <SvgIcon {...props}>
                    <path
                      fill="currentColor"
                      d="M11.99 22C6.468 21.994 1.996 17.515 2 11.993C2.004 6.472 6.482 1.998 12.003 2C17.525 2.002 22 6.478 22 12c-.003 5.525-4.485 10.002-10.01 10ZM4 12.172A8 8 0 1 0 4 12v.172ZM13 17h-2v-2h2v2Zm0-4h-2V7h2v6Z"
                    />
                  </SvgIcon>
                </Tooltip>
                {!alertsIconOpen && <KeyboardArrowDownIcon />}
                {alertsIconOpen && <KeyboardArrowUpIcon />}
              </button>
            )}
            {alertsIconOpen && (
              <div className="relative left-[4px] p-[5px] pt-[10px]">
                <NavLink
                  to="/alerts"
                  style={navLinkStyles}
                  onClick={() => {
                    handleLocalStorageChangesOnNavBarClick();
                  }}
                >
                  <Tooltip title="Live Alerts" placement="right">
                    <ErrorIcon />
                  </Tooltip>
                </NavLink>
              </div>
            )}
            {alertsIconOpen && (
              <div className="relative left-[4px] p-[5px]">
                <NavLink
                  to="/investigation"
                  style={navLinkStyles}
                  onClick={() => {
                    handleLocalStorageChangesOnNavBarClick();
                  }}
                >
                  <Tooltip title="Investigation" placement="right">
                    <PersonSearchIcon />
                  </Tooltip>
                </NavLink>
              </div>
            )}
          </div>
          <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
            <NavLink
              to="/analytics"
              style={navLinkStyles}
              onClick={() => {
                handleLocalStorageChangesOnNavBarClick();
              }}
            >
              <Tooltip title="Analytics" placement="right">
                <SvgIcon {...props}>
                  <path
                    fill="currentColor"
                    d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"
                  />
                  <path
                    fill="currentColor"
                    d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5l-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5l1.414 1.414L13 12.414l2.293 2.293z"
                  />
                </SvgIcon>
              </Tooltip>
            </NavLink>
          </div>
          {cameraHealthVisible && (
            <div className="py-1.5 indent-3 font-mulish text-[14.5px] text-base font-normal	">
              <NavLink
                to="/health"
                style={navLinkStyles}
                onClick={() => {
                  handleLocalStorageChangesOnNavBarClick();
                }}
              >
                <Tooltip title="Health" placement="right">
                  <SvgIcon {...props}>
                    <QueryStatsIcon />
                  </SvgIcon>
                </Tooltip>
                &nbsp;
              </NavLink>
            </div>
          )}
          <div
            className={
              height > 450
                ? "absolute bottom-[105px] indent-3"
                : "absolute top-[320px] indent-3"
            }
          >
            <button
              onClick={() => {
                window.open(`https://releasenotes.actuateui.net/`);
              }}
            >
              <Tooltip title="Release Notes" placement="right">
                <SvgIcon {...props}>
                  <TextSnippetIcon />
                </SvgIcon>
              </Tooltip>
            </button>
          </div>
          <div
            className={
              height > 450
                ? "absolute bottom-[70px] indent-3"
                : "absolute top-[355px] indent-3"
            }
          >
            <button
              onClick={() => {
                showReleaseNotesBanner();
              }}
            >
              <Tooltip title="Support" placement="right">
                <SvgIcon {...props}>
                  <HelpOutlineIcon />
                </SvgIcon>
              </Tooltip>
            </button>
          </div>
          <NavLink to="/">
            <p
              className={
                height > 450
                  ? "absolute bottom-[35px] text-white text-[16px] font-mulish indent-3 font-normal"
                  : "absolute top-[390px] text-white text-[16px] font-mulish indent-3 font-normal"
              }
            >
              <Tooltip title="User" placement="right">
                <SvgIcon {...props}>
                  <path
                    fill="currentColor"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88a9.947 9.947 0 0 1 12.28 0C16.43 19.18 14.03 20 12 20z"
                  />
                </SvgIcon>
              </Tooltip>
            </p>
          </NavLink>
        </div>
      </nav>
    </div>
  );

  return <>{navBarCollapsed ? collapsedNavBarScreen : fullNavBarScreen}</>;
};
