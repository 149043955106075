import React from "react";
import RecursiveContainer from "./RecursiveContainer";
import { useFormikContext } from "formik";
import { Dispatcher } from "../../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const Step = ({
  formConfig,
  validationSchema,
  setErrorState,
  choicesChanger,
  setChoicesChanger,
  stepNumber,
  setHealthCheckSelected,
  healthCheckSelected,
  setActiveWizardContainer,
  handleSubmitSite,
  responseObject,
  setVisibleScreen,
  setNextOrSubmitButtonDisabled,
}: {
  formConfig: any;
  validationSchema: any;
  setErrorState: any;
  choicesChanger: any;
  setChoicesChanger: any;
  stepNumber: any;
  setHealthCheckSelected: Dispatcher<boolean>;
  healthCheckSelected: boolean;
  setActiveWizardContainer: any;
  handleSubmitSite: any;
  responseObject: any;
  setVisibleScreen: any;
  setNextOrSubmitButtonDisabled: Dispatcher<boolean>;
}) => {
  const formik = useFormikContext();

  // formConfig.label === ""

  return (
    <RecursiveContainer
      config={formConfig}
      formik={formik}
      setErrorState={setErrorState}
      choicesChanger={choicesChanger}
      setChoicesChanger={setChoicesChanger}
      stepNumber={stepNumber}
      setHealthCheckSelected={setHealthCheckSelected}
      healthCheckSelected={healthCheckSelected}
      setActiveWizardContainer={setActiveWizardContainer}
      handleSubmitSite={handleSubmitSite}
      responseObject={responseObject}
      setVisibleScreen={setVisibleScreen}
      setNextOrSubmitButtonDisabled={setNextOrSubmitButtonDisabled}
    />
  );
};

export default Step;
