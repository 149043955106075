import React from "react";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleEditInformationButtonClick } from "./SiteAboutPageUtils/handleEditInformationButtonClick";
import EditIcon from "@mui/icons-material/Edit";
import {
  AlertState,
  SiteSchedule,
  SiteInfo,
  PatchObject,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const EditInformationButton = ({
  clickedTab,
  setEditMode,
  setActiveTab,
  setPatchObject,
  setSiteInfo,
  vmsVariables,
  setVmsVariables,
  allSiteSchedules,
  siteInfo,
  setAllSiteSchedules,
  setBackdropLoadingScreenOn,
  editMode,
  setSchedulesTabActive,
  setClickedTab,
  setAlertState,
  setSyncNeeded,
  setSiteInfoChangesMade,
  setScheduleChangesMade,
  setDeleteScheduleDialogOpen,
  recordingServerData,
  recordingServerPatchObject,
  setRecordingServerPatchObject,
  setRecordingServerChangesMade,
  healthCheckData,
  setCameraHealthPatchObject,
  setCameraHealthChangesMade,
  setAddCameraHealthDialogOpen,
  setTurnOffHealthDialogOpen,
  webhooksInfo,
  setWebhooksPatchObject,
  setWebhooksChangesMade,
  setSiteInfoBackup,
  setVmsVariablesBackup,
}: {
  clickedTab: string;
  setEditMode: Dispatcher<boolean>;
  setActiveTab: Dispatcher<JSX.Element>;
  setPatchObject: Dispatcher<PatchObject>;
  setSiteInfo: Dispatcher<SiteInfo>;
  vmsVariables: (string | string[] | undefined)[][];
  setVmsVariables: Dispatcher<(string | string[] | undefined)[][]>;
  allSiteSchedules: SiteSchedule[];
  siteInfo: SiteInfo;
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  editMode: boolean;
  setSchedulesTabActive: Dispatcher<boolean>;
  setClickedTab: Dispatcher<string>;
  setAlertState: Dispatcher<AlertState>;
  setSyncNeeded: Dispatcher<boolean>;
  setSiteInfoChangesMade: Dispatcher<boolean>;
  setScheduleChangesMade: Dispatcher<boolean>;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  recordingServerData: any;
  recordingServerPatchObject: any;
  setRecordingServerPatchObject: any;
  setRecordingServerChangesMade: Dispatcher<boolean>;
  healthCheckData: any;
  setCameraHealthPatchObject: any;
  setCameraHealthChangesMade: Dispatcher<boolean>;
  setAddCameraHealthDialogOpen: Dispatcher<boolean>;
  setTurnOffHealthDialogOpen: any;
  webhooksInfo: any;
  setWebhooksPatchObject: any;
  setWebhooksChangesMade: any;
  setSiteInfoBackup: any;
  setVmsVariablesBackup: any;
}) => {
  const editInformationText = (
    <>
      <EditIcon fontSize="small" />
      &nbsp;Edit Information
    </>
  );
  return (
    <div>
      {clickedTab !== "four" && (
        <OrangeTextButton
          onClick={() =>
            handleEditInformationButtonClick(
              clickedTab,
              setEditMode,
              setActiveTab,
              setPatchObject,
              setSiteInfo,
              vmsVariables,
              setVmsVariables,
              allSiteSchedules,
              siteInfo,
              setAllSiteSchedules,
              setBackdropLoadingScreenOn,
              editMode,
              setSchedulesTabActive,
              setClickedTab,
              setAlertState,
              setSyncNeeded,
              setSiteInfoChangesMade,
              setScheduleChangesMade,
              setDeleteScheduleDialogOpen,
              recordingServerData,
              recordingServerPatchObject,
              setRecordingServerPatchObject,
              setRecordingServerChangesMade,
              healthCheckData,
              setCameraHealthPatchObject,
              setCameraHealthChangesMade,
              setAddCameraHealthDialogOpen,
              setTurnOffHealthDialogOpen,
              webhooksInfo,
              setWebhooksPatchObject,
              setWebhooksChangesMade,
              setSiteInfoBackup,
              setVmsVariablesBackup
            )
          }
          text={editInformationText}
          disabled={false}
        />
      )}
    </div>
  );
};
