import React from "react";
import { createApi } from "../../../utils/createApi";
import { getNumbersAfterSites } from "../EditCamera";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";

export const getCustomerWebhooks = (
  navigate: NavigateFunction,
  setCustomerWebhooks: any
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    getCustomerWebhooks(navigate, setCustomerWebhooks);
  };

  AxiosUI.get(
    `webhook_customer/?customer=${getNumbersAfterSites(location.href)}`
  ).then(
    (response: AxiosResponse) => {
      setCustomerWebhooks(response.data);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("No webhooks found for this customer");
      });
    }
  );
};
