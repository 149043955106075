import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const StreamParametersTabEditMode = ({
  streamParametersData,
  streamParametersPatchObject,
  setStreamParametersPatchObject,
  setStreamParametersChangesMade,
}: {
  streamParametersData: any;
  streamParametersPatchObject: any;
  setStreamParametersPatchObject: any;
  setStreamParametersChangesMade: Dispatcher<boolean>;
}) => {
  const titlesArray: string[] = [];

  const extraFields = streamParametersData.extra_fields;

  extraFields.forEach((field: any) => {
    titlesArray.push(field.label);
  });

  React.useEffect(() => {
    const newPatchObject = { ...streamParametersPatchObject };
    extraFields.forEach((field: any) => {
      newPatchObject[field.field] = streamParametersData[field.field];
    });
    setStreamParametersPatchObject(newPatchObject);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000, fontFamily: "Mulish" }}>
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          <TableRow key={`stream_parameters`}>
            {extraFields.map((object: any) => (
              <TableCell
                align="left"
                key={object.id}
                sx={{ fontFamily: "Mulish" }}
              >
                <div>
                  <TextField
                    id={object.id}
                    defaultValue={streamParametersData[object.field]}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(e) => {
                      setStreamParametersChangesMade(true);
                      //   const newPatchObject = { ...streamParametersPatchObject };
                      //   newPatchObject[object.field] = e.target.value;
                      setStreamParametersPatchObject((prev: any) => ({
                        ...prev,
                        [object.field]: e.target.value,
                      }));
                    }}
                    helperText={object.helperText ? object.helperText : ""}
                  />
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
