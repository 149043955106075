import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { Dispatcher } from "../../SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { handleUpdateName } from "./handleUpdateName";

export const handleUpdateGroupListName = (
  updatedGroupListName: string,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setSelectedGroupListName: Dispatcher<string>,
  groupTrees: any,
  setGroupTrees: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const selectedIndvidualGroupInfo = JSON.parse(
    localStorage.getItem("selectedIndvidualGroupInfo") || "{}"
  );

  const refreshFunction = () => {
    handleUpdateGroupListName(
      updatedGroupListName,
      setBackdropLoadingScreenOn,
      setSelectedGroupListName,
      groupTrees,
      setGroupTrees,
      navigate
    );
  };

  const pushObject = {
    name: updatedGroupListName,
    parent: selectedIndvidualGroupInfo.parent,
  };

  AxiosUI.patch(`/group/${selectedIndvidualGroupInfo.id}/`, pushObject).then(
    () => {
      setSelectedGroupListName(updatedGroupListName);
      handleUpdateName(
        groupTrees,
        setGroupTrees,
        selectedIndvidualGroupInfo.id,
        updatedGroupListName
      );
      setBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, refreshFunction, () => {
        setBackdropLoadingScreenOn(false);
      });
    }
  );
};
