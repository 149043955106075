import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleActivateSite } from "./SiteAboutPageUtils/handleActivateSite";
import { useNavigate } from "react-router-dom";
import {
  AlertState,
  SiteInfo,
  PatchObject,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const ActivateSiteDialog = ({
  activateSiteDialogOpen,
  setActivateSiteDialogOpen,
  setSiteInfo,
  setBackdropLoadingScreenOn,
  setAlertState,
  setPatchObject,
  patchObject,
  setSiteSchedules,
  setAllSiteSchedules,
  clickedTab,
  setActiveTab,
  setDeleteScheduleDialogOpen,
  siteInfo,
}: {
  activateSiteDialogOpen: boolean;
  setActivateSiteDialogOpen: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<SiteInfo>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setPatchObject: Dispatcher<PatchObject>;
  patchObject: PatchObject;
  setSiteSchedules: Dispatcher<any>;
  setAllSiteSchedules: Dispatcher<any>;
  clickedTab: any;
  setActiveTab: any;
  setDeleteScheduleDialogOpen: any;
  siteInfo: SiteInfo;
}) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={activateSiteDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {" Are you sure you want to activate this site?"}
      </DialogTitle>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setSiteInfo((previousState: SiteInfo) => {
              return { ...previousState, active: false };
            });
            setActivateSiteDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setActivateSiteDialogOpen(false);
            setPatchObject((previousState: PatchObject) => {
              return {
                ...previousState,
                active: true,
              };
            });
            setSiteInfo((previousState: SiteInfo) => {
              return {
                ...previousState,
                active: true,
              };
            });
            handleActivateSite(
              setBackdropLoadingScreenOn,
              patchObject,
              setSiteInfo,
              setAlertState,
              setPatchObject,
              siteInfo,
              setSiteSchedules,
              setAllSiteSchedules,
              clickedTab,
              setActiveTab,
              setDeleteScheduleDialogOpen,
              navigate
            );
          }}
          text="Activate"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
