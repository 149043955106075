import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { getCustomerWebhooks } from "./EditCameraUtils/getCustomerWebhooks";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { handleSubmitNewStreamWebhook } from "./EditCameraUtils/handleSubmitNewStreamWebhook";

export const AddStreamWebhookDialog = ({
  setAddStreamWebhookDialogOpen,
  addStreamWebhookDialogOpen,
  setBackdropLoadingScreenOn,
  setCameraWebhookData,
  setActiveTab,
  cameraGeneralInfo,
  setEditStreamWebhookDialogOpen,
}: {
  setAddStreamWebhookDialogOpen: any;
  addStreamWebhookDialogOpen: any;
  setBackdropLoadingScreenOn: any;
  setCameraWebhookData: any;
  setActiveTab: any;
  cameraGeneralInfo: any;
  setEditStreamWebhookDialogOpen: any;
}) => {
  const navigate = useNavigate();

  const [postObject, setPostObject] = React.useState({
    stream: cameraGeneralInfo.streams[0].id,
    webhook: null,
    custom_fields: "{}",
    event_types: [],
  });

  const [errorState, setErrorState] = React.useState({
    webhook: true,
    custom_fields: false,
  });

  const [customerWebhooks, setCustomerWebhooks] = React.useState([]);

  const customerWebhooksNames = new Map();

  const webHookEventTypeOptions = JSON.parse(
    localStorage.webHookEventTypeOptions
  );

  const webHookEventTypeNames = new Map();
  webHookEventTypeOptions.forEach((option: any) => {
    webHookEventTypeNames.set(option.name, option.id);
  });

  React.useEffect(() => {
    getCustomerWebhooks(navigate, setCustomerWebhooks);
  }, []);

  React.useEffect(() => {
    if (customerWebhooks[0]) {
      customerWebhooks.forEach((webhook: any) => {
        customerWebhooksNames.set(webhook.display_name, webhook.id);
      });
    }
  }, [customerWebhooks]);

  return (
    <Dialog
      open={addStreamWebhookDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        Add Stream Webhook{" "}
      </DialogTitle>
      <DialogContent>
        <FormLabel component="legend" sx={{ paddingBottom: 1 }}>
          {" "}
          Webhook*
        </FormLabel>
        <Autocomplete
          id="webhook"
          options={customerWebhooks.map((object: any) => object.display_name)}
          sx={{ width: 400 }}
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, value) => {
            if (value === null) {
              setErrorState((previousState: any) => {
                return { ...previousState, webhook: true };
              });
            } else {
              setErrorState((previousState: any) => {
                return { ...previousState, webhook: false };
              });
            }
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                webhook: customerWebhooksNames.get(value),
              };
            });
          }}
        />
        <br></br>
        <Box sx={{ display: "flex" }}>
          <FormControl variant="standard">
            <FormLabel component="legend">Event Types</FormLabel>
            <FormGroup>
              {webHookEventTypeOptions.map((option: any) => {
                return (
                  <FormControlLabel
                    key={option.name}
                    control={
                      <Checkbox
                        onChange={(event) => {
                          if (event.target.checked) {
                            setPostObject((previousState: any) => {
                              return {
                                ...previousState,
                                event_types: [
                                  ...previousState.event_types,
                                  webHookEventTypeNames.get(option.name),
                                ],
                              };
                            });
                          } else {
                            setPostObject((previousState: any) => {
                              return {
                                ...previousState,
                                event_types: previousState.event_types.filter(
                                  (eventType: any) =>
                                    eventType !==
                                    webHookEventTypeNames.get(option.name)
                                ),
                              };
                            });
                          }
                        }}
                        name={option.name}
                      />
                    }
                    label={option.name}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Box>
        <br></br>
        <FormLabel component="legend" sx={{ paddingBottom: 1 }}>
          {" "}
          Custom Fields
        </FormLabel>
        <Box>
          <TextField
            sx={{ width: 550 }}
            multiline
            rows={7}
            value={postObject.custom_fields}
            error={errorState.custom_fields}
            onChange={(event) => {
              const inputValue = event.target.value;

              // Update postObject with the current input value
              setPostObject((previousState: any) => ({
                ...previousState,
                custom_fields: inputValue,
              }));

              // Validate JSON
              try {
                JSON.parse(inputValue);
                setErrorState((previousState: any) => ({
                  ...previousState,
                  custom_fields: false,
                }));
              } catch (e) {
                setErrorState((previousState: any) => ({
                  ...previousState,
                  custom_fields: true,
                }));
              }
            }}
            helperText={
              errorState.custom_fields
                ? "Invalid JSON format. Ensure your input is a valid JSON object."
                : ""
            }
          />

          <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
            Enter a valid JSON with custom fields that should be sent
            specifically for this camera. See formatting examples in our support
            page:{" "}
            <a
              href="https://support.actuateui.net/admin_ui/webhooks/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#1976d2", textDecoration: "none" }}
            >
              Webhooks
            </a>
          </Typography>
        </Box>
        <br></br>
        <br></br>
        <FormLabel component="legend"> *Required</FormLabel>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddStreamWebhookDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            try {
              const parsedCustomFields = JSON.parse(postObject.custom_fields);

              // Prepare the postObject for API submission
              const payload = {
                ...postObject,
                custom_fields: parsedCustomFields, // Pass the parsed object
              };
              setBackdropLoadingScreenOn(true);
              handleSubmitNewStreamWebhook(
                payload,
                setCameraWebhookData,
                setActiveTab,
                setAddStreamWebhookDialogOpen,
                setBackdropLoadingScreenOn,
                setEditStreamWebhookDialogOpen,
                navigate
              );

              setAddStreamWebhookDialogOpen(false);
            } catch (error) {
              alert("Invalid JSON, cannot submit.");
            }
          }}
          text="Submit"
          disabled={errorState.webhook || errorState.custom_fields}
        />
      </DialogActions>
    </Dialog>
  );
};
