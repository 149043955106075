import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleDeactivateSite } from "./SiteAboutPageUtils/handleDeactivateSite";
import { useNavigate } from "react-router-dom";
import {
  AlertState,
  SiteInfo,
  PatchObject,
  SiteSchedule,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { handleSubmitEmptySchedule } from "./SiteAboutPageUtils/handleSubmitEmptySchedule";
import { Schedule } from "@mui/icons-material";
import { SchedulesTabEditMode } from "./SchedulesTabEditMode";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DeactivateSiteInsteadDialog = ({
  deactivateSiteInsteadDialogOpen,
  setDeactivateSiteInsteadDialogOpen,
  setSiteInfo,
  setBackdropLoadingScreenOn,
  setAlertState,
  setPatchObject,
  patchObject,
  setArmButtonDisabled,
  setArmedStatus,
  siteInfo,
  setScheduleChangesMade,
  setSiteSchedules,
  setAllSiteSchedules,
  setEditMode,
  clickedTab,
  setActiveTab,
  vmsVariables,
  setDeleteScheduleDialogOpen,
  allSiteSchedules,
}: {
  deactivateSiteInsteadDialogOpen: boolean;
  setDeactivateSiteInsteadDialogOpen: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<SiteInfo>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setPatchObject: Dispatcher<PatchObject>;
  patchObject: PatchObject;
  setArmButtonDisabled: any;
  setArmedStatus: any;
  siteInfo: SiteInfo;
  setScheduleChangesMade: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<SiteSchedule[]>;
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>;
  setEditMode: Dispatcher<boolean>;
  clickedTab: any;
  setActiveTab: any;
  vmsVariables: any;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  allSiteSchedules: SiteSchedule[];
}) => {
  const navigate = useNavigate();
  const allSiteSchedulesBackup = JSON.parse(
    localStorage.allSiteSchedulesBackup
  );
  return (
    <Dialog
      open={deactivateSiteInsteadDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {
          "Setting an empty schedule will leave the site inactive. Do you want to deactivate this site as well?"
        }
      </DialogTitle>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setDeactivateSiteInsteadDialogOpen(false);
            setSiteSchedules(allSiteSchedulesBackup);
            setActiveTab(
              <SchedulesTabEditMode
                allSiteSchedules={allSiteSchedulesBackup}
                siteInfo={siteInfo}
                setAllSiteSchedules={setAllSiteSchedules}
                setScheduleChangesMade={setScheduleChangesMade}
                setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setActiveTab={setActiveTab}
                setEditMode={setEditMode}
                setSiteSchedules={setAllSiteSchedules}
                setSiteInfo={setSiteInfo}
              />
            );
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setDeactivateSiteInsteadDialogOpen(false);
            handleSubmitEmptySchedule(
              setScheduleChangesMade,
              setSiteSchedules,
              setAllSiteSchedules,
              setEditMode,
              setBackdropLoadingScreenOn,
              setAlertState,
              clickedTab,
              setActiveTab,
              siteInfo,
              vmsVariables,
              navigate,
              setSiteInfo,
              setDeleteScheduleDialogOpen,
              allSiteSchedules
            );
          }}
          text="Only Submit Empty Schedule"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setDeactivateSiteInsteadDialogOpen(false);
            setPatchObject((previousState: PatchObject) => {
              return {
                ...previousState,
                active: false,
              };
            });
            setSiteInfo((previousState: SiteInfo) => {
              return {
                ...previousState,
                active: false,
              };
            });
            setSiteSchedules([]);
            handleDeactivateSite(
              setBackdropLoadingScreenOn,
              patchObject,
              setSiteInfo,
              setAlertState,
              setPatchObject,
              siteInfo,
              setArmButtonDisabled,
              setArmedStatus,
              setSiteSchedules,
              setAllSiteSchedules,
              clickedTab,
              setActiveTab,
              setDeleteScheduleDialogOpen,
              navigate
            );
          }}
          text="Yes Deactivate"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
