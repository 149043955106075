import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";
import { WebhooksCameraTab } from "../WebhooksCameraTab";
import { getNumbersAfterEditCamera } from "../EditCamera";

export const handleSubmitNewStreamWebhook = (
  postObject: any,
  setCameraWebhookData: any,
  setActiveTab: any,
  setAddStreamWebhookDialogOpen: any,
  setBackdropLoadingScreenOn: any,
  setEditStreamWebhookDialogOpen: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleSubmitNewStreamWebhook(
      postObject,
      setCameraWebhookData,
      setActiveTab,
      setAddStreamWebhookDialogOpen,
      setBackdropLoadingScreenOn,
      setEditStreamWebhookDialogOpen,
      navigate
    );
  };

  AxiosUI.post(`/webhook/`, postObject).then(
    (resp: AxiosResponse) => {
      AxiosUI.get(
        `/webhook/?stream__camera__id=${getNumbersAfterEditCamera(
          location.href
        )}`
      ).then(
        (response: AxiosResponse) => {
          setCameraWebhookData(response.data.results);
          setActiveTab(
            <WebhooksCameraTab
              cameraWebhookData={response.data.results}
              setAddStreamWebhookDialogOpen={setAddStreamWebhookDialogOpen}
              setEditStreamWebhookDialogOpen={setEditStreamWebhookDialogOpen}
            />
          );
          setBackdropLoadingScreenOn(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert(
              "Error getting Webhooks data. Refresh screen for updated data."
            );
            setBackdropLoadingScreenOn(false);
          });
        }
      );
      AxiosUI.post(`/webhook/${resp.data.id}/test/`);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error creating new Stream Webhook.  Please try again.");
        setBackdropLoadingScreenOn(false);
      });
    }
  );
};
