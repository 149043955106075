import * as React from "react";
import { MuiTextFieldType } from "../../common/muiFieldTypes/muiTextFieldType";
import { MuiMultipleChoiceFieldType } from "../../common/muiFieldTypes/muiMultiChoiceFieldType";
import {
  CircularProgress,
  FormHelperText,
  TableContainer,
} from "@mui/material";
import { AutoAddTable } from "./AutoAddTable";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { handleImmixServerInput } from "./CameraAutoAddUtils/handleImmixServerInput";
import { handleImmixPortInput } from "./CameraAutoAddUtils/handleImmixPortInput";
import { handleSubmitAutoAddCameras } from "./CameraAutoAddUtils/handleSubmitAutoAddCameras";
import { useNavigate } from "react-router-dom";
import { handleSureviewServerInput } from "./CameraAutoAddUtils/handleSureViewServerInput";
import { handleSureviewSubjectInput } from "./CameraAutoAddUtils/handleSureViewSubjectInput";
import Alert from "@mui/material/Alert";
import { Autocomplete, TextField } from "@mui/material";
import { AutoAddSearchBar } from "./AutoAddSearchBar";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getAutoAddData } from "./CameraAutoAddUtils/getAutoAddData";
import ErrorBoundary from "../../../ErrorBoundary";
import useWindowDimensions from "../../common/useWindowDimensions";
import { createMinReqHelperText } from "../../common/onBoardingWizardFieldTypes/createMinReqHelperText";
import SyncIcon from "@mui/icons-material/Sync";
import { handleRefreshCameraList } from "./CameraAutoAddUtils/handleRefreshCameraList";
import { handleSentinelInput } from "./CameraAutoAddUtils/handleSentinelInput";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MuiCheckBoxFieldType } from "../../common/muiFieldTypes/muiCheckBoxFieldType";
import { handleImmixDrawIgnoreZonesInput } from "./CameraAutoAddUtils/handleImmixDrawIgnoreZonesInput";

export const CameraAutoAddScreen = ({
  setVisibleScreen,
  setLargerScreen,
  largerScreen,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
}: {
  setVisibleScreen: any;
  setLargerScreen: Dispatcher<boolean>;
  largerScreen: boolean;
  setCameraCheckFinsihedAlertOpen: any;
  setGoodCameras: any;
  setConnectivityCameras: any;
  setLowResolutionCameras: any;
  setCameraCheckResultDownloadURL: any;
}) => {
  const [camerasList, setCamerasList] = React.useState<any>({});

  const [camerasObject, setCamerasObject] = React.useState([]);

  const [fetchingAutoAddData, setFetchingAutoAddData] = React.useState(true);

  const [clientProducts, setClientProducts] = React.useState([]);

  const [selectedProducts, setSelectedProducts] = React.useState([]);

  const [checkboxesChecked, setCheckboxesChecked] = React.useState<any>({});

  const [immixSite, setImmixSite] = React.useState(false);

  const [sureViewSite, setSureviewSite] = React.useState(false);

  const [sentinelSite, setSentinelSite] = React.useState(false);

  const [hanwhaSite, setHanwhaSite] = React.useState(false);

  const [autoAddError, setAutoAddError] = React.useState(
    localStorage.autoAddError
  );

  const [productsArray, setProductsArray] = React.useState([]);

  const [autoAddSiteInfo, setAutoAddSiteInfo] = React.useState<any>({});

  const [streamPatchObject, setStreamPatchObject] = React.useState<any>({
    resolution: "",
    stream_type: "",
    stream_quality: "",
    codec: "",
  });

  const [streamChangesMade, setStreamChangesMade] = React.useState(false);

  const { width } = useWindowDimensions();

  const serverOptions = [
    ["US SMTP Server", "us-smtp.sureviewops.com"],
    ["EU SMTP Server", "eu-smtp.sureviewops.com"],
  ];

  const optionsIds = new Map();
  const optionNames = new Map();
  serverOptions.forEach((option: any) => {
    optionsIds.set(option[0], option[1]);
  });
  serverOptions.forEach((option: any) => {
    optionNames.set(option[1], option[0]);
  });

  const alarmOptions = JSON.parse(localStorage.alarmOptions);

  const alarmIds = new Map();
  alarmOptions.forEach((object: any) => {
    alarmIds.set(object.value, object.name);
  });

  const integrationsTypes = JSON.parse(localStorage.integrationTypes);

  const integrationIds = new Map();
  integrationsTypes.forEach((object: any) => {
    integrationIds.set(object.id, object.name);
  });

  const navigate = useNavigate();

  const [additionalFieldNamesArray, setAdditionalFieldNamesArray] =
    React.useState(JSON.parse(localStorage.additionalFieldNamesArray));

  const immixAlertPostObject = JSON.parse(localStorage.immixAlertPostObject);

  const sentinelAlertPostObject = JSON.parse(
    localStorage.sentinelAlertPostObject
  );

  React.useEffect(() => {
    getAutoAddData(
      setCamerasList,
      setCamerasObject,
      setFetchingAutoAddData,
      checkboxesChecked,
      setCheckboxesChecked,
      setClientProducts,
      setImmixSite,
      setSureviewSite,
      setProductsArray,
      setAutoAddSiteInfo,
      navigate
    );
  }, []);

  React.useEffect(() => {
    if (Object.keys(autoAddSiteInfo).length > 0) {
      if (alarmIds.get(autoAddSiteInfo.default_alarm) === "Immix") {
        setImmixSite(true);
      }
      if (alarmIds.get(autoAddSiteInfo.default_alarm) === "Sureview") {
        setSureviewSite(true);
      }
      if (alarmIds.get(autoAddSiteInfo.default_alarm) === "Sentinel") {
        setSentinelSite(true);
      }
      if (
        integrationIds.get(Number(localStorage.integrationType)) ===
        "digital_watchdog"
      ) {
        setHanwhaSite(true);
      }
    }
  }, [autoAddSiteInfo]);

  const buttonText = (
    <>
      Save Changes
      <ArrowForwardIcon />
    </>
  );

  const [helperText, setHelperText] = React.useState("");

  return (
    <div>
      {fetchingAutoAddData ? (
        <div className="pl-[350px] pt-[200px]">
          <CircularProgress color="warning" />
        </div>
      ) : (
        <ErrorBoundary>
          <div>
            {autoAddError && (
              <div className="absolute w-[600px] h-[50px]">
                <Alert
                  severity="error"
                  onClose={() => {
                    setAutoAddError(false);
                    localStorage.removeItem("autoAddError");
                  }}
                >
                  <p className="font-mulish font-bold text-[16px]">
                    Problem adding Camera(s). Please try again.
                  </p>
                </Alert>
              </div>
            )}
            {/* <div className='font-mulish absolute text-[#283E6D] top-[70px] right-[490px] text-[22px]'>Auto-Add Cameras</div> */}
            <div className="absolute top-[75px] right-[30px]">
              <OrangeTextButton
                onClick={() => {
                  localStorage.removeItem("autoAddError");
                  handleSubmitAutoAddCameras(
                    camerasObject,
                    setVisibleScreen,
                    setLargerScreen,
                    largerScreen,
                    setCameraCheckFinsihedAlertOpen,
                    setGoodCameras,
                    setConnectivityCameras,
                    setLowResolutionCameras,
                    setCameraCheckResultDownloadURL,
                    sentinelSite,
                    streamChangesMade,
                    streamPatchObject,
                    navigate
                  );
                }}
                text={buttonText}
                disabled={!camerasList.results[0]}
              />
            </div>

            <div className="font-mulish  absolute text-[#283E6D] top-[80px] left-[20px] text-[18px]">
              Cameras
            </div>

            <div className="absolute top-[120px] left-[30px]">
              <div>
                <div>
                  <MuiMultipleChoiceFieldType
                    id="product_input"
                    options={clientProducts}
                    onChange={(event: any, value: any) => {
                      setSelectedProducts(value);
                      createMinReqHelperText(
                        setHelperText,
                        value,
                        productsArray,
                        navigate
                      );
                    }}
                    stylingWidth={500}
                    label="Products"
                    helperText={helperText}
                    defaultValue={[]}
                  />
                </div>
                <br></br>
                <div className="relative left-[-15px]">
                  <TableContainer
                    sx={largerScreen ? { width: width - 25 } : { width: 560 }}
                  >
                    <div className="flex">
                      <div className="w-[350px]">
                        <AutoAddSearchBar
                          setCamerasList={setCamerasList}
                          setCamerasObject={setCamerasObject}
                          navigate={navigate}
                        />
                      </div>
                      <div className="pl-15">
                        <OrangeTextButton
                          text={
                            <>
                              <SyncIcon fontSize="small" />
                              &nbsp; Refresh Camera List
                            </>
                          }
                          disabled={false}
                          onClick={() => {
                            handleRefreshCameraList(
                              setCamerasList,
                              setCamerasObject,
                              setFetchingAutoAddData,
                              checkboxesChecked,
                              setCheckboxesChecked,
                              setClientProducts,
                              setImmixSite,
                              setSureviewSite,
                              setProductsArray,
                              setSelectedProducts,
                              setAutoAddError,
                              navigate
                            );
                          }}
                        />
                      </div>
                    </div>

                    <AutoAddTable
                      camerasList={camerasList}
                      selectedProducts={selectedProducts}
                      camerasObject={camerasObject}
                      setCamerasObject={setCamerasObject}
                      checkboxesChecked={checkboxesChecked}
                      setCheckboxesChecked={setCheckboxesChecked}
                      additionalFieldNamesArray={additionalFieldNamesArray}
                      immixSite={immixSite}
                      sureViewSite={sureViewSite}
                      productsArray={productsArray}
                      sentinelSite={sentinelSite}
                    />
                  </TableContainer>
                </div>
                <br></br>
                {immixSite && (
                  <div>
                    <p className="relative h-[35px] font-mulish w-[300px] text-[#283E6D] text-[18px]">
                      Immix Alerts
                    </p>
                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="immixServerInput"
                        defaultValue={immixAlertPostObject.immix_server}
                        value=""
                        onChange={(event: any) => {
                          handleImmixServerInput(
                            camerasObject,
                            setCamerasObject,
                            event.target.value
                          );
                        }}
                        helperText=""
                        stylingWidth={300}
                        label="Immix Server"
                        error={false}
                      />
                    </div>
                    <div>
                      <MuiTextFieldType
                        id="immixPortInput"
                        defaultValue={immixAlertPostObject.immix_port}
                        value=""
                        onChange={(event: any) => {
                          handleImmixPortInput(
                            camerasObject,
                            setCamerasObject,
                            event.target.value
                          );
                        }}
                        helperText=""
                        stylingWidth={300}
                        label="Immix Port"
                        error={false}
                      />
                    </div>
                    <div className="pt-[10px]">
                      <MuiCheckBoxFieldType
                        id="drawIgnoreZones"
                        checked={false}
                        disabled={false}
                        label="Draw Ignore Zones"
                        onChange={(event: any) => {
                          handleImmixDrawIgnoreZonesInput(
                            camerasObject,
                            setCamerasObject,
                            event.target.checked
                          );
                        }}
                        helperText="If checked, draws ignore zones in the alert images."
                      />
                    </div>
                    <br></br>
                  </div>
                )}
                {sureViewSite && (
                  <div>
                    <p className="relative h-[35px] font-mulish w-[300px] text-[#283E6D] text-[18px]">
                      Sureview Alerts
                    </p>
                    <div className="relative h-[75px]">
                      <Autocomplete
                        disablePortal
                        id="sureViewServer"
                        options={serverOptions.map((option: string[]) => {
                          return option[0];
                        })}
                        onChange={(event: any, newInputValue: any) => {
                          handleSureviewServerInput(
                            camerasObject,
                            setCamerasObject,
                            optionsIds.get(newInputValue)
                          );
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Sureview Server"
                            autoComplete="off"
                            sx={{ width: 300 }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <MuiTextFieldType
                        id="sureViewSubject"
                        defaultValue=""
                        value=""
                        onChange={(event: any) => {
                          handleSureviewSubjectInput(
                            camerasObject,
                            setCamerasObject,
                            event.target.value
                          );
                        }}
                        helperText=""
                        stylingWidth={300}
                        label="Sureview Subject"
                        error={false}
                      />
                    </div>
                    <br></br>
                  </div>
                )}
                {sentinelSite && (
                  <div>
                    <p className="relative h-[35px] font-mulish w-[300px] text-[#283E6D] text-[18px]">
                      Sentinel Alerts
                    </p>
                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="sentinelServerInput"
                        defaultValue={sentinelAlertPostObject.sentinel_server}
                        value=""
                        onChange={(event: any) => {
                          handleSentinelInput(
                            setCamerasObject,
                            camerasObject,
                            event.target.value,
                            false,
                            null,
                            "sentinelServer"
                          );
                        }}
                        helperText=""
                        stylingWidth={300}
                        label="Sentinel Server"
                        error={false}
                      />
                    </div>
                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="sentinelSiteIdInput"
                        defaultValue={sentinelAlertPostObject.sentinel_site_id}
                        value=""
                        onChange={(event: any) => {
                          handleSentinelInput(
                            setCamerasObject,
                            camerasObject,
                            event.target.value,
                            false,
                            null,
                            "sentinelSiteId"
                          );
                        }}
                        helperText=""
                        stylingWidth={300}
                        label="Sentinel Site Id"
                        error={false}
                      />
                    </div>
                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="sentinelDeviceAddressInput"
                        defaultValue={
                          sentinelAlertPostObject.sentinel_device_address
                        }
                        value=""
                        onChange={(event: any) => {
                          handleSentinelInput(
                            setCamerasObject,
                            camerasObject,
                            event.target.value,
                            false,
                            null,
                            "sentinelDeviceAddress"
                          );
                        }}
                        helperText=""
                        stylingWidth={300}
                        label="Sentinel Device Address"
                        error={false}
                      />
                    </div>
                    <div className="relative h-[75px]">
                      <FormControlLabel
                        sx={{
                          fontFamily: "Mulish",
                          color: "#001943",
                        }}
                        label="Draw Ignore Zones"
                        control={
                          <Checkbox
                            color="primary"
                            defaultChecked={
                              sentinelAlertPostObject.draw_ignore_zones
                            }
                            onChange={(event, checked) => {
                              handleSentinelInput(
                                setCamerasObject,
                                camerasObject,
                                "",
                                checked,
                                null,
                                "drawIgnoreZones"
                              );
                            }}
                          />
                        }
                      />
                      <FormHelperText>
                        If checked, draws ignore zones in the alert images.
                      </FormHelperText>
                    </div>
                    <br></br>
                  </div>
                )}
                {hanwhaSite && (
                  <div>
                    <p className="relative h-[35px] font-mulish w-[300px] text-[#283E6D] text-[18px]">
                      {`Stream Parameters (optional)`}
                    </p>
                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="streamParamsResolution"
                        defaultValue=""
                        value=""
                        onChange={(event: any) => {
                          setStreamChangesMade(true);
                          setStreamPatchObject({
                            ...streamPatchObject,
                            resolution: event.target.value,
                          });
                        }}
                        helperText="Example: 720p"
                        stylingWidth={300}
                        label="Resolution"
                        error={false}
                      />
                    </div>

                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="streamParamsStreamType"
                        defaultValue=""
                        value=""
                        onChange={(event: any) => {
                          setStreamChangesMade(true);
                          setStreamPatchObject({
                            ...streamPatchObject,
                            stream_type: event.target.value,
                          });
                        }}
                        helperText="rtsp or http"
                        stylingWidth={300}
                        label="Stream Type"
                        error={false}
                      />
                    </div>
                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="streamParamsStreamQuality"
                        defaultValue=""
                        value=""
                        onChange={(event: any) => {
                          setStreamChangesMade(true);
                          setStreamPatchObject({
                            ...streamPatchObject,
                            stream_quality: event.target.value,
                          });
                        }}
                        helperText="For DW, 0 is high quality 1 is low quality. Preferrably use 1."
                        stylingWidth={300}
                        label="Stream Quality"
                        error={false}
                      />
                    </div>
                    <br></br>
                    <div className="relative h-[75px]">
                      <MuiTextFieldType
                        id="streamParamsCodec"
                        defaultValue=""
                        value=""
                        onChange={(event: any) => {
                          setStreamChangesMade(true);
                          setStreamPatchObject({
                            ...streamPatchObject,
                            codec: event.target.value,
                          });
                        }}
                        helperText="Example: H264 for rtsp. For Http use webm, mpegts, mpjpeg, mp4 or mkv"
                        stylingWidth={300}
                        label="Codec"
                        error={false}
                      />
                    </div>

                    <br></br>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ErrorBoundary>
      )}
    </div>
  );
};
