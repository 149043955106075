import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { TextField } from "@mui/material";
import { handleSubmitUpdatedNotes } from "./SiteAboutPageUtils/handleSubmitUpdatedNotes";
import { useNavigate } from "react-router-dom";
import { SiteInfo } from "./SiteAboutPageUtils/siteAboutInterfaces";

export const SeeNotesDialog = ({
  seeNotesDialogOpen,
  setSeeNotesDialogOpen,
  defaultText,
  setSiteInfo,
  setActiveTab,
}: {
  seeNotesDialogOpen: boolean;
  setSeeNotesDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  defaultText: string;
  setSiteInfo: React.Dispatch<React.SetStateAction<SiteInfo>>;
  setActiveTab: React.Dispatch<React.SetStateAction<JSX.Element>>;
}) => {
  const [noteText, setNoteText] = React.useState(defaultText);

  const navigate = useNavigate();

  return (
    <Dialog open={seeNotesDialogOpen}>
      <DialogTitle id="alert-dialog-title">Notes</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(event) => {
            setNoteText(event.target.value);
          }}
          value={noteText}
          variant="outlined"
          multiline
          rows={6}
          sx={{ fontFamily: "Mulish", width: 400 }}
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            if (noteText !== defaultText) {
              handleSubmitUpdatedNotes(
                noteText,
                defaultText,
                setSiteInfo,
                setActiveTab,
                navigate
              );
            }
            setSeeNotesDialogOpen(false);
          }}
          text="Ok"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
