export const createSubmittedCameraObject = (values: any) => {
  const customerId = Number(localStorage.siteId);

  const returnObject: any = {
    camera_name: values.camera_name,
    customer: customerId,
    integration: Number(localStorage.integrationType),
    http: Number(values.http),
    ip: values.ip,
    username: values.username,
    password: values.password,
    channel: values.channel,
    tcp: Number(values.tcp),
    rtsp: Number(values.rtsp),
    camera_type: values.camera_type,
    stream_parameters: values.stream_parameters,
    products: values.products,
    een_api: values.een_api,
    app_name: values.app_name,
    api_key: values.api_key,
    username_star4live: values.username_star4live,
    password_star4live: values.password_star4live,
    device_name: values.device_name,
    software_type_smartpss: values.software_type_smartpss,
    immix: {
      immix_id: values.immix_id,
      immix_server: values.immix_server,
      immix_email: values.immix_email,
      immix_port: values.immix_port,
      immix_input1: values.immix_input1,
    },
    sentinel: {
      sentinel_server: values.sentinel_server,
      sentinel_site_id: values.sentinel_site_id,
      sentinel_device_address: values.sentinel_device_address,
      draw_ignore_zones: values.draw_ignore_zones,
    },
    bold: {
      bold_server: values.bold_server,
      bold_port: values.bold_port,
      bold_id: values.bold_id,
      bold_sdnis: values.bold_sdnis,
      bold_zone: values.bold_zone,
      bold_alarmtype: values.bold_alarmtype,
    },
    sureview: {
      sureview_server: values.sureview_server,
      sureview_subject: values.sureview_subject,
      sureview_cc: values.sureview_cc,
      sureview_email: values.sureview_email,
    },
    patriot: {
      patriot_server: values.patriot_server,
      patriot_username: values.patriot_username,
      patriot_password: values.patriot_password,
      patriot_zone_number: values.patriot_zone_number,
      patriot_send_video: values.patriot_send_video,
      draw_ignore_zones: values.draw_ignore_zones,
      patriot_client_no: values.patriot_client_no,
    },
    // softguard: {
    //   server: values.server,
    //   port: values.port,
    //   zone: values.zone,
    //   account: values.account,
    //   user: values.user,
    //   user_ext: values.user_ext,
    // },
    webhook: {
      stream: values.stream,
      webhook: values.webhook,
      custom_fields: values.custom_fields,
      event_types: values.event_types,
    },
    camera_view: values.camera_view,
    dynamic_slicing: values.dynamic_slicing,
  };

  if (values.server) {
    returnObject.softguard = {
      server: values.server,
      port: values.port,
      zone: values.zone,
      account: values.account,
      user: values.user,
      user_ext: values.user_ext,
    };
  }

  return returnObject;
};
