import { GeneralInfoEditCameraTab } from "../GeneralInfoEditCameraTab";
import { ConnectionsTab } from "../ConnectionsTab";
import { NotificationsTab } from "../NotificationsTab";
import { ProductsTab } from "../ProductsTab";
import { ImmixAlertsTab } from "../ImmixAlertsTab";
import { SureviewAlertsTab } from "../SureviewAlertsTab";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { AxisTab } from "../AxisTab";
import { MobotixTab } from "../MobotixTab";
import ErrorBoundary from "../../../../ErrorBoundary";
import { WebhooksCameraTab } from "../WebhooksCameraTab";
import { SentinelAlertsTab } from "../SentinelAlertsTab";
import { BoldAlertsTab } from "../BoldAlertsTab";
import { PatriotAlertsTab } from "../PatriotAlertsTab";
import { USMonitoringAlertsTab } from "../USMonitoringAlertsTab";
import { SoftguardAlertsTab } from "../SoftguardAlertsTab";
import { StreamParametersTab } from "../StreamParametersTab";

export const handleEditCameraCancelButtonClick = (
  setEditMode: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  cameraGeneralInfo: any,
  setCameraGeneralInfo: any,
  cameraGeneralInfoBackup: any,
  setPatchObject: any,
  immixAlerts: any,
  sureviewAlerts: any,
  setAddImmixAlertDialogOpen: any,
  setAddSureviewAlertDialogOpen: any,
  activeProducts: any,
  setBackdropLoadingScreenOn: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>,
  recipientsConfiguredData: any,
  titlesArray: any,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  actionRulesData: any,
  setActionRulesData: any,
  actionRulesTitlesArray: any,
  setActionRulesTitlesArray: any,
  applicationsConfiguredData: any,
  axisFinished: any,
  timeTablesData: any,
  setTimeTablesData: any,
  iPNotifyData: any,
  setIPNotifyData: any,
  actionHandlerData: any,
  setActionHandlerData: any,
  cameraWebhookData: any,
  productsData: any,
  setProductsData: Dispatcher<any>,
  allTabsOpen: boolean,
  setAllTabsOpen: Dispatcher<boolean>,
  backupProductsData: any,
  setBackupProductsData: any,
  sentinelAlerts: any,
  setAddSentinelAlertDialogOpen: any,
  boldAlerts: any,
  setAddBoldAlertDialogOpen: Dispatcher<boolean>,
  patriotAlerts: any,
  setAddPatriotAlertDialogOpen: Dispatcher<boolean>,
  uSMonitoringAlerts: any,
  setAddUSMonitoringAlertDialogOpen: Dispatcher<boolean>,
  softguardAlerts: any,
  setAddSoftguardAlertDialogOpen: Dispatcher<boolean>,
  streamParametersData: any,
  setAddStreamWebhookDialogOpen: Dispatcher<boolean>,
  setEditStreamWebhookDialogOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  setProductsData(backupProductsData);
  setEditMode(false);
  setCameraGeneralInfo(cameraGeneralInfoBackup);
  setPatchObject({
    camera_name: cameraGeneralInfoBackup.camera_name,
    streams: cameraGeneralInfoBackup.streams,
  });
  if (clickedTab === "one") {
    setActiveTab(
      <ErrorBoundary>
        <GeneralInfoEditCameraTab
          siteInfo={siteInfo}
          cameraGeneralInfo={cameraGeneralInfoBackup}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "two") {
    setActiveTab(
      <ErrorBoundary>
        <ConnectionsTab cameraGeneralInfo={cameraGeneralInfoBackup} />
      </ErrorBoundary>
    );
  } else if (clickedTab === "three") {
    setActiveTab(
      <ErrorBoundary>
        <NotificationsTab cameraGeneralInfo={cameraGeneralInfoBackup} />
      </ErrorBoundary>
    );
  } else if (clickedTab === "four") {
    setProductsData(JSON.parse(localStorage.backupProductsData));
    setActiveTab(
      <ErrorBoundary>
        <ProductsTab
          productsData={JSON.parse(localStorage.backupProductsData)}
          setProductsData={setProductsData}
          allTabsOpen={allTabsOpen}
          setAllTabsOpen={setAllTabsOpen}
          setBackupProductsData={setBackupProductsData}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "five") {
    setActiveTab(
      <ErrorBoundary>
        <ImmixAlertsTab
          immixAlerts={immixAlerts}
          setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
          setSendingImmixTest={setSendingImmixTest}
          setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
          setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
          navigate={navigate}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "six") {
    setActiveTab(
      <ErrorBoundary>
        <SureviewAlertsTab
          sureviewAlerts={sureviewAlerts}
          setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "seven") {
    setActiveTab(
      <ErrorBoundary>
        <AxisTab
          recipientsConfiguredData={recipientsConfiguredData}
          titlesArray={titlesArray}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setRecipientsConfiguredData={setRecipientsConfiguredData}
          setTitlesArray={setTitlesArray}
          actionRulesData={actionRulesData}
          setActionRulesData={setActionRulesData}
          actionRulesTitlesArray={actionRulesTitlesArray}
          setActionRulesTitlesArray={setActionRulesTitlesArray}
          setActiveTab={setActiveTab}
          applicationsConfiguredData={applicationsConfiguredData}
          cameraGeneralInfo={cameraGeneralInfo}
          axisFinished={axisFinished}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "eight") {
    setActiveTab(
      <ErrorBoundary>
        <MobotixTab
          timeTablesData={timeTablesData}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setTimeTablesData={setTimeTablesData}
          setActiveTab={setActiveTab}
          iPNotifyData={iPNotifyData}
          setIPNotifyData={setIPNotifyData}
          actionHandlerData={actionHandlerData}
          setActionHandlerData={setActionHandlerData}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "nine") {
    setActiveTab(
      <ErrorBoundary>
        <WebhooksCameraTab
          cameraWebhookData={cameraWebhookData}
          setAddStreamWebhookDialogOpen={setAddStreamWebhookDialogOpen}
          setEditStreamWebhookDialogOpen={setEditStreamWebhookDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "ten") {
    setActiveTab(
      <ErrorBoundary>
        <SentinelAlertsTab
          sentinelAlerts={sentinelAlerts}
          setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "eleven") {
    setActiveTab(
      <ErrorBoundary>
        <BoldAlertsTab
          boldAlerts={boldAlerts}
          setAddBoldAlertDialogOpen={setAddBoldAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "twelve") {
    setActiveTab(
      <ErrorBoundary>
        <PatriotAlertsTab
          patriotAlerts={patriotAlerts}
          setAddPatriotAlertDialogOpen={setAddPatriotAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "thirteen") {
    setActiveTab(
      <ErrorBoundary>
        <USMonitoringAlertsTab
          uSMonitoringAlerts={uSMonitoringAlerts}
          setAddUSMonitoringAlertDialogOpen={setAddUSMonitoringAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "fourteen") {
    setActiveTab(
      <ErrorBoundary>
        <SoftguardAlertsTab
          softguardAlerts={softguardAlerts}
          setAddSoftguardAlertDialogOpen={setAddSoftguardAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "fifteen") {
    setActiveTab(
      <ErrorBoundary>
        <StreamParametersTab streamParametersData={streamParametersData} />
      </ErrorBoundary>
    );
  }
  setBackdropLoadingScreenOn(false);
};
