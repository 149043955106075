import { Autocomplete, TextField } from "@mui/material";
import { Field } from "formik";
import * as React from "react";
import { requiredResponseValidator } from "../formikContainer/requiredResponseValidator";
import { createMinReqHelperText } from "./createMinReqHelperText";
import { Link, useNavigate } from "react-router-dom";
import { Dispatcher } from "../../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { WizardContainer } from "../formikContainer/WizardContainer";

export const MultipleChoiceFieldType = ({
  config,
  formik,
  setErrorState,
  setChoicesChanger,
  choicesChanger,
  setHealthCheckSelected,
  setActiveWizardContainer,
  handleSubmitSite,
  responseObject,
  setVisibleScreen,
}: {
  config: any;
  formik: any;
  setErrorState: any;
  setChoicesChanger: any;
  choicesChanger: any;
  setHealthCheckSelected: Dispatcher<boolean>;
  setActiveWizardContainer: any;
  handleSubmitSite: any;
  responseObject: any;
  setVisibleScreen: any;
}) => {
  const options = config.options;
  const optionsIds = new Map();
  const optionNames = new Map();
  options.forEach((option: any) => {
    optionsIds.set(option[0], option[1]);
  });
  options.forEach((option: any) => {
    optionNames.set(option[1], option[0]);
  });
  const [newChoicesChanger, setNewChoicesChanger] = React.useState(false);

  const [helperText, setHelperText] = React.useState(config.helperText);

  const navigate = useNavigate();

  if (!choicesChanger) {
    choicesChanger = newChoicesChanger;
    setChoicesChanger = setNewChoicesChanger;
  }

  const [choiceOptions, setChoiceOptions] = React.useState(config.options);

  const checkForIntruder = (array: any[]) => {
    let intruderPresent = false;
    array.forEach((item: string) => {
      if (item.includes("Intruder")) {
        intruderPresent = true;
      }
    });
    return intruderPresent;
  };

  return ( 
      <div>
        <Autocomplete
          multiple
          disablePortal
          id={config.id}
        defaultValue={Array.isArray(formik.values[config.field])
          ? formik.values[config.field]
            .map((element: number) => optionNames.get(element))
            .filter((item: string | undefined) => item !== undefined) // Filter out invalid mappings
          : []}
          options={choiceOptions.map((option: string[]) => {
            return option[0];
          })}
          onChange={(event: any, value: any) => {
            if (
              config.id === "products" &&
              location.href.includes("addcamera")
            ) {
              createMinReqHelperText(
                setHelperText,
                value,
                config.options,
                navigate
              );
            }
            if (
              config.id === "products" &&
              location.href.includes("addsite") &&
              value.includes("Healthcheck")
            ) {
              setHealthCheckSelected(true);
              setActiveWizardContainer(
                <WizardContainer
                  handleSubmitData={handleSubmitSite}
                  response={responseObject}
                  setVisibleScreen={setVisibleScreen}
                  responseObject={false}
                  choicesChanger={choicesChanger}
                  setChoicesChanger={setChoicesChanger}
                  setHealthCheckSelected={setHealthCheckSelected}
                  healthCheckSelected={true}
                  setActiveWizardContainer={setActiveWizardContainer}
                  handleSubmitSite={handleSubmitSite}
                  setCameraCheckFinsihedAlertOpen={() => {}}
                  setGoodCameras={() => {}}
                  setConnectivityCameras={() => {}}
                  setLowResolutionCameras={() => {}}
                  setCameraCheckResultDownloadURL={() => {}}
                />
              );
              if (value.length === 1) {
                localStorage.setItem("healthcheckOnly", "true");
              }
              if (value.length > 1) {
                localStorage.setItem("healthcheckOnly", "false");
              }
            }
            if (
              config.id === "products" &&
              location.href.includes("addsite") &&
              checkForIntruder(value)
            ) {
              value.map((valueString: string) => {
                if (valueString === "Intruder") {
                  setChoiceOptions(
                    config.options.filter(
                      (subArr: [string, string]) => subArr[0] !== "Intruder +"
                    )
                  );
                }
                if (valueString === "Intruder +") {
                  setChoiceOptions(
                    config.options.filter(
                      (subArr: [string, string]) => subArr[0] !== "Intruder"
                    )
                  );
                }
              });
            }
            if (
              config.id === "products" &&
              location.href.includes("addsite") &&
              !checkForIntruder(value)
            ) {
              setChoiceOptions(config.options);
            }
            if (
              config.id === "products" &&
              location.href.includes("addsite") &&
              !value.includes("Healthcheck")
            ) {
              setHealthCheckSelected(false);
              localStorage.setItem("healthcheckOnly", "false");
              setActiveWizardContainer(
                <WizardContainer
                  handleSubmitData={handleSubmitSite}
                  response={responseObject}
                  setVisibleScreen={setVisibleScreen}
                  responseObject={false}
                  choicesChanger={choicesChanger}
                  setChoicesChanger={setChoicesChanger}
                  setHealthCheckSelected={setHealthCheckSelected}
                  healthCheckSelected={false}
                  setActiveWizardContainer={setActiveWizardContainer}
                  handleSubmitSite={handleSubmitSite}
                  setCameraCheckFinsihedAlertOpen={() => {}}
                  setGoodCameras={() => {}}
                  setConnectivityCameras={() => {}}
                  setLowResolutionCameras={() => {}}
                  setCameraCheckResultDownloadURL={() => {}}
                />
              );
            }
            const choicesArray: number[] = [];
            value.forEach((element: string) => {
              choicesArray.push(optionsIds.get(element));
            });
            setChoicesChanger(!choicesChanger);
            formik.values[config.field] = choicesArray;
          }}
          sx={{ width: 350 }}
          renderInput={(params) => (
            <Field
              {...params}
              variant="standard"
              defaultValue={formik.values[config.field]}
              label={config.label}
              name={config.id}
              helperText={helperText}
              as={TextFieldComponent}
            />
          )}
        />
      </div>
        );
};

const TextFieldComponent = (props: any) => <TextField {...props} />;
